import { addDays, startOfDay } from 'date-fns';

import { groupsBy, tableDateStatus } from 'src/constants';
import { PolicyModel, SerializedGroupedPolicy } from 'src/types/types';

type GetKeyType = (item: PolicyModel) => string
type GetLabelType = (item: PolicyModel) => string

const groupBy = (items: PolicyModel[], getKey: GetKeyType, getLabel: GetLabelType): SerializedGroupedPolicy[] => {
  return Object.values(items.reduce((prev, next) => {
    const key = getKey(next);

    // eslint-disable-next-line no-param-reassign
    if (!prev[key]) prev[key] = { title: getLabel(next), policies: [] };
    prev[key].policies.push(next);
    return prev;
  }, {} as { [key: string]: SerializedGroupedPolicy })) as SerializedGroupedPolicy[];
};

const getDateStatusTitle = (date: string): string => {
  const itemDate = new Date(date);
  const now = startOfDay(new Date());
  if (addDays(now, 7) >= itemDate) {
    return tableDateStatus.expiredOnWeek;
  }
  if (itemDate >= addDays(now, 8) && itemDate <= addDays(now, 61)) {
    return tableDateStatus.expiredSoon;
  }
  if (addDays(now, 61) <= itemDate) {
    return tableDateStatus.actual;
  }
  return 'Something';
};

export const getGrouptedPolicies = (policies: PolicyModel[], groupByParam: string): SerializedGroupedPolicy[] => {
  if (groupByParam === groupsBy.contactName) {
    return groupBy(policies, (item) => item.contactName, (item) => item.contactName);
  }
  if (groupByParam === groupsBy.status) {
    return groupBy(policies, (item) => getDateStatusTitle(item.finishDate), (item) => getDateStatusTitle(item.finishDate));
  }
  if (groupByParam === groupsBy.companyId) {
    return groupBy(policies, (item) => item.companyId, (item) => item.companyId);
  }
  return [] as SerializedGroupedPolicy[];
};
