import { createAsyncThunk } from '@reduxjs/toolkit';

import UserApi from 'src/api/UserApi';
import { InviteUser, UpdateUserModel } from 'src/types/types';

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async () => {
    const res = await UserApi.fetchUsers();
    return res;
  },
);

export const getUserById = createAsyncThunk(
  'users/getUserById',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await UserApi.fetchUserById(id);
    return res;
  },
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (params: { user: UpdateUserModel, id: string }) => {
    const { user, id } = params;
    const res = await UserApi.updateUser(user, id);
    return res;
  },
);

export const inviteUser = createAsyncThunk(
  'users/inviteUser',
  async (params: { user: InviteUser }) => {
    const { user } = params;
    const res = await UserApi.inviteUser(user);
    return res;
  },
);

export const getMe = createAsyncThunk(
  'users/me',
  async () => {
    const res = await UserApi.fetchMe();
    return res;
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id: string) => {
    const res = await UserApi.deleteUser(id);
    return res;
  },
);
