import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';

const baseState = (state: RootState) => state.billings;

export const selectBillings = createDraftSafeSelector(baseState, (state) => state);

export const selectFiltredProducts = createDraftSafeSelector(baseState, (state) => {
  return {
    companyProducts: state.billings.filter((billing) => {
      return billing.metadata.companyType === 'company';
    }),
    agencyProducts: state.billings.filter((billing) => {
      return billing.metadata.companyType === 'agency';
    }),
  };
});
