import React from 'react';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import Toggle from '../Toggle/Toggle';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 12,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({
  className,
  onChange,
  label,
  placeholder,
  value,
  toggler,
  checked,
  toggleTitle,
  toggleHandleClick,
}) => {
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <div className={className}>
      <div className="form-input__label-toggle">
        <label className="form-input__label">{label}</label>
        {!toggler && (
          <div className="form-input__price-toggler">
            <Toggle
              checked={checked}
              changeHandler={toggleHandleClick}
            />
            <span className="form-input__toggler-text">{toggleTitle}</span>
          </div>
        )}
      </div>
      <MaskedInput
        mask={currencyMask}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="form-input__field"
        value={value}
      />
    </div>
  );
};

export default CurrencyInput;
