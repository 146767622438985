import { FC, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import SignUpByInvite, { SignUpByInviteFormValues } from 'src/components/SignUpForm/SignUpByInivite';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { serializeUser } from 'src/store/auth/serialize';
import { registerUser, registerUserByInvite } from 'src/store/auth/thunk';
import { SignFormValues } from 'src/types/types';

import SignUpForm from '../components/SignUpForm/SignUpForm';

const SignUp: FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const token = search.split('token=')[1];

  const signUpFormSubmit = async (values: SignFormValues) => {
    setLoading(true);
    await dispatch(registerUser(serializeUser(values)));
    setLoading(false);
  };

  const signUpByInviteFormSubmit = async (values: SignUpByInviteFormValues) => {
    setLoading(true);
    await dispatch(registerUserByInvite({ phone: values.phone, password: values.password, token }));
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="mb-40 login-title">Sign up</h1>
      {!!token && <SignUpByInvite onSubmit={signUpByInviteFormSubmit} loading={loading} />}
      {!token && <SignUpForm onSubmitForm={signUpFormSubmit} loading={loading} />}
      <div className="form__subtitle">
        Already have an account? &nbsp;
        <span className="form__label--blue">
          <Link to={routeNames.LOGIN}>Log in</Link>
        </span>
      </div>
    </div>
  );
};

export default SignUp;
