import { FC } from 'react';

import classNames from 'classnames';

import { ReactComponent as CheckboxIcon } from 'src/assets/icons/checkbox-icon.svg';

type CheckboxProps = {
  active?: boolean,
  onClick: () => void
}

const Checkbox: FC<CheckboxProps> = ({ active, onClick }) => {
  const checkboxClassName = classNames('checkbox-component', { 'checkbox-component--active': active });
  return (
    <div className={checkboxClassName} role="presentation" onClick={onClick}>
      {active && <CheckboxIcon />}
    </div>
  );
};

export default Checkbox;
