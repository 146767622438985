import { FC, useState } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { Link, useSearchParams } from 'react-router-dom';

import ResetPasswordForm from 'src/components/ResetPasswordForm/ResetPasswordForm';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { resetPassword } from 'src/store/auth/thunk';
import { ResetPasswordFormValues } from 'src/types/types';

const ResetPassword: FC = () => {
  const [disableButton, setDisableButton] = useState(false);
  const [searchParams] = useSearchParams();
  const [complete, setComplete] = useState(false);

  const dispatch = useAppDispatch();

  const onSubmitForm = async (values: ResetPasswordFormValues) => {
    setDisableButton(true);

    const action = await dispatch(resetPassword({
      passwordConfirmation: values.passwordConfirmation,
      password: values.password,
      code: searchParams.get('code') || '',
    }));

    setComplete(isFulfilled(action));
  };

  return (
    <div className="container">
      <h1 className="mb-40 login-title">Setup new password</h1>
      {!complete && (
        <>
          <ResetPasswordForm onSubmitForm={onSubmitForm} disableButton={disableButton} />

          <div className="form__subtitle">
            Go back to&nbsp;
            <span className="form__label--blue">
              <Link to={routeNames.LOGIN}>log in</Link>
            </span>
          </div>
        </>
      )}

      {complete && (
        <>
          Password has been reset
          <br /><br />
          <span className="form__label--blue">
            <Link to={routeNames.LOGIN}>Go back to login</Link>
          </span>
        </>
      )}

    </div>
  );
};

export default ResetPassword;
