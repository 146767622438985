import { FC, useEffect, useMemo } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HistoryBlock from 'src/components/History/HistoryBlock';
import UsersForm from 'src/components/UsersForm/UsersForm';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getAllCompanies } from 'src/store/companies/thunk';
import { selectUsers } from 'src/store/users/selectors';
import { getUsers, inviteUser } from 'src/store/users/thunk';

const AddUserPage: FC = () => {
  const navigate = useNavigate();
  const { myCompany, companies } = useSelector(selectCompanies);
  const { me, users } = useSelector(selectUsers);
  const dispatch = useAppDispatch();

  const isUsersLimitAchieved = useMemo(() => {
    return !!(myCompany && myCompany.usersLimit && myCompany.usersLimit <= users.length);
  }, [users.length, myCompany]);

  const onSubmit = async (user: { email: string, role: string, companyId: string }) => {
    const action = await dispatch(inviteUser({ user }));
    if (isFulfilled(action)) navigate(routeNames.MANAGE_USERS);
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getAllCompanies());
  }, [dispatch]);

  return (
    <div className="user-form__container">
      <HistoryBlock link={routeNames.MANAGE_USERS} currentPageTitle="Add user" linkedPageTitle="Manage users" />
      {myCompany && (
        <UsersForm
          handleSubmit={onSubmit}
          company={myCompany}
          me={me}
          companies={companies}
          isUsersLimitAchieved={isUsersLimitAchieved}
        />
      )}
    </div>
  );
};

export default AddUserPage;
