import { FC, useEffect, useState } from 'react';

type Props = {
  date: string
}

const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
const currentDay = new Date().getDate();

const PolicyStatus: FC<Props> = ({ date }) => {
  const [title, setTitle] = useState('');
  const [className, setClassName] = useState('');

  useEffect(() => {
    if (new Date(currentYear, currentMonth, currentDay + 7) >= new Date(date)) {
      setTitle('Expires soon');
      setClassName('policy-status_red');
    }
    if (new Date(currentYear, currentMonth, currentDay) > new Date(date)) {
      setTitle('Expired already');
      setClassName('policy-status_red');
    }
    if (new Date(currentYear, currentMonth, currentDay + 61) >= new Date(date)
      && (new Date(currentYear, currentMonth, currentDay + 7) < new Date(date))) {
      setTitle('Expiry Date is on or after 8 days from now');
      setClassName('policy-status_yellow');
    }
    if (new Date(currentYear, currentMonth, currentDay + 61) < new Date(date)) {
      setTitle('Expiry Date is on or after 61 days from now');
      setClassName('policy-status_green');
    }
  }, [date]);

  return (
    <div className={`policy-status ${className}`}>
      <span className="policy-status__rectangle" />
      <span className="policy-status__title">{title}</span>
    </div>
  );
};

export default PolicyStatus;
