import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as Edit } from 'src/assets/icons/Edit.svg';
import { userRoleItems } from 'src/constants';
import { routeNames } from 'src/router';
import { CompanyModel, UserModel } from 'src/types/types';

type SmallMobileTableProps = {
  users?: UserModel[],
  companies?: CompanyModel[],
  me: UserModel,
}

const SmallMobileTable: FC<SmallMobileTableProps> = ({ users, companies, me }) => {
  const navigate = useNavigate();
  const userRole = userRoleItems.find((r) => r.role === me.role.name);

  const isCompanyEditEnabled = userRole?.canUserEditCompanies;

  return (
    <div className="small-mobile-table">
      {companies && companies.map((company) => {
        return (
          <div className="small-mobile-table__card" key={company.companyId}>
            <div className="small-mobile-table__info">
              <div className="small-mobile-table__contact">
                <h5 className="small-mobile-table__contact-head">Company name</h5>
                <p className="small-mobile-table__contact-text small-mobile-table__contact-text-bold">{company.companyName}</p>
              </div>
              <div className="small-mobile-table__contact">
                <h5 className="small-mobile-table__contact-head">Contact name</h5>
                <p className="small-mobile-table__contact-text">{company.contactName}</p>
              </div>
              <div className="small-mobile-table__contact">
                <h5 className="small-mobile-table__contact-head">Contact email</h5>
                <p className="small-mobile-table__contact-text">{company.contactEmail}</p>
              </div>
            </div>
            <div className="small-mobile-table__edit">
              {isCompanyEditEnabled && (
                <Edit
                  onClick={() => navigate(`${routeNames.COMPANIES}/edit/${company.companyId}`)}
                />
              )}
            </div>
          </div>
        );
      })}

      {users && users.map((user) => {
        return (
          <div className="small-mobile-table__card" key={user.userId}>
            <div className="small-mobile-table__info">
              <div className="small-mobile-table__contact">
                <h5 className="small-mobile-table__contact-head">Email</h5>
                <p className="small-mobile-table__contact-text small-mobile-table__contact-text-bold">{user.email}</p>
              </div>
              <div className="small-mobile-table__contact">
                <h5 className="small-mobile-table__contact-head">Phone</h5>
                <p className="small-mobile-table__contact-text">{user.phone}</p>
              </div>
              <div className="small-mobile-table__contact">
                <h5 className="small-mobile-table__contact-head">Name</h5>
                <p className="small-mobile-table__contact-text">{user.fullName ? user.fullName : '---'}</p>
              </div>
            </div>
            <div className="small-mobile-table__edit">
              {isCompanyEditEnabled && (
                <Edit
                  onClick={() => navigate(`${routeNames.MANAGE_USERS}/edit/${user.userId}`)}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SmallMobileTable;
