/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Key, ReactNode, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/blue-arrow.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';

interface FilterProps<T> {
  options: T[],
  onChange: (v: T) => void,
  children?: ReactNode | undefined,
  title: string,
  className?: string,
  customTilte?: string
}

function Filter<T>({ options, onChange, children, title, className, customTilte }: FilterProps<T>) {
  const [isOptionsShown, setIsOptionsShown] = useState(false);
  const ref = useRef(null);

  const iconClassName = classNames('filter__icon', { 'filter__icon--open': isOptionsShown });
  const filterClassName = classNames('filter', { [className || '']: className });

  useOutsideClick(ref, () => setIsOptionsShown(false));

  return (
    <div
      className={filterClassName}
      onClick={() => setIsOptionsShown(!isOptionsShown)}
      role="presentation"
      ref={ref}
    >
      <div className="filter__label">
        <p>{customTilte || title}</p>
        <ArrowIcon className={iconClassName} />
      </div>

      {isOptionsShown && (
        <ul className="filter__options">
          {options.map((option, k) => (
            <li
              role="presentation"
              key={k as Key}
              className="filter__option"
              onClick={() => onChange(option)}
            >
              <p>{option}</p>
            </li>
          ))}
          {children}
        </ul>
      )}
    </div>
  );
}

export default Filter;
