import { FC, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GroupFilter } from 'src/pages/Policy/Policy';
import { TableConfig } from 'src/pages/Policy/TableConfig';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { PolicyModel, UserModel } from 'src/types/types';
import { groupPoliciesByFinishDateStatus, groupPoliciesByKey } from 'src/utils/policy';

import Field from './PolicyTableField';
import Header from './PolicyTableHeader';

type PolicyTableProps = {
  policies: PolicyModel[],
  selectedPolicies: PolicyModel[],
  config: TableConfig[],
  groupBy: GroupFilter,
  changePolicies: (p: PolicyModel[]) => void,
  changeSelectedPolicies: (p: PolicyModel[]) => void,
  user: UserModel,
  enableTips?: boolean,
  enableCheckboxes: boolean
}

type PoliciesFieldsProps = Omit<PolicyTableProps, 'changePolicies' | 'config'> & { activeItems: TableConfig[] }

const Fields: FC<PoliciesFieldsProps> = (props) => {
  const navigate = useNavigate();
  const { policies, activeItems, user, enableTips, enableCheckboxes, selectedPolicies, changeSelectedPolicies } = props;

  const handleFieldClick = (id: string) => navigate(`${routeNames.POLICIES}/${id}`);

  return (
    <ul className="policy-table__fields">
      {policies.map((policy) => (
        <li
          className="policy-table__field"
          key={policy.policyId}
          onClick={() => handleFieldClick(policy.policyId)}
          role="presentation"
        >
          <Field
            config={activeItems}
            policy={policy}
            user={user}
            enableTips={enableTips}
            enableCheckboxes={enableCheckboxes}
            selectedPolicies={selectedPolicies}
            changeSelectedPolicies={changeSelectedPolicies}
          />
        </li>
      ))}
    </ul>
  );
};

const GrouppedFields: FC<PoliciesFieldsProps> = (props) => {
  const {
    groupBy,
    policies,
    activeItems,
    user,
    enableTips,
    enableCheckboxes,
    selectedPolicies,
    changeSelectedPolicies,
  } = props;
  const navigate = useNavigate();
  const handleFieldClick = (id: string) => navigate(`${routeNames.POLICIES}/${id}`);
  const { companies } = useSelector(selectCompanies);

  if (groupBy === GroupFilter.status) {
    const groupedByStatus = Object.entries(groupPoliciesByFinishDateStatus(policies));

    return (
      <ul className="policy-table__fields">
        {groupedByStatus.map((entry) => (
          <li className="policy-table__grouped-field" key={entry[0]}>
            {!!entry[1].length && (
              <>
                <div className="policy-table__grouped-title">{entry[0]}</div>
                {entry[1].map((policy) => (
                  <div
                    className="policy-table__field"
                    key={policy.policyId}
                    onClick={() => handleFieldClick(policy.policyId)}
                    role="presentation"
                  >
                    <Field
                      config={activeItems}
                      policy={policy}
                      user={user}
                      enableTips={enableTips}
                      enableCheckboxes={enableCheckboxes}
                      selectedPolicies={selectedPolicies}
                      changeSelectedPolicies={changeSelectedPolicies}
                    />
                  </div>
                ))}
              </>
            )}
          </li>
        ))}
      </ul>
    );
  }

  let groupedBy;

  switch (groupBy) {
    case GroupFilter.company:
      groupedBy = Object.entries(groupPoliciesByKey(policies,
        'companyId', (p, id) => companies.find((c) => c.companyId === id)?.companyName || 'unknown'));
      break;
    case GroupFilter.contactName:
      groupedBy = Object.entries(groupPoliciesByKey(policies, 'contactName'));
      break;
    default:
      groupedBy = [];
      break;
  }

  return (
    <ul className="policy-table__fields">
      {groupedBy.map((entry) => (
        <li className="policy-table__grouped-field" key={entry[0]}>
          <div className="policy-table__grouped-title">{entry[0]}
          </div>
          {entry[1].map((policy) => (
            <div
              className="policy-table__field"
              key={policy.policyId}
              onClick={() => handleFieldClick(policy.policyId)}
              role="presentation"
            >
              <Field
                config={activeItems}
                policy={policy}
                user={user}
                enableTips={enableTips}
                enableCheckboxes={enableCheckboxes}
                selectedPolicies={selectedPolicies}
                changeSelectedPolicies={changeSelectedPolicies}
              />
            </div>
          ))}
        </li>
      ))}
    </ul>
  );
};

const PolicyTable: FC<PolicyTableProps> = (props) => {
  const {
    config,
    policies,
    user,
    enableTips,
    changePolicies,
    groupBy,
    enableCheckboxes,
    selectedPolicies,
    changeSelectedPolicies,
  } = props;

  const activeItems = useMemo(() => config.filter((i) => i.checked), [config]);

  return (
    <div className="policy-table">
      <Header
        config={activeItems}
        enableTips={enableTips}
        changePolicies={changePolicies}
        policies={policies}
        groupBy={groupBy}
        enableCheckboxes={enableCheckboxes}
        selectedPolicies={selectedPolicies}
        changeSelectedPolicies={changeSelectedPolicies}
      />
      {groupBy === GroupFilter.none && (
        <Fields
          groupBy={groupBy}
          user={user}
          enableTips={enableTips}
          policies={policies}
          activeItems={activeItems}
          enableCheckboxes={enableCheckboxes}
          selectedPolicies={selectedPolicies}
          changeSelectedPolicies={changeSelectedPolicies}
        />
      )}
      {groupBy !== GroupFilter.none && (
        <GrouppedFields
          groupBy={groupBy}
          user={user}
          enableTips={enableTips}
          policies={policies}
          activeItems={activeItems}
          enableCheckboxes={enableCheckboxes}
          selectedPolicies={selectedPolicies}
          changeSelectedPolicies={changeSelectedPolicies}
        />
      )}
    </div>
  );
};

export default PolicyTable;
