/* eslint-disable max-len */
/* eslint-disable complexity */
import { FC, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/Chevron.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { CompanyModel, PolicyModel, UserModel } from 'src/types/types';

import Button from '../Button/Button';
import DateField from '../DateField/DateField';

export type CustomFilterValues = {
  policyNumber: string,
  email: string,
  companyName: string,
  contactName: string,
  dateStart: Date | null,
  dateEnd: Date | null
}

type CustomFilterProps = {
  onClose: () => void,
  onChange: (v: CustomFilterValues) => void,
  companies: CompanyModel[],
  users: UserModel[],
  values: CustomFilterValues,
  policies: PolicyModel[]
}

enum Field {
  policyNumber = 'policyNumber',
  email = 'email',
  company = 'company',
  contact = 'contact',
  none = 'none'
}

const CustomFilter: FC<CustomFilterProps> = ({ onClose, onChange, companies, values, policies }) => {
  const [fieldOpen, setFieldOpen] = useState<Field>(Field.none);
  const [filterValues, setFilterValues] = useState(values);

  const ref = useRef(null);

  const searchPolicyNumber = useMemo(() => {
    return policies.filter((p) => p.policyNumber.toLocaleLowerCase().includes(filterValues.policyNumber.toLocaleLowerCase()));
  }, [policies, filterValues.policyNumber]);

  const searchEmail = useMemo(() => {
    return policies.filter((p) => p.emailToNotify.filter((e) => e.toLocaleLowerCase().includes(filterValues.email.toLocaleLowerCase())).length > 0);
  }, [policies, filterValues.email]);

  const searchCompany = useMemo(() => {
    return companies.filter((c) => c.companyName.toLocaleLowerCase().includes(filterValues.companyName.toLocaleLowerCase()));
  }, [companies, filterValues.companyName]);

  const searchUsers = useMemo(() => {
    return policies.filter((p) => p.contactName.toLocaleLowerCase().includes(filterValues.contactName.toLocaleLowerCase()));
  }, [filterValues.contactName, policies]);

  const policyNumberFieldClassName = classNames(
    'custom-filter__field',
    { 'custom-filter__field--open': fieldOpen === Field.policyNumber },
    { 'custom-filter__field--with-round-border': fieldOpen === Field.policyNumber && !!searchEmail.length },
  );

  const emailFieldClassName = classNames(
    'custom-filter__field',
    { 'custom-filter__field--open': fieldOpen === Field.email },
    { 'custom-filter__field--with-round-border': fieldOpen === Field.email && !!searchEmail.length },
  );

  const companyFieldClassName = classNames(
    'custom-filter__field',
    { 'custom-filter__field--open': fieldOpen === Field.company },
    { 'custom-filter__field--with-round-border': fieldOpen === Field.company && !!searchCompany.length },
  );

  const contactFieldClassName = classNames(
    'custom-filter__field',
    { 'custom-filter__field--open': fieldOpen === Field.contact },
    { 'custom-filter__field--with-round-border': fieldOpen === Field.contact && !!searchUsers.length },
  );

  const handleButtonClick = () => {
    onChange(filterValues);
    onClose();
  };

  const closeField = () => {
    if (fieldOpen !== Field.none) setFieldOpen(Field.none);
  };

  const togglePolicyNumberField = () => {
    if (fieldOpen === Field.policyNumber) setFieldOpen(Field.none);
    if (fieldOpen !== Field.policyNumber) setFieldOpen(Field.policyNumber);
  };

  const toggleEmailField = () => {
    if (fieldOpen === Field.email) setFieldOpen(Field.none);
    if (fieldOpen !== Field.email) setFieldOpen(Field.email);
  };

  const toggleContactField = () => {
    if (fieldOpen === Field.contact) setFieldOpen(Field.none);
    if (fieldOpen !== Field.contact) setFieldOpen(Field.contact);
  };

  const toggleCompanyField = () => {
    if (fieldOpen === Field.company) setFieldOpen(Field.none);
    if (fieldOpen !== Field.company) setFieldOpen(Field.company);
  };

  useOutsideClick(ref, () => {
    setFieldOpen(Field.none);
    onClose();
  });

  return (
    <div className="custom-filter" ref={ref} role="presentation" onClick={closeField}>
      <p className="custom-filter__label">Custom filter</p>

      <div className={policyNumberFieldClassName} onClick={togglePolicyNumberField} role="presentation">
        <input
          type="text"
          className="custom-filter__input"
          placeholder="Policy number"
          value={filterValues.policyNumber}
          onChange={(e) => setFilterValues({ ...filterValues, policyNumber: e.target.value })}
        />
        <ArrowIcon className="custom-filter__arrow-icon" />

        {fieldOpen === Field.policyNumber && (
          <ul className="custom-filter__companies">
            {searchPolicyNumber.map((p) => (
              <li
                className="custom-filter__company"
                key={p.policyNumber}
                role="presentation"
                onClick={() => setFilterValues({ ...filterValues, policyNumber: p.policyNumber })}
              >
                <p>{p.policyNumber}</p>
              </li>
            ))}
          </ul>
        )}

        {!!filterValues.policyNumber.length && (
          <CloseIcon
            className="custom-filter__close-icon"
            onClick={() => setFilterValues({ ...filterValues, policyNumber: '' })}
          />
        )}
      </div>

      <div className={emailFieldClassName} onClick={toggleEmailField} role="presentation">
        <input
          type="text"
          className="custom-filter__input"
          placeholder="Email"
          value={filterValues.email}
          onChange={(e) => setFilterValues({ ...filterValues, email: e.target.value })}
        />
        <ArrowIcon className="custom-filter__arrow-icon" />

        {fieldOpen === Field.email && (
          <ul className="custom-filter__companies">
            {searchEmail.map((m) => (
              <li
                className="custom-filter__company"
                key={m.emailToNotify.join(',')}
                role="presentation"
                onClick={() => setFilterValues({ ...filterValues, email: m.emailToNotify[0] })}
              >
                <p>{m.emailToNotify}</p>
              </li>
            ))}
          </ul>
        )}

        {!!filterValues.email.length && (
          <CloseIcon
            className="custom-filter__close-icon"
            onClick={() => setFilterValues({ ...filterValues, email: '' })}
          />
        )}
      </div>

      <div className={contactFieldClassName} onClick={toggleContactField} role="presentation">
        <input
          type="text"
          className="custom-filter__input"
          placeholder="Contact name"
          value={filterValues.contactName}
          onChange={(e) => setFilterValues({ ...filterValues, contactName: e.target.value })}
        />
        <ArrowIcon className="custom-filter__arrow-icon" />

        {fieldOpen === Field.contact && (
          <ul className="custom-filter__companies">
            {searchUsers.map((p) => (
              <li
                className="custom-filter__company"
                role="presentation"
                key={p.contactName}
                onClick={() => setFilterValues({ ...filterValues, contactName: p.contactName })}
              >
                <p>{p.contactName}</p>
              </li>
            ))}
          </ul>
        )}

        {!!filterValues.contactName.length && (
          <CloseIcon
            className="custom-filter__close-icon"
            onClick={() => setFilterValues({ ...filterValues, contactName: '' })}
          />
        )}
      </div>

      <div className={companyFieldClassName} onClick={toggleCompanyField} role="presentation">
        <input
          type="text"
          className="custom-filter__input"
          placeholder="Company name"
          value={filterValues.companyName}
          onChange={(e) => setFilterValues({ ...filterValues, companyName: e.target.value })}
        />
        <ArrowIcon className="custom-filter__arrow-icon" />

        {fieldOpen === Field.company && (
          <ul className="custom-filter__companies">
            {searchCompany.map((c) => (
              <li
                className="custom-filter__company"
                key={c.companyId}
                role="presentation"
                onClick={() => setFilterValues({ ...filterValues, companyName: c.companyName })}
              >
                <p>{c.companyName}</p>
              </li>
            ))}
          </ul>
        )}

        {!!filterValues.companyName.length && (
          <CloseIcon
            className="custom-filter__close-icon"
            onClick={() => setFilterValues({ ...filterValues, companyName: '' })}
          />
        )}
      </div>

      <div className="custom-filter__dates">
        <DateField
          date={filterValues.dateStart}
          placeholder="Expiry day from"
          minDate={filterValues.dateEnd}
          onChange={(d: Date | null) => setFilterValues({ ...filterValues, dateStart: d })}
        />
        <DateField
          date={filterValues.dateEnd}
          placeholder="Expiry day to"
          minDate={filterValues.dateStart}
          onChange={(d: Date | null) => setFilterValues({ ...filterValues, dateEnd: d })}
        />

        {(filterValues.dateEnd || filterValues.dateStart) && (
          <CloseIcon
            className="custom-filter__close-icon"
            onClick={() => setFilterValues({ ...filterValues, dateEnd: null, dateStart: null })}
          />
        )}
      </div>

      <Button title="Apply filter" onClick={handleButtonClick} />
    </div>
  );
};

export default CustomFilter;
