import { FC } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as ChangePlanIcon } from 'src/assets/icons/change.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/icons/Logout.svg';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { logout } from 'src/store/auth/authSlice';
import { clearAllCompanies } from 'src/store/companies/companiesSlice';
import { clearAllUsers } from 'src/store/users/userSlice';
import { BillingType, CompanyModel, UserModel } from 'src/types/types';

type ProfileProps = {
  company: CompanyModel,
  me: UserModel,
  billingPlan: BillingType,
  closeMobileMenu: () => void
}

const Profile: FC<ProfileProps> = ({ me, company, billingPlan, closeMobileMenu }) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(clearAllCompanies());
    dispatch(clearAllUsers());
    dispatch(logout());
    closeMobileMenu();
  };

  return (
    <div className="profile">
      {company.companyName && <h4 className="profile__title">Hi, {company.companyName}</h4>}
      <p className="profile__email">{me.email}</p>
      <p className="profile__billing">
        {`
          ${billingPlan.name}, ${billingPlan.prices.length ? billingPlan.prices[0].price / 100 : 'Free'} (
          ${billingPlan.metadata.usersLimit || 'unlimited'} users,
          ${billingPlan.metadata.companiesLimit || 'unlimited'} companies,
          ${billingPlan.metadata.policiesLimit || 'unlimited'} policies)
        `}
      </p>
      <div className="profile__actions">
        <Link className="profile__change-plan" to={routeNames.BILLING}>
          <ChangePlanIcon className="profile__change-plan-icon" />
          <p className="profile__change-plan-text">Change plan</p>
        </Link>
        <div className="profile__logout" onClick={handleLogout} role="presentation">
          <LogoutIcon className="profile__logout-icon" />
          <p className="profile__logout-text">Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
