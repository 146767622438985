import { FC } from 'react';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import Button from 'src/components/Button/Button';

type AlertProps = {
  onClose: () => void,
  callback: () => void
}

const Alert: FC<AlertProps> = ({ onClose, callback }) => {
  return (
    <div className="alert-overlay" onClick={onClose} role="presentation">
      <div className="alert" onClick={(e) => e.stopPropagation()} role="presentation">
        <CloseIcon className="alert__close-icon" onClick={onClose} />
        <h3 className="alert__title">Decline selected</h3>
        <p className="alert__text">Are you sure you want to decline selected policies?</p>
        <div className="alert__buttons">
          <Button title="Go back" className="alert__button" onClick={onClose} />
          <Button title="Yes, decline" appearance="transparent" className="alert__button" onClick={callback} />
        </div>
      </div>
    </div>
  );
};

export default Alert;
