import { FC, useRef } from 'react';

import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { Link } from 'react-router-dom';

import Button from 'src/components/Button/Button';
import { routeNames } from 'src/router';
import { LoginFormValues } from 'src/types/types';

import { validationSchema } from './validationSchema';

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

type LoginFormProps = {
  loading?: boolean,
  onSubmitForm: (values: LoginFormValues) => void;
}

const LoginForm: FC<LoginFormProps> = ({ onSubmitForm, loading }) => {
  const ref = useRef<FormikProps<LoginFormValues>>(null);

  const submit = (values: LoginFormValues) => {
    onSubmitForm(values);
  };

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur={false}
      onSubmit={submit}
    >
      {({ errors }) => (
        <Form className="form">
          <div className="form__input-box">
            <div className="form__item-block">
              <h5>Email</h5>
              <Field
                className={classNames({ 'input-text': true, 'input-text_error': errors.email })}
                name="email"
                placeholder="mail@company-name.com"
              />
              <span className="form__error">
                <ErrorMessage name="email" />
              </span>
            </div>

            <div className="form__item-block">
              <div className="form__label-block">
                <h5>Password</h5>
                <Link className="form__label--blue" to={routeNames.FORGOT_PASSWORD}>Forgot password?</Link>
              </div>
              <Field
                type="password"
                className={classNames({ 'input-text': true, 'input-text_error': errors.password })}
                name="password"
                placeholder="*********"
              />
              <span className="form__error">
                <ErrorMessage name="password" />
              </span>
            </div>
          </div>

          <Button
            title="Log in"
            type="submit"
            disabled={loading}
            loading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
