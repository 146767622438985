import { FC, useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';

import Header from 'src/components/Header/Header';

import AppRouter from './components/AppRouter/AppRouter';
import Snackbar from './components/Snackbar/Snackbar';
import { useAuthenticated } from './hooks/useAuthenticated';
import { useAppDispatch } from './hooks/useDispatchType';
import { clear } from './store/activities/activitiesSlice';
import { selectActivities } from './store/activities/selectors';
import { logout } from './store/auth/authSlice';
import { selectAuth } from './store/auth/selector';
import { getBillings } from './store/billings/thunk';
import { selectCompanies } from './store/companies/selectors';
import { getMyCompany } from './store/companies/thunk';
import { selectUsers } from './store/users/selectors';
import { getMe } from './store/users/thunk';

const App: FC = () => {
  const { isAuth, isLoading } = useSelector(selectAuth);
  const { message, error } = useSelector(selectActivities);
  const { me } = useSelector(selectUsers);
  const { myCompany } = useSelector(selectCompanies);
  const dispatch = useAppDispatch();

  useAuthenticated();

  // Current project structure is sucks. We need to always have
  // authenticated user and his company in store
  // Better approach would be create a special hoc or hook to handle this data
  // Temporary approach is to use this function here
  const handleAuthentications = useCallback(async () => {
    if (isAuth) {
      if (!me) dispatch(getMe());
      if (me && !myCompany) dispatch(getMyCompany({ id: me.companyId }));
      dispatch(getBillings());
    } else {
      document.documentElement.style.setProperty('--color-blue', '#144DDE');
      document.documentElement.style.setProperty(
        '--color-dark-blue', '#0e43c8',
      );
    }
  }, [dispatch, me, myCompany, isAuth]);

  const renderSnackbar = () => {
    if (error) {
      return <Snackbar text={error} error onClear={() => dispatch(clear())} />;
    }
    if (message) {
      return <Snackbar text={message} success onClear={() => dispatch(clear())} />;
    }

    return <></>;
  };

  useEffect(() => {
    handleAuthentications();
  }, [handleAuthentications]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//js-na1.hs-scripts.com/8024134.js';
    script.id = 'hs-script-loader';
    script.defer = true;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Header logout={() => dispatch(logout())} isAuth={isAuth} />
      {!isLoading
        && (
          <div className="wrapper">
            <AppRouter isAuth={isAuth} />
          </div>
        )}
      {renderSnackbar()}
    </>
  );
};
export default App;
