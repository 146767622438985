import { createSlice } from '@reduxjs/toolkit';

import { sliceNames } from 'src/constants';
import { CompanyModel } from 'src/types/types';

import {
  createCompany,
  getCompanies,
  getCompaniesCount,
  getCompanyById,
  getMyCompany,
  updateCompany,
  getAllCompanies,
} from './thunk';

const companiesSlice = createSlice({
  name: sliceNames.companies,
  initialState: {
    companies: [] as CompanyModel[],
    company: {} as CompanyModel,
    myCompany: null as (CompanyModel | null),
    pages: [] as number[],
    currentPage: 1,
  },
  reducers: {
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    clearAllCompanies(state) {
      state.companies = [];
      state.company = {} as CompanyModel;
      state.myCompany = null;
    },
    clearCompany(state) {
      state.company = {} as CompanyModel;
    },
  },
  extraReducers: (builder) => {
    // get companies
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });

    // get all companies
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });

    // create company

    builder.addCase(createCompany.fulfilled, (state, action) => {
      state.companies.push(action.payload);
    });

    // get company by id

    builder.addCase(getCompanyById.fulfilled, (state, action) => {
      state.company = action.payload;
    });

    // get my company

    builder.addCase(getMyCompany.fulfilled, (state, action) => {
      state.myCompany = action.payload;
    });

    // update company

    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.companies = state.companies.map((company) => {
        if (company.companyId === action.payload.companyId) {
          return action.payload;
        }
        return company;
      });
      state.company = {} as CompanyModel;
    });

    // pagination

    builder.addCase(getCompaniesCount.fulfilled, (state, { payload }) => {
      const pages = [] as number[];
      for (let i = 1; i <= Math.ceil(payload / 10); i += 1) {
        pages.push(i);
      }
      state.pages = pages;
    });
  },
});

export const { setCurrentPage, clearAllCompanies, clearCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
