/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useMemo, useState } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'src/components/Button/Button';
import HistoryBlock from 'src/components/History/HistoryBlock';
import PolicyForm, { PolicyFormValues } from 'src/components/PolicyForm/PolicyForm';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getCompanies } from 'src/store/companies/thunk';
import { selectPolicies } from 'src/store/policies/selectors';
import { createPolicy, getPolicies } from 'src/store/policies/thunk';
import { CompanyModel } from 'src/types/types';
import { serializePolicy } from 'src/utils/policy';

const initialValues: PolicyFormValues = {
  name: '',
  policyNumber: '',
  contactName: '',
  emailToNotify: [],
  company: {} as CompanyModel,
  coverageAmount: '',
  finishDate: null,
  notifications: [],
  notes: '',
  attachment: [],
};

// TODO fix styles and class names
const PoliciesFormPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { myCompany, companies } = useSelector(selectCompanies);
  const { policies } = useSelector(selectPolicies);

  const [values, setValues] = useState<PolicyFormValues>(initialValues);

  const isPoliciesLimitAchieved = useMemo(() => {
    return !!(myCompany && myCompany.policiesLimit && myCompany.policiesLimit <= policies.length);
  }, [myCompany, policies.length]);

  const submitForm = async () => {
    const action = await dispatch(createPolicy({
      policy: serializePolicy(values),
      file: values.attachment[0] || null,
    }));

    if (isFulfilled(action)) navigate(-1);
  };

  useEffect(() => {
    if (myCompany) setValues({ ...values, company: myCompany });
    if (!myCompany) dispatch(getCompanies());
    if (policies.length) dispatch(getPolicies());
  }, [myCompany]);

  return (
    <div className="policies-form__container">
      <HistoryBlock link={routeNames.POLICIES} currentPageTitle="Add policy" linkedPageTitle="Policies" />
      <h1 className="policies-form__title">Add policy</h1>
      {myCompany && (
        <PolicyForm
          values={values}
          onChange={(v) => setValues(v)}
          companies={companies}
          submit={submitForm}
        >
          {!isPoliciesLimitAchieved && (
            <div className="policy-form__buttons">
              <Button title="Add policy" type="submit" />
            </div>
          )}
        </PolicyForm>
      )}
    </div>
  );
};

export default PoliciesFormPage;
