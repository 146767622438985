/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PolicyApi from 'src/api/PolicyApi';
import Button from 'src/components/Button/Button';
import HistoryBlock from 'src/components/History/HistoryBlock';
import PolicyForm, { PolicyFormValues } from 'src/components/PolicyForm/PolicyForm';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getCompanies } from 'src/store/companies/thunk';
import { selectPolicies } from 'src/store/policies/selectors';
import { deletePolicy, getPolicyById, updatePolicy } from 'src/store/policies/thunk';
import { deserializePolicy, serializePolicy } from 'src/utils/policy';

const EditPolicyForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();

  const { companies } = useSelector(selectCompanies);
  const { policy } = useSelector(selectPolicies);

  const [inititalValues, setInitialValues] = useState<PolicyFormValues | null>(null);

  const onDeletePolicy = async () => {
    const action = await dispatch(deletePolicy({ id }));
    if (isFulfilled(action)) navigate(routeNames.POLICIES);
  };

  const submitForm = async () => {
    if (!inititalValues?.attachment.length && policy.attachment.length) {
      await PolicyApi.removeAttachment(policy.attachment[0].id);
    }

    const action = await dispatch(updatePolicy({
      id,
      policy: serializePolicy(inititalValues as PolicyFormValues),
      file: inititalValues?.attachment[0] || null,
    }));

    if (isFulfilled(action)) navigate(-1);
  };

  useEffect(() => {
    dispatch(getPolicyById({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (!companies.length) {
      dispatch(getCompanies());
    }
  }, [companies, dispatch, policy]);

  useEffect(() => {
    if (policy) {
      const policyCompany = companies.find((c) => c.companyId === policy.companyId);
      if (policyCompany) {
        const deserializedPolicy = deserializePolicy(policy, policyCompany);
        setInitialValues(deserializedPolicy);
      }
    }
  }, [companies, policy]);

  // TODO fix class names
  return (
    <div className="policies-form__container">
      <HistoryBlock link={routeNames.POLICIES} currentPageTitle="Edit policy" linkedPageTitle="Policies" />
      <h1 className="policies-form__title">Edit policy</h1>
      {inititalValues && (
        <PolicyForm
          values={inititalValues}
          onChange={(v) => setInitialValues(v)}
          companies={companies}
          submit={submitForm}
        >
          <div className="edit-policy-form__buttons">
            <Button title="Save" type="submit" />
            <Button title="Delete" appearance="transparent" onClick={onDeletePolicy} />
          </div>
        </PolicyForm>
      )}
    </div>
  );
};

export default EditPolicyForm;
