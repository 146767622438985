import { configureStore } from '@reduxjs/toolkit';

import policiesReducer from 'src/store/policies/policiesSlice';
import userReducer from 'src/store/users/userSlice';

import activitiesReducer from './activities/activitiesSlice';
import authSlice from './auth/authSlice';
import billingsReducer from './billings/billingsSlice';
import companiesReducer from './companies/companiesSlice';

const store = configureStore({
  reducer: {
    users: userReducer,
    policies: policiesReducer,
    companies: companiesReducer,
    billings: billingsReducer,
    activities: activitiesReducer,
    auth: authSlice,
  },
});

export type AppDispatchType = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>

export default store;
