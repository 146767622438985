import { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as PaymentSuccess } from 'src/assets/icons/payment-success.svg';
import Button from 'src/components/Button/Button';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectBillings } from 'src/store/billings/selectors';
import { selectCompanies } from 'src/store/companies/selectors';
import { getMyCompany } from 'src/store/companies/thunk';
import { selectUsers } from 'src/store/users/selectors';

const BillingSuccess: FC = () => {
  const dispatch = useAppDispatch();

  const { myCompany } = useSelector(selectCompanies);
  const { billings } = useSelector(selectBillings);
  const { me } = useSelector(selectUsers);

  // TODO add redux selector and state for current billing plan
  const myBilling = billings.find((b) => b.id === myCompany?.billingId);

  useEffect(() => {
    if (me) dispatch(getMyCompany({ id: me.companyId }));
  }, [dispatch, me]);

  return (
    <div className="billing-success">
      <div className="billing-success__icon"><PaymentSuccess /></div>
      <h3 className="billing-success__title">Your payment was completed successfully</h3>
      <p className="billing-success__subtitle">
        You are successfully subscribed to the {myBilling && myBilling.name} plan
      </p>
      <Link to={routeNames.BILLING}><Button title="Back to plans" className="billing-success__button" /></Link>
    </div>
  );
};

export default BillingSuccess;
