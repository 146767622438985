import { ReactElement } from 'react';

import AddCompanyPage from 'src/pages/AddCompanyPage';
import AddUserPage from 'src/pages/AddUserPage';
import BillingPage from 'src/pages/BillingPage';
import BillingSuccess from 'src/pages/BillingSucceess';
import CompaniesPage from 'src/pages/CompaniesPage';
import EditCompanyForm from 'src/pages/EditCompanyPage';
import EditPolicyForm from 'src/pages/EditPolicyPage';
import EditUserPage from 'src/pages/EditUserPage';
import ForgotPassword from 'src/pages/ForgotPassword';
import Login from 'src/pages/Login';
import ManageUsers from 'src/pages/ManageUsers';
import PoliciesFormPage from 'src/pages/PoliciesFormPage';
import Policy from 'src/pages/Policy/Policy';
import PolicyInfoPage from 'src/pages/PolicyInfoPage';
import ResetPassword from 'src/pages/ResetPassword';
import SignUp from 'src/pages/SignUp';
import UpdatePolicy from 'src/pages/UpdatePolicyPage';
import WarningPage from 'src/pages/WarningPage';

export interface IRoute {
  path: string,
  component: ReactElement,
  exact?: boolean
}

export enum routeNames {
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',
  SIGNUP = '/signup',
  WARNING = '/warning',
  POLICIES = '/policies',
  ADD_POLICY = '/policies/add',
  EDIT_POLICY = '/policies/edit/:id',
  UPDATE_POLICY = '/policies/update/:id',
  MANAGE_USERS = '/users',
  ADD_USER = '/users/add',
  EDIT_USER = '/users/edit/:id',
  COMPANIES = '/companies',
  ADD_COMPANY = '/companies/add',
  EDIT_COMPANY = '/companies/edit/:id',
  REVIEWING = '/reviewing',
  BILLING = '/billing',
  POLICY = '/policies/:id',
  BILLING_SUCCESS = '/billing/success'
}

export const publicRoutes: IRoute[] = [
  { path: routeNames.LOGIN, exact: true, component: <Login /> },
  { path: routeNames.FORGOT_PASSWORD, exact: true, component: <ForgotPassword /> },
  { path: routeNames.RESET_PASSWORD, exact: true, component: <ResetPassword /> },
  { path: routeNames.SIGNUP, exact: true, component: <SignUp /> },
  { path: routeNames.WARNING, exact: true, component: <WarningPage /> },
  { path: routeNames.UPDATE_POLICY, exact: true, component: <UpdatePolicy /> },
];

export const privateRoutes: IRoute[] = [
  { path: routeNames.POLICIES, exact: true, component: <Policy /> },
  { path: routeNames.ADD_POLICY, exact: true, component: <PoliciesFormPage /> },
  { path: routeNames.EDIT_POLICY, exact: true, component: <EditPolicyForm /> },
  { path: routeNames.MANAGE_USERS, exact: true, component: <ManageUsers /> },
  { path: routeNames.ADD_USER, exact: true, component: <AddUserPage /> },
  { path: routeNames.EDIT_USER, exact: true, component: <EditUserPage /> },
  { path: routeNames.COMPANIES, exact: true, component: <CompaniesPage /> },
  { path: routeNames.ADD_COMPANY, exact: true, component: <AddCompanyPage /> },
  { path: routeNames.EDIT_COMPANY, exact: true, component: <EditCompanyForm /> },
  { path: routeNames.REVIEWING, exact: true, component: <Policy /> },
  { path: routeNames.BILLING, exact: true, component: <BillingPage /> },
  { path: routeNames.POLICY, exact: true, component: <PolicyInfoPage /> },
  { path: routeNames.BILLING_SUCCESS, exact: true, component: <BillingSuccess /> },
  { path: routeNames.UPDATE_POLICY, exact: true, component: <UpdatePolicy /> },
];
