export enum OptionSize {
  large = 0.7,
  standart = 1,
  middle = 1.2,
  small = 1.4
}

export type TableConfig = {
  name: string,
  checked: boolean,
  default: boolean,
  value: string,
  size: OptionSize,
  customise: boolean
}

const tableOptions: TableConfig[] = [
  {
    name: 'Policy name',
    checked: true,
    value: 'name',
    size: OptionSize.large,
    default: true,
    customise: false,
  },
  {
    name: 'Policy number',
    checked: true,
    default: true,
    value: 'policyNumber',
    size: OptionSize.standart,
    customise: true,
  },
  {
    name: 'Expiry date',
    checked: true,
    default: true,
    value: 'finishDate',
    size: OptionSize.middle,
    customise: true,
  },
  {
    name: 'Coverage amount',
    checked: false,
    default: false,
    value: 'coverageAmount',
    size: OptionSize.standart,
    customise: true,
  },
  {
    name: 'Contact name',
    checked: false,
    default: false,
    value: 'contactName',
    size: OptionSize.standart,
    customise: true,
  },
  {
    name: 'Last update',
    checked: true,
    default: true,
    value: 'updated_at',
    size: OptionSize.middle,
    customise: true,
  },
  {
    name: 'Notes',
    checked: true,
    default: true,
    value: 'notes',
    size: OptionSize.standart,
    customise: true,
  },
  {
    name: 'Notifications',
    checked: true,
    value: 'notifications',
    size: OptionSize.middle,
    default: true,
    customise: false,
  },
];

export default tableOptions;
