/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC } from 'react';

import { ReactComponent as AttachIcon } from 'src/assets/icons/Attach.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/Delete.svg';

type FileInputProps = {
  onChange: (v: File | null) => void,
  value: File[]
}

const FileInput: FC<FileInputProps> = ({ onChange, value }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) onChange(e.target.files[0]);
  };

  return (
    <label className="form-file-input">
      {!!value.length && <p className="form-file-input__text">{value[0].name}</p>}
      {!value.length && <p className="form-file-input__text form-file-input__placeholder">Upload file</p>}

      {!!value.length && (
        <DeleteIcon
          className="form-file-input__icon"
          onClick={(e) => {
            e.preventDefault();
            onChange(null);
          }}
        />
      )}

      {!value.length && <AttachIcon className="form-file-input__icon" />}

      <input
        type="file"
        className="form-file-input__input"
        onChange={handleChange}
      />
    </label>
  );
};

export default FileInput;
