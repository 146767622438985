import axios from 'axios';

import { mainInstanse } from 'src/axios';
import { PolicyFormModel, PolicyModel, PolicyNotificationModel } from 'src/types/types';

const PolicyApi = {
  async fetchPolicies(start?: number): Promise<PolicyModel[]> {
    if (start || start === 0) {
      const res = await mainInstanse.get<PolicyModel[]>(`/policies?_start=${start}&_limit=${10}`);
      return res.data;
    }

    const res = await mainInstanse.get<PolicyModel[]>('/policies');
    return res.data;
  },

  async createpolicy(policy: PolicyFormModel, file: File | null): Promise<PolicyModel> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(policy));
    if (file !== null) formData.append('files.attachment', file);
    const res = await mainInstanse.multipartPost<PolicyModel>('post', '/policies', formData);
    return res.data;
  },

  async findPolicyById(id: string): Promise<PolicyModel> {
    const res = await mainInstanse.get(`/policies/${id}`);
    return res.data;
  },

  async updatePolicy(id: string, policy: PolicyFormModel, file: File | null): Promise<PolicyModel> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(policy));
    if (file !== null) formData.append('files.attachment', file);
    const res = await mainInstanse.multipartPost<PolicyModel>('put', `/policies/${id}`, formData);
    return res.data;
  },

  async removeAttachment(id: number): Promise<File> {
    const res = await mainInstanse.delete<File>(`/policies/removeAttachment/${id}`);
    return res.data;
  },

  async partiallyUpdatePolicy(id: string, startDate: string, finishDate: string, notifications: string[], file: File | null) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ startDate, finishDate, notifications }));
    if (file !== null) formData.append('files.attachment', file);
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/policies/partiallyUpdate/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res;
  },

  async deletePolicy(id: string): Promise<PolicyModel> {
    const res = await mainInstanse.delete<PolicyModel>(`/policies/${id}`);
    return res.data;
  },

  async approvePolicy(id: string): Promise<PolicyModel> {
    const approved = JSON.stringify({ approved: true });
    const data = new FormData();
    data.append('data', approved);
    const res = await mainInstanse.multipartPost<PolicyModel>('put', `/policies/${id}`, data);
    return res.data;
  },

  async fetchPoliciesCount(): Promise<number> {
    const res = await mainInstanse.get<number>('/policies/count');
    return res.data;
  },

  async sendNotification(id: string): Promise<void> {
    const data = new FormData();
    const res = await mainInstanse.multipartPost<void>('post', `/policies/${id}/sendNotification`, data);
    return res.data;
  },

  async getNotifications(id: string): Promise<PolicyNotificationModel[]> {
    const res = await mainInstanse.get(`/policies/${id}/notifications`);
    return res.data;
  },
};

export default PolicyApi;
