import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuthApi } from 'src/api/AuthApi';
import { SignUpByInvite, SignUpUserModel } from 'src/types/types';

export const loginUser = createAsyncThunk(
  'users/loginUser',
  async (params: { email: string, password: string }) => {
    const { email, password } = params;
    const res = await AuthApi.login(email, password);
    return res;
  },
);

export const forgotPassword = createAsyncThunk(
  'users/forgotPassword',
  async (params: { email: string }) => {
    const { email } = params;
    const res = await AuthApi.forgotPassword(email);
    return res;
  },
);

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (params: { password: string, passwordConfirmation: string, code: string }) => {
    const { password, passwordConfirmation, code } = params;
    const res = await AuthApi.resetPassword(password, passwordConfirmation, code);
    return res;
  },
);

export const registerUser = createAsyncThunk(
  'users/registerUser',
  async (params: SignUpUserModel) => {
    const res = await AuthApi.signup(params);
    return res;
  },
);

export const registerUserByInvite = createAsyncThunk(
  'users/registerByInvite',
  async (params: SignUpByInvite) => {
    const res = await AuthApi.signUpByInvite(params);
    return res;
  },
);

export const getMe = createAsyncThunk(
  'users/getMe',
  async () => {
    const res = await AuthApi.getMe();
    return res;
  },
);
