import { PolicyModel, FilterType } from 'src/types/types';

export const getCustomiseFilterPolicies = (policies: PolicyModel[],
  filter: FilterType): PolicyModel[] => {
  let filteredPolicies = policies;

  if (filter.name) {
    filteredPolicies = filteredPolicies.filter((policy) => policy.contactName.includes(filter.name));
  }

  if (filter.expireFrom) {
    filteredPolicies = filteredPolicies.filter((policy) => new Date(policy.finishDate) > new Date(filter.expireFrom));
  }

  if (filter.expireTo) {
    filteredPolicies = filteredPolicies.filter((policy) => new Date(policy.finishDate) < new Date(filter.expireTo));
  }

  return filteredPolicies;
};
