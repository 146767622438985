export const API_URL = process.env.REACT_APP_API_URL;

export const groupItems = ['Name', 'Email', 'Start date', 'Expire Date'];

export const phoneRegExp = (
  /^\+\d{7,15}$/g);

export const userRoles = {
  viewer: 'viewer',
  user: 'user',
};

export const filterBlockTitles = {
  filter: 'Filter',
  groupBy: 'Group by',
};

export const filters = {
  myPolicies: 'Only my policies',
  expiresThisMonth: 'Expires this month',
  expiresNextMonth: 'Expires next month',
};

export const companyType = {
  company: 'company',
  agency: 'agency',
};

export const userRoleItems = [
  {
    role: 'viewer',
    companyTypes: [companyType.company, companyType.agency],
    possibilities: ['Can view policies', "Can't update them"],
    canInviteUsers: false,
    canUserManagePolicies: false,
    canUserEditCompanies: false,
    canUserAddCompanies: false,
    rolesUserCanManage: [],
  },
  {
    role: 'user',
    companyTypes: [companyType.company, companyType.agency],
    possibilities: ['Can add new policies', 'Update existing policies'],
    canInviteUsers: false,
    canUserEditCompanies: false,
    canUserAddCompanies: false,
    canUserManagePolicies: true,
    rolesUserCanManage: [],
  },
  {
    role: 'company manager',
    companyTypes: [companyType.company],
    possibilities: ['Everything a User can do', 'Add new Users and Managers'],
    canInviteUsers: true,
    canUserManagePolicies: true,
    canUserAddCompanies: false,
    canUserEditCompanies: false,
    rolesUserCanManage: ['viewer', 'user', 'company manager'],
  },
  {
    role: 'agency manager',
    companyTypes: [companyType.agency],
    possibilities: ['Everything a User can do', 'Add new Users and Managers'],
    canInviteUsers: true,
    canUserEditCompanies: false,
    canUserAddCompanies: false,
    canUserManagePolicies: true,
    rolesUserCanManage: ['viewer', 'user', 'agency manager', 'company manager'],
  },
  {
    role: 'company admin',
    companyTypes: [companyType.company],
    possibilities: ['Everything a Manager can do', 'Can update billing & company information'],
    canInviteUsers: true,
    canUserEditCompanies: true,
    canUserManagePolicies: true,
    canUserAddCompanies: false,
    rolesUserCanManage: ['viewer', 'user', 'company manager', 'company admin'],
  },
  {
    role: 'agency admin',
    companyTypes: [companyType.agency],
    possibilities: ['Everything a Manager can do', 'Can update billing & company information'],
    canInviteUsers: true,
    canUserEditCompanies: true,
    canUserManagePolicies: true,
    canUserAddCompanies: true,
    rolesUserCanManage: ['viewer', 'user', 'agency manager', 'agency admin', 'company manager', 'company admin'],
  },
];

export const tableDateStatus = {
  expiredOnWeek: 'Expiry Date is on or before one week from now',
  expiredSoon: 'Expiry Date is on or after 8 days from now',
  actual: 'Expiry Date is on or after 61 days from now',
};

export const groupsBy = {
  contactName: 'Contact name',
  status: 'Status',
  companyId: 'Company',
};

export const mycomplyToken = 'mycomply_token';

export const sliceNames = {
  policies: 'policies',
  billings: 'billings',
  users: 'users',
  companies: 'companies',
  activities: 'activities',
};

// TODO I think better approach will be to create special entity on the back end for default billing plan
export const freeBillingPlan = {
  description: 'Default billing plan',
  id: 'free-billing-plan',
  metadata: {
    usersLimit: '1',
    policiesLimit: '3',
    companiesLimit: '1',
    features:
      `Single company
1 User
3 policies / contracts / licenses
Unlimited third party reminders
Email support
Secure TLS 1.3 encryption protection
AWS KMS HIPAA data compliance`,
  },
  name: 'Free',
  prices: [],
};
