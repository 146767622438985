import * as yup from 'yup';

import { phoneRegExp } from 'src/constants';

export const addUserValidationSchema = yup.object({
  email: yup.string().email('Provided email has invalid format').required('Please enter email'),
  role: yup.string().required('Please choose role'),
});

export const editUserValidationSchema = yup.object({
  email: yup.string().email('Provided email has invalid format').required('Please enter email'),
  role: yup.string().required('Please choose role'),
  name: yup.string(),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});
