import axios from 'axios';

import { API_URL } from 'src/constants';
import { Attachment } from 'src/types/types';

export const downloadFiles = (files: Attachment[]): void => {
  files.forEach((file) => {
    axios({ url: `${API_URL}${file.url}`, method: 'GET', responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  });
};
