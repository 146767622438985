import { FC } from 'react';

import classNames from 'classnames';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

import { ReactComponent as CalendarIcon } from 'src/assets/icons/Calendar.svg';

type DateSelectorProps = {
  disabled?: boolean,
  initialDate: Date | null,
  onChange: (d: Date) => void,
}

const DateSelector: FC<DateSelectorProps> = ({ disabled, initialDate, onChange }) => {
  const selectorClassName = classNames('form-date-selector', { 'form-date-selector--disabled': disabled });

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const handleChangeTime = (time: string) => {
    if (initialDate) {
      const validTime = time.replace('_', '0');
      const [hours, minutes] = validTime.split(':');
      const date = new Date(initialDate);
      date.setHours(Number(hours));
      date.setMinutes(Number(minutes));
      onChange(date);
    }
  };

  const dateInput = (
    <div className="form-date-selector__field-wrapper form-date-selector__field-wrapper--date">
      <CalendarIcon className="form-date-selector__calendar-icon" />
      <input
        className="form-date-selector__field"
        placeholder="Select date"
        disabled
        value={initialDate ? format(initialDate, 'dd MMM yyy') : ''}
      />
    </div>
  );

  return (
    <div className={selectorClassName}>
      <div className="form-date-selector__fields">
        <DatePicker
          disabled={disabled}
          selected={initialDate}
          onChange={(date: Date) => onChange(date)}
          customInput={dateInput}
          minDate={minDate}
        />
        {initialDate && (
          <InputMask
            mask="99:99"
            value={initialDate ? initialDate.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3') : ''}
            className="form-date-selector__field form-date-selector__field--time"
            placeholder="00:00"
            disabled={disabled}
            onChange={(e) => handleChangeTime(e.target.value)}
          />
        )}
      </div>

    </div>
  );
};

export default DateSelector;
