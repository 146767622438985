import { FC, useState } from 'react';

import { ReactComponent as SearchIcon } from 'src/assets/icons/Search.svg';

type SearchProps = {
  placeholder: string,
  onChange: (v: string) => void
}

const Search: FC<SearchProps> = ({ placeholder, onChange }) => {
  const [mobileShown, setMobileShown] = useState(true);

  return (
    <div
      className="search"
    >
      <SearchIcon
        className="search__icon"
        onClick={() => setMobileShown(!mobileShown)}
        role="presentation"
      />
      <input
        type="text"
        className="search__field"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default Search;
