import { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import UpdatePolicyForm from 'src/components/PoliciesForm/UpdatePolicyForm';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getCompanies } from 'src/store/companies/thunk';
import { selectPolicies } from 'src/store/policies/selectors';
import { getPolicyById } from 'src/store/policies/thunk';
import { selectUsers } from 'src/store/users/selectors';

import PolicyApi from '../api/PolicyApi';

const UpdatePolicyPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { me } = useSelector(selectUsers);
  const { policy } = useSelector(selectPolicies);
  const { companies } = useSelector(selectCompanies);

  const { id = '' } = useParams<{ id: string }>();
  const onSubmitForm = async (startDate, finishDate, notifications, file) => {
    await PolicyApi.partiallyUpdatePolicy(id, startDate, finishDate, notifications, file);
    if (me) {
      navigate(routeNames.REVIEWING);
    } else navigate(routeNames.LOGIN);
  };

  const findPolicyCompany = () => {
    return companies.find((c) => {
      return c.companyId === policy.companyId;
    });
  };

  useEffect(() => {
    dispatch(getPolicyById({ id }));
    dispatch(getCompanies());
  }, [dispatch, id]);

  return (
    <div className="policies-form__container">
      <h1>Update policy for {findPolicyCompany()?.companyName}</h1>
      <UpdatePolicyForm onSubmit={onSubmitForm} policy={policy} />
    </div>
  );
};

export default UpdatePolicyPage;
