import { FC } from 'react';

const Tips: FC = () => (
  <ul className="policy-table-tips">
    <li className="policy-table-tips__tip">
      Expiry Date is on or before one week from now
    </li>
    <li className="policy-table-tips__tip">
      Expiry Date is on or after 8 days from now
    </li>
    <li className="policy-table-tips__tip">
      Expiry Date is on or after 61 days from now
    </li>
  </ul>
);

export default Tips;
