import { createAsyncThunk } from '@reduxjs/toolkit';

import BillingApi from 'src/api/BillingApi';

export const getBillings = createAsyncThunk(
  'billings/getBillings',
  async () => {
    const res = await BillingApi.fetchBillings();
    return res.data;
  },
);

export const getPaymentLink = createAsyncThunk(
  'billings/getPaymentLink',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await BillingApi.getPaymantLink(id);
    return res.data;
  },
);

export const unsubscribe = createAsyncThunk(
  'billings/getPaymentLink',
  async () => {
    const res = await BillingApi.unsubscribe();
    return res.data;
  },
);
