/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/Plus.svg';
import Button from 'src/components/Button/Button';
import CustomFilter, { CustomFilterValues } from 'src/components/CustomFilter/CustomFilter';
import Filter from 'src/components/Filter/Filter';
import PolicyTable from 'src/components/PolicyTable/PolicyTable';
import Search from 'src/components/Search/Search';
import TableEditor from 'src/components/TableEditor/TableEditor';
import { userRoleItems } from 'src/constants';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getCompanies } from 'src/store/companies/thunk';
import { selectPolicies } from 'src/store/policies/selectors';
import { approvedPolicies, deletePolicies, getPolicies } from 'src/store/policies/thunk';
import { selectUsers } from 'src/store/users/selectors';
import { getUsers } from 'src/store/users/thunk';
import { PolicyModel } from 'src/types/types';
import { sortPolicies } from 'src/utils/policy';
import { checkIsDateInRange } from 'src/utils/timeUtils';

import Alert from './Alert';
import tableOptions, { TableConfig } from './TableConfig';

enum FilterOptions {
  expiresThisMonth = 'Expires this month',
  expiresNextMonth = 'Expires next month',
  custom = 'custom',
  all = 'all'
}

export enum GroupFilter {
  none = 'none',
  company = 'Company',
  contactName = 'Contact name',
  status = 'Status'
}

const customFilterInitialValues: CustomFilterValues = {
  policyNumber: '',
  email: '',
  companyName: '',
  contactName: '',
  dateEnd: null,
  dateStart: null,
};

const groupByOptions = [GroupFilter.company, GroupFilter.contactName, GroupFilter.status];
const filterOptions = [FilterOptions.expiresThisMonth, FilterOptions.expiresNextMonth];

const Policy: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const prevConfig = (() => {
    const config = localStorage.getItem('tableConfig');
    if (config) return JSON.parse(config).tableConfig;
    return null;
  })();

  const [searchValue, setSearchValue] = useState('');
  const [filterOption, setFilterOption] = useState(FilterOptions.all);
  const [groupBy, setGroupBy] = useState(GroupFilter.none);
  const [isCustomerFilterShown, setCustomFilterShown] = useState(false);
  const [tableConfig, setTableConfig] = useState(prevConfig || tableOptions);
  const [policiesToShow, setPoliciesToShow] = useState<PolicyModel[]>([]);
  const [customFilterValues, setCustomFilterValues] = useState<CustomFilterValues>(customFilterInitialValues);
  const [selectedPolicies, setSelectedPolicies] = useState<PolicyModel[]>([]);
  const [isAlertShown, setAlertShown] = useState(false);

  const isReviewingPage = location.pathname === routeNames.REVIEWING;

  const { companies, myCompany } = useSelector(selectCompanies);
  const { users, me } = useSelector(selectUsers);
  const { policies } = useSelector(selectPolicies);

  const isCustomFilterValuesEmpty = useMemo(() => {
    return !(
      !!customFilterValues.companyName.length
      || !!customFilterValues.contactName.length
      || customFilterValues.dateEnd
      || customFilterValues.dateStart
    );
  }, [customFilterValues]);

  const isPoliciesLimitAchieved = useMemo(() => {
    return !!(myCompany && myCompany.policiesLimit && myCompany.policiesLimit <= policies.length);
  }, [myCompany, policies.length]);

  const canManagePolicies = userRoleItems.find((r) => r.role === me?.role.name)?.canUserManagePolicies;

  const applyCustomFilters = useCallback((policies: PolicyModel[]) => {
    return policies.filter((p) => {
      const company = companies.find((c) => c.companyId === p.companyId);

      const companyNameMatches = customFilterValues.companyName.length
        ? company?.companyName.toLocaleLowerCase() === customFilterValues.companyName.toLocaleLowerCase()
        : true;

      const contactNameMatches = customFilterValues.contactName.length
        ? customFilterValues.contactName.toLocaleLowerCase() === p.contactName.toLocaleLowerCase()
        : true;

      const finishDateMatches = p.finishDate && checkIsDateInRange(
        customFilterValues.dateStart,
        customFilterValues.dateEnd,
        new Date(p.finishDate),
      );

      return contactNameMatches && companyNameMatches && finishDateMatches;
    });
  }, [companies, customFilterValues]);

  const filterPolicies = useCallback((policies: PolicyModel[]) => {
    switch (filterOption) {
      case FilterOptions.expiresNextMonth:
        return policies.filter((p) => {
          const nextMonth = new Date().getMonth() === 11 ? 0 : new Date().getMonth() + 1;
          return p.finishDate && new Date(p.finishDate).getMonth() === nextMonth;
        });
      case FilterOptions.expiresThisMonth:
        return policies.filter((p) => p.finishDate && new Date(p.finishDate).getMonth() === new Date().getMonth());
      case FilterOptions.custom:
        return applyCustomFilters(policies);
      default:
        return policies;
    }
  }, [applyCustomFilters, filterOption]);

  const clearFilterValues = () => {
    setFilterOption(FilterOptions.all);
    setCustomFilterValues(customFilterInitialValues);
  };

  const handleCustomFilterChange = (v: CustomFilterValues) => {
    setFilterOption(FilterOptions.custom);
    setCustomFilterValues(v);
  };

  // TODO add debounce here
  const handleApproveClick = () => {
    if (!selectedPolicies.length) dispatch(approvedPolicies({ ids: policiesToShow.map((p) => p.policyId) }));
    if (selectedPolicies.length) dispatch(approvedPolicies({ ids: selectedPolicies.map((p) => p.policyId) }));
    setSelectedPolicies([]);
  };

  const declinePolicies = () => {
    if (!selectedPolicies.length) dispatch(deletePolicies({ ids: policiesToShow.map((p) => p.policyId) }));
    if (selectedPolicies.length) dispatch(deletePolicies({ ids: selectedPolicies.map((p) => p.policyId) }));
    setSelectedPolicies([]);
    setAlertShown(false);
  };

  const setTableConfigToLocalStorage = (tableConfig: TableConfig[]) => {
    localStorage.setItem('tableConfig', JSON.stringify({ tableConfig }));
  };

  const changeTableConfig = (v: TableConfig[]) => {
    setTableConfig(v);
    setTableConfigToLocalStorage(v);
  };

  useEffect(() => {
    if (!companies.length) dispatch(getCompanies());
    if (!users.length) dispatch(getUsers());
    dispatch(getPolicies());
  }, [companies.length, dispatch, users.length]);

  useEffect(() => {
    const filteredPolicies = filterPolicies(policies);
    const sortedPolicies = sortPolicies(filteredPolicies, 'name', false);

    if (isReviewingPage) setPoliciesToShow(sortedPolicies.filter((p) => !p.approved));
    if (!isReviewingPage) setPoliciesToShow(sortedPolicies.filter((p) => p.approved));
  }, [filterPolicies, isReviewingPage, policies]);

  // TODO need add this after preloaders will be implemented
  // if (isReviewingPage && !policiesToShow.length) {
  //   return (
  //     <div className="policy">
  //       <ReviewingEmptyState />
  //     </div>
  //   );
  // }

  const customFilteredPolicies = () => {
    if (customFilterValues.email) {
      return policies.filter((p) => p.emailToNotify.filter((e) => e.toLocaleLowerCase() === customFilterValues.email.toLocaleLowerCase()).length > 0);
    }
    if (customFilterValues.policyNumber) {
      return policies.filter((p) => p.policyNumber.toLocaleLowerCase() === customFilterValues.policyNumber.toLocaleLowerCase());
    }
    if (customFilterValues.contactName) {
      return policies.filter((p) => p.contactName.toLocaleLowerCase() === customFilterValues.contactName.toLocaleLowerCase());
    }
    if (customFilterValues.companyName) {
      const searchedCompany = companies.find((c) => c.companyName === customFilterValues.companyName);
      return policies.filter((p) => p.companyId.toLocaleLowerCase() === searchedCompany?.companyId.toLocaleLowerCase());
    }
    return policiesToShow;
  };

  const switchFilter = searchValue
    ? policies.filter((p) => p.name.includes(searchValue.toLocaleLowerCase()))
    : customFilteredPolicies();

  return (
    <div className="policy">
      {isAlertShown && (<Alert onClose={() => setAlertShown(false)} callback={declinePolicies} />)}
      <h1 className="policy__title">{isReviewingPage ? 'Reviewing' : 'Policies'}</h1>
      <div className="policy__controllers">
        <div className="policy__filters">
          <Search placeholder="Search by policy name" onChange={(v) => setSearchValue(v)} />
          <Filter
            options={filterOptions}
            onChange={(v) => setFilterOption(v)}
            title="Filter"
          >
            <div
              className="policy__filter-option"
              role="presentation"
              onClick={() => setCustomFilterShown(true)}
            >
              <p>Custom filter</p>
            </div>
          </Filter>

          {(filterOption !== FilterOptions.all || !isCustomFilterValuesEmpty) && (
            <div className="policy__close-filter" role="presentation" onClick={clearFilterValues}>
              <CloseIcon />
            </div>
          )}

          {isCustomerFilterShown && (
            <div className="policy__custom-filter">
              <CustomFilter
                onClose={() => setCustomFilterShown(false)}
                onChange={(v: CustomFilterValues) => handleCustomFilterChange(v)}
                companies={companies}
                users={users}
                values={customFilterValues}
                policies={policies}
              />
            </div>
          )}

          <Filter
            options={groupByOptions}
            onChange={(v) => setGroupBy(v)}
            title="Group by"
            customTilte={groupBy !== GroupFilter.none ? `Grouped by ${groupBy}` : undefined}
            className="policy__group-by"
          />

          {groupBy !== GroupFilter.none && (
            <div className="policy__close-filter" role="presentation" onClick={() => setGroupBy(GroupFilter.none)}>
              <CloseIcon />
            </div>
          )}

          <TableEditor
            title="Customise"
            className="policy__editor"
            values={tableConfig}
            onChange={(v: TableConfig[]) => changeTableConfig(v)}
          />
        </div>

        {(!isReviewingPage && canManagePolicies) && (
          <Link
            to={isPoliciesLimitAchieved ? routeNames.BILLING : routeNames.ADD_POLICY}
            className="policy__add-policy"
          >
            <Button title="Add policy"><PlusIcon /></Button>
          </Link>
        )}

        {!!isReviewingPage && (
          <div className="policy__buttons">
            <Button
              onClick={() => setAlertShown(true)}
              disabled={!policiesToShow.length}
              title={selectedPolicies.length ? 'Decline selected' : 'Decline all'}
              appearance="transparent"
            />
            <Button
              disabled={!policiesToShow.length}
              onClick={handleApproveClick}
              title={selectedPolicies.length ? 'Approve selected' : 'Approve all'}
            />
          </div>
        )}
      </div>

      {me && (
        <div className="policy__table">
          <PolicyTable
            policies={switchFilter}
            config={tableConfig}
            user={me}
            groupBy={groupBy}
            changePolicies={(polcies: PolicyModel[]) => setPoliciesToShow(polcies)}
            enableCheckboxes={isReviewingPage}
            selectedPolicies={selectedPolicies}
            changeSelectedPolicies={(v: PolicyModel[]) => setSelectedPolicies(v)}
            enableTips
          />
        </div>
      )}

      {(!!isReviewingPage && policiesToShow.length > 0) && (
        <div className="policy__buttons policy__buttons-mobile">
          <Button
            disabled={!policiesToShow.length}
            onClick={handleApproveClick}
            title={selectedPolicies.length ? 'Approve selected' : 'Approve all'}
          />
          <Button
            onClick={() => setAlertShown(true)}
            disabled={!policiesToShow.length}
            title={selectedPolicies.length ? 'Decline selected' : 'Decline all'}
            appearance="transparent"
          />
        </div>
      )}
    </div>
  );
};

export default Policy;
