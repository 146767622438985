import { mainInstanse } from 'src/axios';
import { CompanyModel, CompanyFormModel } from 'src/types/types';

const CompanyApi = {

  async fetchCompanies(): Promise<CompanyModel[]> {
    const res = await mainInstanse.get<CompanyModel[]>('/companies');
    return res.data;
  },

  async fetchAllCompanies(): Promise<CompanyModel[]> {
    const res = await mainInstanse.get<CompanyModel[]>('/companies');
    return res.data;
  },

  async createCompany(company: CompanyFormModel, logo?: File | null): Promise<CompanyModel> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(company));
    if (logo) { formData.append('files.logo', logo || null); }
    const res = await mainInstanse.multipartPost<CompanyModel>('post', '/companies', formData);
    return res.data;
  },

  async getCompanyById(id: string): Promise<CompanyModel> {
    const res = await mainInstanse.get(`/companies/${id}`);
    return res.data;
  },

  async updateCompany(company: CompanyFormModel, id: string, logo?: File | null): Promise<CompanyModel> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(company));
    if (logo) { formData.append('files.logo', logo || null); }
    const res = await mainInstanse.multipartPost<CompanyModel>('put', `/companies/${id}`, formData);
    return res.data;
  },

  async deleteCompany(id: string): Promise<CompanyModel> {
    const res = await mainInstanse.delete<CompanyModel>(`/companies/${id}`);
    return res.data;
  },

  async onboardCompany(id: string): Promise<{ ok: true }> {
    const res = await mainInstanse.post<{ ok: true }>(`/companies/onboard/${id}`);
    return res.data;
  },

  async fetchCompaniesCount(): Promise<number> {
    const res = await mainInstanse.get<number>('/companies/count');
    return res.data;
  },
};

export default CompanyApi;
