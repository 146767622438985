import { FC } from 'react';

import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import Button from '../Button/Button';
import Phone from '../Phone/Phone';
import { signUpByInviteValidationSchema } from './validationSchema';

export type SignUpByInviteFormValues = {
  phone: string,
  password: string,
  passwordConfirmation: string
}

type SignUpByInviteProps = {
  onSubmit: (v: SignUpByInviteFormValues) => void
  loading: boolean
}

const SignUpByInvite: FC<SignUpByInviteProps> = ({ onSubmit, loading }) => {
  const updatePhone = (setFieldValue, setFieldTouched, validateField, value: string) => {
    setFieldValue('phone', value, true);
    setTimeout(() => {
      setFieldTouched('phone', true);
    });
  };

  const initialValues: SignUpByInviteFormValues = {
    phone: '',
    password: '',
    passwordConfirmation: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signUpByInviteValidationSchema}
      validateOnChange
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, setFieldTouched, validateField, errors, isValid }) => (
        <Form className="form">
          <div className="form__item-block">
            <h5>What’s your number?</h5>
            <Phone
              className={classNames({ 'input-text': true, 'input-text_error': errors.phone })}
              onChange={(value) => { updatePhone(setFieldValue, setFieldTouched, validateField, value); }}
            />

            <span className="form__error">
              <ErrorMessage name="phone" />
            </span>
          </div>

          <div className="form__item-block">
            <h5>What’s your password?</h5>
            <Field
              type="password"
              className={classNames({ 'input-text': true, 'input-text_error': errors.password })}
              name="password"
              placeholder="*********"
            />
            <span className="form__error">
              <ErrorMessage name="password" />
            </span>
          </div>

          <div className="form__item-block">
            <h5>Confirm password</h5>
            <Field
              type="password"
              className={classNames({ 'input-text': true, 'input-text_error': errors.passwordConfirmation })}
              name="passwordConfirmation"
              placeholder="*********"
            />
            <span className="form__error">
              <ErrorMessage name="passwordConfirmation" />
            </span>
          </div>

          <Button disabled={!isValid} title="Get started" type="submit" loading={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default SignUpByInvite;
