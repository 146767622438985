import { FC } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { privateRoutes, publicRoutes, routeNames } from 'src/router';

type AppRouterProps = {
  isAuth: boolean
}

const AppRouter: FC<AppRouterProps> = ({ isAuth }) => {
  return (
    isAuth ? (
      <Routes>
        {privateRoutes.map((route) => (
          <Route
            path={route.path}
            element={route.component}
            key={route.path}
          />
        ))}
        <Route
          path="*"
          element={<Navigate to={routeNames.POLICIES} />}
        />
      </Routes>
    )
      : (
        <Routes>
          {publicRoutes.map((route) => (
            <Route
              path={route.path}
              element={route.component}
              key={route.path}
            />
          ))}
          <Route
            path="*"
            element={<Navigate to={routeNames.LOGIN} />}
          />
        </Routes>
      )
  );
};

export default AppRouter;
