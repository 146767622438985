/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as BurgerMenu } from 'src/assets/icons/burger_menu.svg';
import { ReactComponent as Close } from 'src/assets/icons/close.svg';
import { ReactComponent as MobileMenuArrow } from 'src/assets/icons/mobile_menu_right_arrow.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/profile.svg';
import { ReactComponent as Logo } from 'src/assets/images/Logo.svg';
import { freeBillingPlan } from 'src/constants';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectBillings } from 'src/store/billings/selectors';
import { selectCompanies } from 'src/store/companies/selectors';
import { selectPolicies } from 'src/store/policies/selectors';
import { selectUsers } from 'src/store/users/selectors';
import { getUsers } from 'src/store/users/thunk';

import Profile from '../Profile/Profile';

const navItemsForAgency = [{
  title: 'Policies',
  link: routeNames.POLICIES,
}, {
  title: 'Reviewing',
  link: routeNames.REVIEWING,
}, {
  title: 'Companies',
  link: routeNames.COMPANIES,
}, {
  title: 'Manage users',
  link: routeNames.MANAGE_USERS,
}, {
  title: 'Billing',
  link: routeNames.BILLING,
}];

const navItemsForCompany = [{
  title: 'Policies',
  link: routeNames.POLICIES,
}, {
  title: 'Reviewing',
  link: routeNames.REVIEWING,
}, {
  title: 'Manage users',
  link: routeNames.MANAGE_USERS,
}, {
  title: 'Billing',
  link: routeNames.BILLING,
}];

type HeaderProps = {
  isAuth: boolean,
  logout: () => void
}

type HeaderNotificationProps = {
  message: string
}

const HeaderNotification: FC<HeaderNotificationProps> = ({ message }) => {
  return (
    <div className="header-notification">
      <p>{message}</p>
      <Link to={routeNames.BILLING} className="header-notification__button">
        Upgrade now
      </Link>
    </div>
  );
};

const Header: FC<HeaderProps> = ({ isAuth, logout }) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const [mobileMenuShown, setMobileMenuShown] = useState(false);
  const [profileShown, setProfileShown] = useState(false);

  const { myCompany } = useSelector(selectCompanies);
  const { companies } = useSelector(selectCompanies);
  const { me, users } = useSelector(selectUsers);
  const { policies } = useSelector(selectPolicies);
  const { billings } = useSelector(selectBillings);

  const isPoliciesLimitAchieved = myCompany?.policiesLimit && myCompany?.policiesLimit <= policies.length;
  const isCompaniesLimitAchieved = myCompany?.companiesLimit && myCompany.companiesLimit <= companies.length;
  const isUsersLimitAchieved = myCompany?.usersLimit && myCompany.usersLimit <= users.length;

  // TODO move company types to constants
  const routes = (() => {
    if (myCompany) return myCompany?.companyType === 'company' ? navItemsForCompany : navItemsForAgency;
    return [];
  })();

  // TODO this issue is temporary, need to add selector for my current billing plan
  const myBilling = useMemo(() => {
    const currentBilling = billings.find((b) => b.id === myCompany?.billingId);
    if (currentBilling) return currentBilling;
    return freeBillingPlan;
  }, [billings, myCompany?.billingId]);

  const notificationMessage = (() => {
    // need to change conditions when pagination will be done
    if (pathname === routeNames.POLICIES && isPoliciesLimitAchieved) {
      return `You have the ${myBilling.name} plan. You have 0 out of ${myCompany?.policiesLimit} policies left.`;
    }
    if (pathname === routeNames.COMPANIES && isCompaniesLimitAchieved) {
      return `You have the ${myBilling.name} plan. You have 0 out of ${myCompany?.companiesLimit} companies left.`;
    }
    if (pathname === routeNames.MANAGE_USERS && isUsersLimitAchieved) {
      return `You have the ${myBilling.name} plan. You have 0 out of ${myCompany?.usersLimit} users left.`;
    }

    return undefined;
  })();

  useEffect(() => {
    if (isAuth) dispatch(getUsers());
  }, [dispatch]);

  return (
    <>
      <header className={mobileMenuShown ? 'header mobileOpened' : 'header'}>
        {notificationMessage && <HeaderNotification message={notificationMessage} />}

        <div className="header__container">
          <div className="header__logo mt-5 cu-p">
            <Link to={routeNames.POLICIES} onClick={() => setMobileMenuShown(false)}>
              {myCompany?.logo
                && (
                  <img
                    style={{ maxWidth: '500px', maxHeight: '40px' }}
                    alt=""
                    src={`${process.env.REACT_APP_API_URL}${myCompany?.logo?.url}`}
                  />
                )}

              {myCompany && !myCompany?.logo
                && (
                  <Logo />
                )}

            </Link>
          </div>

          <>
            {isAuth
              && (
                <>
                  <ul className={mobileMenuShown ? 'header__nav' : 'header__nav mobileClosed'}>
                    {myCompany?.companyType === 'company'}
                    {routes.map((item) => (
                      <NavLink
                        to={item.link}
                        key={item.title}
                        className={(navData) => (navData.isActive ? 'header__nav-item_active' : 'header__nav-item')}
                        onClick={() => setMobileMenuShown(false)}
                      >
                        <li
                          className={classNames({
                            'header__nav-item': true,

                          })}
                          role="presentation"
                        >
                          {item.title}
                        </li>
                        {mobileMenuShown && <MobileMenuArrow />}
                      </NavLink>
                    ))}
                  </ul>

                  {mobileMenuShown
                    ? (
                      <div
                        className="header__burger header__burger-close"
                        onClick={() => setMobileMenuShown(false)}
                        role="presentation"
                      >
                        <Close />
                      </div>
                    )
                    : (
                      <div
                        className="header__burger"
                        onClick={() => setMobileMenuShown(true)}
                        role="presentation"
                      >
                        <BurgerMenu />
                      </div>
                    )}

                  <div
                    className={mobileMenuShown ? 'header__profile mobileOpened' : 'header__profile'}
                    onClick={() => setProfileShown(!profileShown)}
                    onMouseLeave={() => setProfileShown(false)}
                    role="presentation"
                  >
                    <ProfileIcon className="header__profile-icon" />
                    <p className="header__profile-text">Profile</p>

                    {(me && myCompany && profileShown) && (
                      <div className="header__profile-content">
                        <Profile
                          me={me}
                          company={myCompany}
                          billingPlan={myBilling}
                          closeMobileMenu={() => setMobileMenuShown(false)}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
          </>
        </div>
      </header>
    </>
  );
};

export default Header;
