const countryCodesIndexed = {
  byName: {
    Afghanistan: {
      name: 'Afghanistan',
      dial_code: '+93',
      code: 'AF',
      emoji: '🇦🇫',
    },
    'Aland Islands': {
      name: 'Aland Islands',
      dial_code: '+358',
      code: 'AX',
      emoji: '🇦🇽',
    },
    Albania: { name: 'Albania', dial_code: '+355', code: 'AL', emoji: '🇦🇱' },
    Algeria: { name: 'Algeria', dial_code: '+213', code: 'DZ', emoji: '🇩🇿' },
    'American Samoa': {
      name: 'American Samoa',
      dial_code: '+1684',
      code: 'AS',
      emoji: '🇦🇸',
    },
    Andorra: { name: 'Andorra', dial_code: '+376', code: 'AD', emoji: '🇦🇩' },
    Angola: { name: 'Angola', dial_code: '+244', code: 'AO', emoji: '🇦🇴' },
    Anguilla: { name: 'Anguilla', dial_code: '+1264', code: 'AI', emoji: '🇦🇮' },
    Antarctica: {
      name: 'Antarctica',
      dial_code: '+672',
      code: 'AQ',
      emoji: '🇦🇶',
    },
    'Antigua and Barbuda': {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      code: 'AG',
      emoji: '🇦🇬',
    },
    Argentina: { name: 'Argentina', dial_code: '+54', code: 'AR', emoji: '🇦🇷' },
    Armenia: { name: 'Armenia', dial_code: '+374', code: 'AM', emoji: '🇦🇲' },
    Aruba: { name: 'Aruba', dial_code: '+297', code: 'AW', emoji: '🇦🇼' },
    Australia: { name: 'Australia', dial_code: '+61', code: 'AU', emoji: '🇦🇺' },
    Austria: { name: 'Austria', dial_code: '+43', code: 'AT', emoji: '🇦🇹' },
    Azerbaijan: {
      name: 'Azerbaijan',
      dial_code: '+994',
      code: 'AZ',
      emoji: '🇦🇿',
    },
    Bahamas: { name: 'Bahamas', dial_code: '+1242', code: 'BS', emoji: '🇧🇸' },
    Bahrain: { name: 'Bahrain', dial_code: '+973', code: 'BH', emoji: '🇧🇭' },
    Bangladesh: {
      name: 'Bangladesh',
      dial_code: '+880',
      code: 'BD',
      emoji: '🇧🇩',
    },
    Barbados: { name: 'Barbados', dial_code: '+1246', code: 'BB', emoji: '🇧🇧' },
    Belarus: { name: 'Belarus', dial_code: '+375', code: 'BY', emoji: '🇧🇾' },
    Belgium: { name: 'Belgium', dial_code: '+32', code: 'BE', emoji: '🇧🇪' },
    Belize: { name: 'Belize', dial_code: '+501', code: 'BZ', emoji: '🇧🇿' },
    Benin: { name: 'Benin', dial_code: '+229', code: 'BJ', emoji: '🇧🇯' },
    Bermuda: { name: 'Bermuda', dial_code: '+1441', code: 'BM', emoji: '🇧🇲' },
    Bhutan: { name: 'Bhutan', dial_code: '+975', code: 'BT', emoji: '🇧🇹' },
    'Bolivia, Plurinational State of': {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
      emoji: '🇧🇴',
    },
    'Bosnia and Herzegovina': {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      code: 'BA',
      emoji: '🇧🇦',
    },
    Botswana: { name: 'Botswana', dial_code: '+267', code: 'BW', emoji: '🇧🇼' },
    Brazil: { name: 'Brazil', dial_code: '+55', code: 'BR', emoji: '🇧🇷' },
    'British Indian Ocean Territory': {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      code: 'IO',
      emoji: '🇮🇴',
    },
    'Brunei Darussalam': {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      code: 'BN',
      emoji: '🇧🇳',
    },
    Bulgaria: { name: 'Bulgaria', dial_code: '+359', code: 'BG', emoji: '🇧🇬' },
    'Burkina Faso': {
      name: 'Burkina Faso',
      dial_code: '+226',
      code: 'BF',
      emoji: '🇧🇫',
    },
    Burundi: { name: 'Burundi', dial_code: '+257', code: 'BI', emoji: '🇧🇮' },
    Cambodia: { name: 'Cambodia', dial_code: '+855', code: 'KH', emoji: '🇰🇭' },
    Cameroon: { name: 'Cameroon', dial_code: '+237', code: 'CM', emoji: '🇨🇲' },
    Canada: { name: 'Canada', dial_code: '+1', code: 'CA', emoji: '🇨🇦' },
    'Cape Verde': {
      name: 'Cape Verde',
      dial_code: '+238',
      code: 'CV',
      emoji: '🇨🇻',
    },
    'Cayman Islands': {
      name: 'Cayman Islands',
      dial_code: '+ 345',
      code: 'KY',
      emoji: '🇰🇾',
    },
    'Central African Republic': {
      name: 'Central African Republic',
      dial_code: '+236',
      code: 'CF',
      emoji: '🇨🇫',
    },
    Chad: { name: 'Chad', dial_code: '+235', code: 'TD', emoji: '🇹🇩' },
    Chile: { name: 'Chile', dial_code: '+56', code: 'CL', emoji: '🇨🇱' },
    China: { name: 'China', dial_code: '+86', code: 'CN', emoji: '🇨🇳' },
    'Christmas Island': {
      name: 'Christmas Island',
      dial_code: '+61',
      code: 'CX',
      emoji: '🇨🇽',
    },
    'Cocos (Keeling) Islands': {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      code: 'CC',
      emoji: '🇨🇨',
    },
    Colombia: { name: 'Colombia', dial_code: '+57', code: 'CO', emoji: '🇨🇴' },
    Comoros: { name: 'Comoros', dial_code: '+269', code: 'KM', emoji: '🇰🇲' },
    Congo: { name: 'Congo', dial_code: '+242', code: 'CG', emoji: '🇨🇬' },
    'Congo, The Democratic Republic of the Congo': {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD',
      emoji: '🇨🇩',
    },
    'Cook Islands': {
      name: 'Cook Islands',
      dial_code: '+682',
      code: 'CK',
      emoji: '🇨🇰',
    },
    'Costa Rica': {
      name: 'Costa Rica',
      dial_code: '+506',
      code: 'CR',
      emoji: '🇨🇷',
    },
    "Cote d'Ivoire": {
      name: "Cote d'Ivoire",
      dial_code: '+225',
      code: 'CI',
      emoji: '🇨🇮',
    },
    Croatia: { name: 'Croatia', dial_code: '+385', code: 'HR', emoji: '🇭🇷' },
    Cuba: { name: 'Cuba', dial_code: '+53', code: 'CU', emoji: '🇨🇺' },
    Cyprus: { name: 'Cyprus', dial_code: '+357', code: 'CY', emoji: '🇨🇾' },
    'Czech Republic': {
      name: 'Czech Republic',
      dial_code: '+420',
      code: 'CZ',
      emoji: '🇨🇿',
    },
    Denmark: { name: 'Denmark', dial_code: '+45', code: 'DK', emoji: '🇩🇰' },
    Djibouti: { name: 'Djibouti', dial_code: '+253', code: 'DJ', emoji: '🇩🇯' },
    Dominica: { name: 'Dominica', dial_code: '+1767', code: 'DM', emoji: '🇩🇲' },
    'Dominican Republic': {
      name: 'Dominican Republic',
      dial_code: '+1849',
      code: 'DO',
      emoji: '🇩🇴',
    },
    Ecuador: { name: 'Ecuador', dial_code: '+593', code: 'EC', emoji: '🇪🇨' },
    Egypt: { name: 'Egypt', dial_code: '+20', code: 'EG', emoji: '🇪🇬' },
    'El Salvador': {
      name: 'El Salvador',
      dial_code: '+503',
      code: 'SV',
      emoji: '🇸🇻',
    },
    'Equatorial Guinea': {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      code: 'GQ',
      emoji: '🇬🇶',
    },
    Eritrea: { name: 'Eritrea', dial_code: '+291', code: 'ER', emoji: '🇪🇷' },
    Estonia: { name: 'Estonia', dial_code: '+372', code: 'EE', emoji: '🇪🇪' },
    Ethiopia: { name: 'Ethiopia', dial_code: '+251', code: 'ET', emoji: '🇪🇹' },
    'Falkland Islands (Malvinas)': {
      name: 'Falkland Islands (Malvinas)',
      dial_code: '+500',
      code: 'FK',
      emoji: '🇫🇰',
    },
    'Faroe Islands': {
      name: 'Faroe Islands',
      dial_code: '+298',
      code: 'FO',
      emoji: '🇫🇴',
    },
    Fiji: { name: 'Fiji', dial_code: '+679', code: 'FJ', emoji: '🇫🇯' },
    Finland: { name: 'Finland', dial_code: '+358', code: 'FI', emoji: '🇫🇮' },
    France: { name: 'France', dial_code: '+33', code: 'FR', emoji: '🇫🇷' },
    'French Guiana': {
      name: 'French Guiana',
      dial_code: '+594',
      code: 'GF',
      emoji: '🇬🇫',
    },
    'French Polynesia': {
      name: 'French Polynesia',
      dial_code: '+689',
      code: 'PF',
      emoji: '🇵🇫',
    },
    Gabon: { name: 'Gabon', dial_code: '+241', code: 'GA', emoji: '🇬🇦' },
    Gambia: { name: 'Gambia', dial_code: '+220', code: 'GM', emoji: '🇬🇲' },
    Georgia: { name: 'Georgia', dial_code: '+995', code: 'GE', emoji: '🇬🇪' },
    Germany: { name: 'Germany', dial_code: '+49', code: 'DE', emoji: '🇩🇪' },
    Ghana: { name: 'Ghana', dial_code: '+233', code: 'GH', emoji: '🇬🇭' },
    Gibraltar: {
      name: 'Gibraltar',
      dial_code: '+350',
      code: 'GI',
      emoji: '🇬🇮',
    },
    Greece: { name: 'Greece', dial_code: '+30', code: 'GR', emoji: '🇬🇷' },
    Greenland: {
      name: 'Greenland',
      dial_code: '+299',
      code: 'GL',
      emoji: '🇬🇱',
    },
    Grenada: { name: 'Grenada', dial_code: '+1473', code: 'GD', emoji: '🇬🇩' },
    Guadeloupe: {
      name: 'Guadeloupe',
      dial_code: '+590',
      code: 'GP',
      emoji: '🇬🇵',
    },
    Guam: { name: 'Guam', dial_code: '+1671', code: 'GU', emoji: '🇬🇺' },
    Guatemala: {
      name: 'Guatemala',
      dial_code: '+502',
      code: 'GT',
      emoji: '🇬🇹',
    },
    Guernsey: { name: 'Guernsey', dial_code: '+44', code: 'GG', emoji: '🇬🇬' },
    Guinea: { name: 'Guinea', dial_code: '+224', code: 'GN', emoji: '🇬🇳' },
    'Guinea-Bissau': {
      name: 'Guinea-Bissau',
      dial_code: '+245',
      code: 'GW',
      emoji: '🇬🇼',
    },
    Guyana: { name: 'Guyana', dial_code: '+595', code: 'GY', emoji: '🇬🇾' },
    Haiti: { name: 'Haiti', dial_code: '+509', code: 'HT', emoji: '🇭🇹' },
    'Holy See (Vatican City State)': {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      code: 'VA',
      emoji: '🇻🇦',
    },
    Honduras: { name: 'Honduras', dial_code: '+504', code: 'HN', emoji: '🇭🇳' },
    'Hong Kong': {
      name: 'Hong Kong',
      dial_code: '+852',
      code: 'HK',
      emoji: '🇭🇰',
    },
    Hungary: { name: 'Hungary', dial_code: '+36', code: 'HU', emoji: '🇭🇺' },
    Iceland: { name: 'Iceland', dial_code: '+354', code: 'IS', emoji: '🇮🇸' },
    India: { name: 'India', dial_code: '+91', code: 'IN', emoji: '🇮🇳' },
    Indonesia: { name: 'Indonesia', dial_code: '+62', code: 'ID', emoji: '🇮🇩' },
    'Iran, Islamic Republic of Persian Gulf': {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR',
      emoji: '🇮🇷',
    },
    Iraq: { name: 'Iraq', dial_code: '+964', code: 'IQ', emoji: '🇮🇶' },
    Ireland: { name: 'Ireland', dial_code: '+353', code: 'IE', emoji: '🇮🇪' },
    'Isle of Man': {
      name: 'Isle of Man',
      dial_code: '+44',
      code: 'IM',
      emoji: '🇮🇲',
    },
    Israel: { name: 'Israel', dial_code: '+972', code: 'IL', emoji: '🇮🇱' },
    Italy: { name: 'Italy', dial_code: '+39', code: 'IT', emoji: '🇮🇹' },
    Jamaica: { name: 'Jamaica', dial_code: '+1876', code: 'JM', emoji: '🇯🇲' },
    Japan: { name: 'Japan', dial_code: '+81', code: 'JP', emoji: '🗾' },
    Jersey: { name: 'Jersey', dial_code: '+44', code: 'JE', emoji: '🇯🇪' },
    Jordan: { name: 'Jordan', dial_code: '+962', code: 'JO', emoji: '🇯🇴' },
    Kazakhstan: {
      name: 'Kazakhstan',
      dial_code: '+77',
      code: 'KZ',
      emoji: '🇰🇿',
    },
    Kenya: { name: 'Kenya', dial_code: '+254', code: 'KE', emoji: '🇰🇪' },
    Kiribati: { name: 'Kiribati', dial_code: '+686', code: 'KI', emoji: '🇰🇮' },
    "Korea, Democratic People's Republic of Korea": {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      code: 'KP',
      emoji: '🇰🇵',
    },
    'Korea, Republic of South Korea': {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      code: 'KR',
      emoji: '🇰🇷',
    },
    Kuwait: { name: 'Kuwait', dial_code: '+965', code: 'KW', emoji: '🇰🇼' },
    Kyrgyzstan: {
      name: 'Kyrgyzstan',
      dial_code: '+996',
      code: 'KG',
      emoji: '🇰🇬',
    },
    Laos: { name: 'Laos', dial_code: '+856', code: 'LA', emoji: '🇱🇦' },
    Latvia: { name: 'Latvia', dial_code: '+371', code: 'LV', emoji: '🇱🇻' },
    Lebanon: { name: 'Lebanon', dial_code: '+961', code: 'LB', emoji: '🇱🇧' },
    Lesotho: { name: 'Lesotho', dial_code: '+266', code: 'LS', emoji: '🇱🇸' },
    Liberia: { name: 'Liberia', dial_code: '+231', code: 'LR', emoji: '🇱🇷' },
    'Libyan Arab Jamahiriya': {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      code: 'LY',
      emoji: '🇱🇾',
    },
    Liechtenstein: {
      name: 'Liechtenstein',
      dial_code: '+423',
      code: 'LI',
      emoji: '🇱🇮',
    },
    Lithuania: {
      name: 'Lithuania',
      dial_code: '+370',
      code: 'LT',
      emoji: '🇱🇹',
    },
    Luxembourg: {
      name: 'Luxembourg',
      dial_code: '+352',
      code: 'LU',
      emoji: '🇱🇺',
    },
    Macao: { name: 'Macao', dial_code: '+853', code: 'MO', emoji: '🇲🇴' },
    Macedonia: {
      name: 'Macedonia',
      dial_code: '+389',
      code: 'MK',
      emoji: '🇲🇰',
    },
    Madagascar: {
      name: 'Madagascar',
      dial_code: '+261',
      code: 'MG',
      emoji: '🇲🇬',
    },
    Malawi: { name: 'Malawi', dial_code: '+265', code: 'MW', emoji: '🇲🇼' },
    Malaysia: { name: 'Malaysia', dial_code: '+60', code: 'MY', emoji: '🇲🇾' },
    Maldives: { name: 'Maldives', dial_code: '+960', code: 'MV', emoji: '🇲🇻' },
    Mali: { name: 'Mali', dial_code: '+223', code: 'ML', emoji: '🇲🇱' },
    Malta: { name: 'Malta', dial_code: '+356', code: 'MT', emoji: '🇲🇹' },
    'Marshall Islands': {
      name: 'Marshall Islands',
      dial_code: '+692',
      code: 'MH',
      emoji: '🇲🇭',
    },
    Martinique: {
      name: 'Martinique',
      dial_code: '+596',
      code: 'MQ',
      emoji: '🇲🇶',
    },
    Mauritania: {
      name: 'Mauritania',
      dial_code: '+222',
      code: 'MR',
      emoji: '🇲🇷',
    },
    Mauritius: {
      name: 'Mauritius',
      dial_code: '+230',
      code: 'MU',
      emoji: '🇲🇺',
    },
    Mayotte: { name: 'Mayotte', dial_code: '+262', code: 'YT', emoji: '🇾🇹' },
    Mexico: { name: 'Mexico', dial_code: '+52', code: 'MX', emoji: '🇲🇽' },
    'Micronesia, Federated States of Micronesia': {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM',
      emoji: '🇫🇲',
    },
    Moldova: { name: 'Moldova', dial_code: '+373', code: 'MD', emoji: '🇲🇩' },
    Monaco: { name: 'Monaco', dial_code: '+377', code: 'MC', emoji: '🇲🇨' },
    Mongolia: { name: 'Mongolia', dial_code: '+976', code: 'MN', emoji: '🇲🇳' },
    Montenegro: {
      name: 'Montenegro',
      dial_code: '+382',
      code: 'ME',
      emoji: '🇲🇪',
    },
    Montserrat: {
      name: 'Montserrat',
      dial_code: '+1664',
      code: 'MS',
      emoji: '🇲🇸',
    },
    Morocco: { name: 'Morocco', dial_code: '+212', code: 'MA', emoji: '🇲🇦' },
    Mozambique: {
      name: 'Mozambique',
      dial_code: '+258',
      code: 'MZ',
      emoji: '🇲🇿',
    },
    Myanmar: { name: 'Myanmar', dial_code: '+95', code: 'MM', emoji: '🇲🇲' },
    Namibia: { name: 'Namibia', dial_code: '+264', code: 'NA', emoji: '🇳🇦' },
    Nauru: { name: 'Nauru', dial_code: '+674', code: 'NR', emoji: '🇳🇷' },
    Nepal: { name: 'Nepal', dial_code: '+977', code: 'NP', emoji: '🇳🇵' },
    Netherlands: {
      name: 'Netherlands',
      dial_code: '+31',
      code: 'NL',
      emoji: '🇳🇱',
    },
    'Netherlands Antilles': {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      code: 'AN',
      emoji: '🇳🇱',
    },
    'New Caledonia': {
      name: 'New Caledonia',
      dial_code: '+687',
      code: 'NC',
      emoji: '🇳🇨',
    },
    'New Zealand': {
      name: 'New Zealand',
      dial_code: '+64',
      code: 'NZ',
      emoji: '🇳🇿',
    },
    Nicaragua: {
      name: 'Nicaragua',
      dial_code: '+505',
      code: 'NI',
      emoji: '🇳🇮',
    },
    Niger: { name: 'Niger', dial_code: '+227', code: 'NE', emoji: '🇳🇪' },
    Nigeria: { name: 'Nigeria', dial_code: '+234', code: 'NG', emoji: '🇳🇬' },
    Niue: { name: 'Niue', dial_code: '+683', code: 'NU', emoji: '🇳🇺' },
    'Norfolk Island': {
      name: 'Norfolk Island',
      dial_code: '+672',
      code: 'NF',
      emoji: '🇳🇫',
    },
    'Northern Mariana Islands': {
      name: 'Northern Mariana Islands',
      dial_code: '+1670',
      code: 'MP',
      emoji: '🇲🇵',
    },
    Norway: { name: 'Norway', dial_code: '+47', code: 'NO', emoji: '🇳🇴' },
    Oman: { name: 'Oman', dial_code: '+968', code: 'OM', emoji: '🇴🇲' },
    Pakistan: { name: 'Pakistan', dial_code: '+92', code: 'PK', emoji: '🇵🇰' },
    Palau: { name: 'Palau', dial_code: '+680', code: 'PW', emoji: '🇵🇼' },
    'Palestinian Territory, Occupied': {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      code: 'PS',
      emoji: '🇵🇸',
    },
    Panama: { name: 'Panama', dial_code: '+507', code: 'PA', emoji: '🇵🇦' },
    'Papua New Guinea': {
      name: 'Papua New Guinea',
      dial_code: '+675',
      code: 'PG',
      emoji: '🇵🇬',
    },
    Paraguay: { name: 'Paraguay', dial_code: '+595', code: 'PY', emoji: '🇵🇾' },
    Peru: { name: 'Peru', dial_code: '+51', code: 'PE', emoji: '🇵🇪' },
    Philippines: {
      name: 'Philippines',
      dial_code: '+63',
      code: 'PH',
      emoji: '🇵🇭',
    },
    Pitcairn: { name: 'Pitcairn', dial_code: '+872', code: 'PN', emoji: '🇵🇳' },
    Poland: { name: 'Poland', dial_code: '+48', code: 'PL', emoji: '🇵🇱' },
    Portugal: { name: 'Portugal', dial_code: '+351', code: 'PT', emoji: '🇵🇹' },
    'Puerto Rico': {
      name: 'Puerto Rico',
      dial_code: '+1939',
      code: 'PR',
      emoji: '🇵🇷',
    },
    Qatar: { name: 'Qatar', dial_code: '+974', code: 'QA', emoji: '🇶🇦' },
    Romania: { name: 'Romania', dial_code: '+40', code: 'RO', emoji: '🇷🇴' },
    Russia: { name: 'Russia', dial_code: '+7', code: 'RU', emoji: '🇷🇺' },
    Rwanda: { name: 'Rwanda', dial_code: '+250', code: 'RW', emoji: '🇷🇼' },
    Reunion: { name: 'Reunion', dial_code: '+262', code: 'RE', emoji: '🇷🇪' },
    'Saint Barthelemy': {
      name: 'Saint Barthelemy',
      dial_code: '+590',
      code: 'BL',
      emoji: '🇧🇱',
    },
    'Saint Helena, Ascension and Tristan Da Cunha': {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH',
      emoji: '🇸🇭',
    },
    'Saint Kitts and Nevis': {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1869',
      code: 'KN',
      emoji: '🇰🇳',
    },
    'Saint Lucia': {
      name: 'Saint Lucia',
      dial_code: '+1758',
      code: 'LC',
      emoji: '🇱🇨',
    },
    'Saint Martin': {
      name: 'Saint Martin',
      dial_code: '+590',
      code: 'MF',
      emoji: '🇫🇷',
    },
    'Saint Pierre and Miquelon': {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      code: 'PM',
      emoji: '🇵🇲',
    },
    'Saint Vincent and the Grenadines': {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      code: 'VC',
      emoji: '🇻🇨',
    },
    Samoa: { name: 'Samoa', dial_code: '+685', code: 'WS', emoji: '🇼🇸' },
    'San Marino': {
      name: 'San Marino',
      dial_code: '+378',
      code: 'SM',
      emoji: '🇸🇲',
    },
    'Sao Tome and Principe': {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      code: 'ST',
      emoji: '🇸🇹',
    },
    'Saudi Arabia': {
      name: 'Saudi Arabia',
      dial_code: '+966',
      code: 'SA',
      emoji: '🇸🇦',
    },
    Senegal: { name: 'Senegal', dial_code: '+221', code: 'SN', emoji: '🇸🇳' },
    Serbia: { name: 'Serbia', dial_code: '+381', code: 'RS', emoji: '🇷🇸' },
    Seychelles: {
      name: 'Seychelles',
      dial_code: '+248',
      code: 'SC',
      emoji: '🇸🇨',
    },
    'Sierra Leone': {
      name: 'Sierra Leone',
      dial_code: '+232',
      code: 'SL',
      emoji: '🇸🇱',
    },
    Singapore: { name: 'Singapore', dial_code: '+65', code: 'SG', emoji: '🇸🇬' },
    Slovakia: { name: 'Slovakia', dial_code: '+421', code: 'SK', emoji: '🇸🇰' },
    Slovenia: { name: 'Slovenia', dial_code: '+386', code: 'SI', emoji: '🇸🇮' },
    'Solomon Islands': {
      name: 'Solomon Islands',
      dial_code: '+677',
      code: 'SB',
      emoji: '🇸🇧',
    },
    Somalia: { name: 'Somalia', dial_code: '+252', code: 'SO', emoji: '🇸🇴' },
    'South Africa': {
      name: 'South Africa',
      dial_code: '+27',
      code: 'ZA',
      emoji: '🇿🇦',
    },
    'South Sudan': {
      name: 'South Sudan',
      dial_code: '+211',
      code: 'SS',
      emoji: '🇸🇸',
    },
    'South Georgia and the South Sandwich Islands': {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS',
      emoji: '🇬🇸',
    },
    Spain: { name: 'Spain', dial_code: '+34', code: 'ES', emoji: '🇪🇸' },
    'Sri Lanka': {
      name: 'Sri Lanka',
      dial_code: '+94',
      code: 'LK',
      emoji: '🇱🇰',
    },
    Sudan: { name: 'Sudan', dial_code: '+249', code: 'SD', emoji: '🇸🇩' },
    Suriname: { name: 'Suriname', dial_code: '+597', code: 'SR', emoji: '🇸🇷' },
    'Svalbard and Jan Mayen': {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      code: 'SJ',
      emoji: '🇳🇴',
    },
    Swaziland: {
      name: 'Swaziland',
      dial_code: '+268',
      code: 'SZ',
      emoji: '🇸🇿',
    },
    Sweden: { name: 'Sweden', dial_code: '+46', code: 'SE', emoji: '🇸🇪' },
    Switzerland: {
      name: 'Switzerland',
      dial_code: '+41',
      code: 'CH',
      emoji: '🇨🇭',
    },
    'Syrian Arab Republic': {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      code: 'SY',
      emoji: '🇸🇾',
    },
    Taiwan: { name: 'Taiwan', dial_code: '+886', code: 'TW', emoji: '🇹🇼' },
    Tajikistan: {
      name: 'Tajikistan',
      dial_code: '+992',
      code: 'TJ',
      emoji: '🇹🇯',
    },
    'Tanzania, United Republic of Tanzania': {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ',
      emoji: '🇹🇿',
    },
    Thailand: { name: 'Thailand', dial_code: '+66', code: 'TH', emoji: '🇹🇭' },
    'Timor-Leste': {
      name: 'Timor-Leste',
      dial_code: '+670',
      code: 'TL',
      emoji: '🇹🇱',
    },
    Togo: { name: 'Togo', dial_code: '+228', code: 'TG', emoji: '🇹🇬' },
    Tokelau: { name: 'Tokelau', dial_code: '+690', code: 'TK', emoji: '🇹🇰' },
    Tonga: { name: 'Tonga', dial_code: '+676', code: 'TO', emoji: '🇹🇴' },
    'Trinidad and Tobago': {
      name: 'Trinidad and Tobago',
      dial_code: '+1868',
      code: 'TT',
      emoji: '🇹🇹',
    },
    Tunisia: { name: 'Tunisia', dial_code: '+216', code: 'TN', emoji: '🇹🇳' },
    Turkey: { name: 'Turkey', dial_code: '+90', code: 'TR', emoji: '🇹🇷' },
    Turkmenistan: {
      name: 'Turkmenistan',
      dial_code: '+993',
      code: 'TM',
      emoji: '🇹🇲',
    },
    'Turks and Caicos Islands': {
      name: 'Turks and Caicos Islands',
      dial_code: '+1649',
      code: 'TC',
      emoji: '🇹🇨',
    },
    Tuvalu: { name: 'Tuvalu', dial_code: '+688', code: 'TV', emoji: '🇹🇻' },
    Uganda: { name: 'Uganda', dial_code: '+256', code: 'UG', emoji: '🇺🇬' },
    Ukraine: { name: 'Ukraine', dial_code: '+380', code: 'UA', emoji: '🇺🇦' },
    'United Arab Emirates': {
      name: 'United Arab Emirates',
      dial_code: '+971',
      code: 'AE',
      emoji: '🇦🇪',
    },
    'United Kingdom': {
      name: 'United Kingdom',
      dial_code: '+44',
      code: 'UK',
      emoji: '🇬🇧',
    },
    'United States': {
      name: 'United States',
      dial_code: '+1',
      code: 'US',
      emoji: '🇺🇸',
    },
    Uruguay: { name: 'Uruguay', dial_code: '+598', code: 'UY', emoji: '🇺🇾' },
    Uzbekistan: {
      name: 'Uzbekistan',
      dial_code: '+998',
      code: 'UZ',
      emoji: '🇺🇿',
    },
    Vanuatu: { name: 'Vanuatu', dial_code: '+678', code: 'VU', emoji: '🇻🇺' },
    'Venezuela, Bolivarian Republic of Venezuela': {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE',
      emoji: '🇻🇪',
    },
    Vietnam: { name: 'Vietnam', dial_code: '+84', code: 'VN', emoji: '🇻🇳' },
    'Virgin Islands, British': {
      name: 'Virgin Islands, British',
      dial_code: '+1284',
      code: 'VG',
      emoji: '🇻🇬',
    },
    'Virgin Islands, U.S.': {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1340',
      code: 'VI',
      emoji: '🇻🇮',
    },
    'Wallis and Futuna': {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      code: 'WF',
      emoji: '🇼🇫',
    },
    Yemen: { name: 'Yemen', dial_code: '+967', code: 'YE', emoji: '🇾🇪' },
    Zambia: { name: 'Zambia', dial_code: '+260', code: 'ZM', emoji: '🇿🇲' },
    Zimbabwe: { name: 'Zimbabwe', dial_code: '+263', code: 'ZW', emoji: '🇿🇼' },
  },
  byDialCode: {
    '+93': { name: 'Afghanistan', dial_code: '+93', code: 'AF', emoji: '🇦🇫' },
    '+358': { name: 'Finland', dial_code: '+358', code: 'FI', emoji: '🇫🇮' },
    '+355': { name: 'Albania', dial_code: '+355', code: 'AL', emoji: '🇦🇱' },
    '+213': { name: 'Algeria', dial_code: '+213', code: 'DZ', emoji: '🇩🇿' },
    '+1684': {
      name: 'American Samoa',
      dial_code: '+1684',
      code: 'AS',
      emoji: '🇦🇸',
    },
    '+376': { name: 'Andorra', dial_code: '+376', code: 'AD', emoji: '🇦🇩' },
    '+244': { name: 'Angola', dial_code: '+244', code: 'AO', emoji: '🇦🇴' },
    '+1264': { name: 'Anguilla', dial_code: '+1264', code: 'AI', emoji: '🇦🇮' },
    '+672': {
      name: 'Norfolk Island',
      dial_code: '+672',
      code: 'NF',
      emoji: '🇳🇫',
    },
    '+1268': {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      code: 'AG',
      emoji: '🇦🇬',
    },
    '+54': { name: 'Argentina', dial_code: '+54', code: 'AR', emoji: '🇦🇷' },
    '+374': { name: 'Armenia', dial_code: '+374', code: 'AM', emoji: '🇦🇲' },
    '+297': { name: 'Aruba', dial_code: '+297', code: 'AW', emoji: '🇦🇼' },
    '+61': {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      code: 'CC',
      emoji: '🇨🇨',
    },
    '+43': { name: 'Austria', dial_code: '+43', code: 'AT', emoji: '🇦🇹' },
    '+994': { name: 'Azerbaijan', dial_code: '+994', code: 'AZ', emoji: '🇦🇿' },
    '+1242': { name: 'Bahamas', dial_code: '+1242', code: 'BS', emoji: '🇧🇸' },
    '+973': { name: 'Bahrain', dial_code: '+973', code: 'BH', emoji: '🇧🇭' },
    '+880': { name: 'Bangladesh', dial_code: '+880', code: 'BD', emoji: '🇧🇩' },
    '+1246': { name: 'Barbados', dial_code: '+1246', code: 'BB', emoji: '🇧🇧' },
    '+375': { name: 'Belarus', dial_code: '+375', code: 'BY', emoji: '🇧🇾' },
    '+32': { name: 'Belgium', dial_code: '+32', code: 'BE', emoji: '🇧🇪' },
    '+501': { name: 'Belize', dial_code: '+501', code: 'BZ', emoji: '🇧🇿' },
    '+229': { name: 'Benin', dial_code: '+229', code: 'BJ', emoji: '🇧🇯' },
    '+1441': { name: 'Bermuda', dial_code: '+1441', code: 'BM', emoji: '🇧🇲' },
    '+975': { name: 'Bhutan', dial_code: '+975', code: 'BT', emoji: '🇧🇹' },
    '+591': {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
      emoji: '🇧🇴',
    },
    '+387': {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      code: 'BA',
      emoji: '🇧🇦',
    },
    '+267': { name: 'Botswana', dial_code: '+267', code: 'BW', emoji: '🇧🇼' },
    '+55': { name: 'Brazil', dial_code: '+55', code: 'BR', emoji: '🇧🇷' },
    '+246': {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      code: 'IO',
      emoji: '🇮🇴',
    },
    '+673': {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      code: 'BN',
      emoji: '🇧🇳',
    },
    '+359': { name: 'Bulgaria', dial_code: '+359', code: 'BG', emoji: '🇧🇬' },
    '+226': {
      name: 'Burkina Faso',
      dial_code: '+226',
      code: 'BF',
      emoji: '🇧🇫',
    },
    '+257': { name: 'Burundi', dial_code: '+257', code: 'BI', emoji: '🇧🇮' },
    '+855': { name: 'Cambodia', dial_code: '+855', code: 'KH', emoji: '🇰🇭' },
    '+237': { name: 'Cameroon', dial_code: '+237', code: 'CM', emoji: '🇨🇲' },
    '+1': { name: 'United States', dial_code: '+1', code: 'US', emoji: '🇺🇸' },
    '+238': { name: 'Cape Verde', dial_code: '+238', code: 'CV', emoji: '🇨🇻' },
    '+ 345': {
      name: 'Cayman Islands',
      dial_code: '+ 345',
      code: 'KY',
      emoji: '🇰🇾',
    },
    '+236': {
      name: 'Central African Republic',
      dial_code: '+236',
      code: 'CF',
      emoji: '🇨🇫',
    },
    '+235': { name: 'Chad', dial_code: '+235', code: 'TD', emoji: '🇹🇩' },
    '+56': { name: 'Chile', dial_code: '+56', code: 'CL', emoji: '🇨🇱' },
    '+86': { name: 'China', dial_code: '+86', code: 'CN', emoji: '🇨🇳' },
    '+57': { name: 'Colombia', dial_code: '+57', code: 'CO', emoji: '🇨🇴' },
    '+269': { name: 'Comoros', dial_code: '+269', code: 'KM', emoji: '🇰🇲' },
    '+242': { name: 'Congo', dial_code: '+242', code: 'CG', emoji: '🇨🇬' },
    '+243': {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD',
      emoji: '🇨🇩',
    },
    '+682': {
      name: 'Cook Islands',
      dial_code: '+682',
      code: 'CK',
      emoji: '🇨🇰',
    },
    '+506': { name: 'Costa Rica', dial_code: '+506', code: 'CR', emoji: '🇨🇷' },
    '+225': {
      name: "Cote d'Ivoire",
      dial_code: '+225',
      code: 'CI',
      emoji: '🇨🇮',
    },
    '+385': { name: 'Croatia', dial_code: '+385', code: 'HR', emoji: '🇭🇷' },
    '+53': { name: 'Cuba', dial_code: '+53', code: 'CU', emoji: '🇨🇺' },
    '+357': { name: 'Cyprus', dial_code: '+357', code: 'CY', emoji: '🇨🇾' },
    '+420': {
      name: 'Czech Republic',
      dial_code: '+420',
      code: 'CZ',
      emoji: '🇨🇿',
    },
    '+45': { name: 'Denmark', dial_code: '+45', code: 'DK', emoji: '🇩🇰' },
    '+253': { name: 'Djibouti', dial_code: '+253', code: 'DJ', emoji: '🇩🇯' },
    '+1767': { name: 'Dominica', dial_code: '+1767', code: 'DM', emoji: '🇩🇲' },
    '+1849': {
      name: 'Dominican Republic',
      dial_code: '+1849',
      code: 'DO',
      emoji: '🇩🇴',
    },
    '+593': { name: 'Ecuador', dial_code: '+593', code: 'EC', emoji: '🇪🇨' },
    '+20': { name: 'Egypt', dial_code: '+20', code: 'EG', emoji: '🇪🇬' },
    '+503': { name: 'El Salvador', dial_code: '+503', code: 'SV', emoji: '🇸🇻' },
    '+240': {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      code: 'GQ',
      emoji: '🇬🇶',
    },
    '+291': { name: 'Eritrea', dial_code: '+291', code: 'ER', emoji: '🇪🇷' },
    '+372': { name: 'Estonia', dial_code: '+372', code: 'EE', emoji: '🇪🇪' },
    '+251': { name: 'Ethiopia', dial_code: '+251', code: 'ET', emoji: '🇪🇹' },
    '+500': {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS',
      emoji: '🇬🇸',
    },
    '+298': {
      name: 'Faroe Islands',
      dial_code: '+298',
      code: 'FO',
      emoji: '🇫🇴',
    },
    '+679': { name: 'Fiji', dial_code: '+679', code: 'FJ', emoji: '🇫🇯' },
    '+33': { name: 'France', dial_code: '+33', code: 'FR', emoji: '🇫🇷' },
    '+594': {
      name: 'French Guiana',
      dial_code: '+594',
      code: 'GF',
      emoji: '🇬🇫',
    },
    '+689': {
      name: 'French Polynesia',
      dial_code: '+689',
      code: 'PF',
      emoji: '🇵🇫',
    },
    '+241': { name: 'Gabon', dial_code: '+241', code: 'GA', emoji: '🇬🇦' },
    '+220': { name: 'Gambia', dial_code: '+220', code: 'GM', emoji: '🇬🇲' },
    '+995': { name: 'Georgia', dial_code: '+995', code: 'GE', emoji: '🇬🇪' },
    '+49': { name: 'Germany', dial_code: '+49', code: 'DE', emoji: '🇩🇪' },
    '+233': { name: 'Ghana', dial_code: '+233', code: 'GH', emoji: '🇬🇭' },
    '+350': { name: 'Gibraltar', dial_code: '+350', code: 'GI', emoji: '🇬🇮' },
    '+30': { name: 'Greece', dial_code: '+30', code: 'GR', emoji: '🇬🇷' },
    '+299': { name: 'Greenland', dial_code: '+299', code: 'GL', emoji: '🇬🇱' },
    '+1473': { name: 'Grenada', dial_code: '+1473', code: 'GD', emoji: '🇬🇩' },
    '+590': {
      name: 'Saint Martin',
      dial_code: '+590',
      code: 'MF',
      emoji: '🇫🇷',
    },
    '+1671': { name: 'Guam', dial_code: '+1671', code: 'GU', emoji: '🇬🇺' },
    '+502': { name: 'Guatemala', dial_code: '+502', code: 'GT', emoji: '🇬🇹' },
    '+44': {
      name: 'United Kingdom',
      dial_code: '+44',
      code: 'UK',
      emoji: '🇬🇧',
    },
    '+224': { name: 'Guinea', dial_code: '+224', code: 'GN', emoji: '🇬🇳' },
    '+245': {
      name: 'Guinea-Bissau',
      dial_code: '+245',
      code: 'GW',
      emoji: '🇬🇼',
    },
    '+595': { name: 'Paraguay', dial_code: '+595', code: 'PY', emoji: '🇵🇾' },
    '+509': { name: 'Haiti', dial_code: '+509', code: 'HT', emoji: '🇭🇹' },
    '+379': {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      code: 'VA',
      emoji: '🇻🇦',
    },
    '+504': { name: 'Honduras', dial_code: '+504', code: 'HN', emoji: '🇭🇳' },
    '+852': { name: 'Hong Kong', dial_code: '+852', code: 'HK', emoji: '🇭🇰' },
    '+36': { name: 'Hungary', dial_code: '+36', code: 'HU', emoji: '🇭🇺' },
    '+354': { name: 'Iceland', dial_code: '+354', code: 'IS', emoji: '🇮🇸' },
    '+91': { name: 'India', dial_code: '+91', code: 'IN', emoji: '🇮🇳' },
    '+62': { name: 'Indonesia', dial_code: '+62', code: 'ID', emoji: '🇮🇩' },
    '+98': {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR',
      emoji: '🇮🇷',
    },
    '+964': { name: 'Iraq', dial_code: '+964', code: 'IQ', emoji: '🇮🇶' },
    '+353': { name: 'Ireland', dial_code: '+353', code: 'IE', emoji: '🇮🇪' },
    '+972': { name: 'Israel', dial_code: '+972', code: 'IL', emoji: '🇮🇱' },
    '+39': { name: 'Italy', dial_code: '+39', code: 'IT', emoji: '🇮🇹' },
    '+1876': { name: 'Jamaica', dial_code: '+1876', code: 'JM', emoji: '🇯🇲' },
    '+81': { name: 'Japan', dial_code: '+81', code: 'JP', emoji: '🗾' },
    '+962': { name: 'Jordan', dial_code: '+962', code: 'JO', emoji: '🇯🇴' },
    '+77': { name: 'Kazakhstan', dial_code: '+77', code: 'KZ', emoji: '🇰🇿' },
    '+254': { name: 'Kenya', dial_code: '+254', code: 'KE', emoji: '🇰🇪' },
    '+686': { name: 'Kiribati', dial_code: '+686', code: 'KI', emoji: '🇰🇮' },
    '+850': {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      code: 'KP',
      emoji: '🇰🇵',
    },
    '+82': {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      code: 'KR',
      emoji: '🇰🇷',
    },
    '+965': { name: 'Kuwait', dial_code: '+965', code: 'KW', emoji: '🇰🇼' },
    '+996': { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG', emoji: '🇰🇬' },
    '+856': { name: 'Laos', dial_code: '+856', code: 'LA', emoji: '🇱🇦' },
    '+371': { name: 'Latvia', dial_code: '+371', code: 'LV', emoji: '🇱🇻' },
    '+961': { name: 'Lebanon', dial_code: '+961', code: 'LB', emoji: '🇱🇧' },
    '+266': { name: 'Lesotho', dial_code: '+266', code: 'LS', emoji: '🇱🇸' },
    '+231': { name: 'Liberia', dial_code: '+231', code: 'LR', emoji: '🇱🇷' },
    '+218': {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      code: 'LY',
      emoji: '🇱🇾',
    },
    '+423': {
      name: 'Liechtenstein',
      dial_code: '+423',
      code: 'LI',
      emoji: '🇱🇮',
    },
    '+370': { name: 'Lithuania', dial_code: '+370', code: 'LT', emoji: '🇱🇹' },
    '+352': { name: 'Luxembourg', dial_code: '+352', code: 'LU', emoji: '🇱🇺' },
    '+853': { name: 'Macao', dial_code: '+853', code: 'MO', emoji: '🇲🇴' },
    '+389': { name: 'Macedonia', dial_code: '+389', code: 'MK', emoji: '🇲🇰' },
    '+261': { name: 'Madagascar', dial_code: '+261', code: 'MG', emoji: '🇲🇬' },
    '+265': { name: 'Malawi', dial_code: '+265', code: 'MW', emoji: '🇲🇼' },
    '+60': { name: 'Malaysia', dial_code: '+60', code: 'MY', emoji: '🇲🇾' },
    '+960': { name: 'Maldives', dial_code: '+960', code: 'MV', emoji: '🇲🇻' },
    '+223': { name: 'Mali', dial_code: '+223', code: 'ML', emoji: '🇲🇱' },
    '+356': { name: 'Malta', dial_code: '+356', code: 'MT', emoji: '🇲🇹' },
    '+692': {
      name: 'Marshall Islands',
      dial_code: '+692',
      code: 'MH',
      emoji: '🇲🇭',
    },
    '+596': { name: 'Martinique', dial_code: '+596', code: 'MQ', emoji: '🇲🇶' },
    '+222': { name: 'Mauritania', dial_code: '+222', code: 'MR', emoji: '🇲🇷' },
    '+230': { name: 'Mauritius', dial_code: '+230', code: 'MU', emoji: '🇲🇺' },
    '+262': { name: 'Reunion', dial_code: '+262', code: 'RE', emoji: '🇷🇪' },
    '+52': { name: 'Mexico', dial_code: '+52', code: 'MX', emoji: '🇲🇽' },
    '+691': {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM',
      emoji: '🇫🇲',
    },
    '+373': { name: 'Moldova', dial_code: '+373', code: 'MD', emoji: '🇲🇩' },
    '+377': { name: 'Monaco', dial_code: '+377', code: 'MC', emoji: '🇲🇨' },
    '+976': { name: 'Mongolia', dial_code: '+976', code: 'MN', emoji: '🇲🇳' },
    '+382': { name: 'Montenegro', dial_code: '+382', code: 'ME', emoji: '🇲🇪' },
    '+1664': {
      name: 'Montserrat',
      dial_code: '+1664',
      code: 'MS',
      emoji: '🇲🇸',
    },
    '+212': { name: 'Morocco', dial_code: '+212', code: 'MA', emoji: '🇲🇦' },
    '+258': { name: 'Mozambique', dial_code: '+258', code: 'MZ', emoji: '🇲🇿' },
    '+95': { name: 'Myanmar', dial_code: '+95', code: 'MM', emoji: '🇲🇲' },
    '+264': { name: 'Namibia', dial_code: '+264', code: 'NA', emoji: '🇳🇦' },
    '+674': { name: 'Nauru', dial_code: '+674', code: 'NR', emoji: '🇳🇷' },
    '+977': { name: 'Nepal', dial_code: '+977', code: 'NP', emoji: '🇳🇵' },
    '+31': { name: 'Netherlands', dial_code: '+31', code: 'NL', emoji: '🇳🇱' },
    '+599': {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      code: 'AN',
      emoji: '🇳🇱',
    },
    '+687': {
      name: 'New Caledonia',
      dial_code: '+687',
      code: 'NC',
      emoji: '🇳🇨',
    },
    '+64': { name: 'New Zealand', dial_code: '+64', code: 'NZ', emoji: '🇳🇿' },
    '+505': { name: 'Nicaragua', dial_code: '+505', code: 'NI', emoji: '🇳🇮' },
    '+227': { name: 'Niger', dial_code: '+227', code: 'NE', emoji: '🇳🇪' },
    '+234': { name: 'Nigeria', dial_code: '+234', code: 'NG', emoji: '🇳🇬' },
    '+683': { name: 'Niue', dial_code: '+683', code: 'NU', emoji: '🇳🇺' },
    '+1670': {
      name: 'Northern Mariana Islands',
      dial_code: '+1670',
      code: 'MP',
      emoji: '🇲🇵',
    },
    '+47': {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      code: 'SJ',
      emoji: '🇳🇴',
    },
    '+968': { name: 'Oman', dial_code: '+968', code: 'OM', emoji: '🇴🇲' },
    '+92': { name: 'Pakistan', dial_code: '+92', code: 'PK', emoji: '🇵🇰' },
    '+680': { name: 'Palau', dial_code: '+680', code: 'PW', emoji: '🇵🇼' },
    '+970': {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      code: 'PS',
      emoji: '🇵🇸',
    },
    '+507': { name: 'Panama', dial_code: '+507', code: 'PA', emoji: '🇵🇦' },
    '+675': {
      name: 'Papua New Guinea',
      dial_code: '+675',
      code: 'PG',
      emoji: '🇵🇬',
    },
    '+51': { name: 'Peru', dial_code: '+51', code: 'PE', emoji: '🇵🇪' },
    '+63': { name: 'Philippines', dial_code: '+63', code: 'PH', emoji: '🇵🇭' },
    '+872': { name: 'Pitcairn', dial_code: '+872', code: 'PN', emoji: '🇵🇳' },
    '+48': { name: 'Poland', dial_code: '+48', code: 'PL', emoji: '🇵🇱' },
    '+351': { name: 'Portugal', dial_code: '+351', code: 'PT', emoji: '🇵🇹' },
    '+1939': {
      name: 'Puerto Rico',
      dial_code: '+1939',
      code: 'PR',
      emoji: '🇵🇷',
    },
    '+974': { name: 'Qatar', dial_code: '+974', code: 'QA', emoji: '🇶🇦' },
    '+40': { name: 'Romania', dial_code: '+40', code: 'RO', emoji: '🇷🇴' },
    '+7': { name: 'Russia', dial_code: '+7', code: 'RU', emoji: '🇷🇺' },
    '+250': { name: 'Rwanda', dial_code: '+250', code: 'RW', emoji: '🇷🇼' },
    '+290': {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH',
      emoji: '🇸🇭',
    },
    '+1869': {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1869',
      code: 'KN',
      emoji: '🇰🇳',
    },
    '+1758': {
      name: 'Saint Lucia',
      dial_code: '+1758',
      code: 'LC',
      emoji: '🇱🇨',
    },
    '+508': {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      code: 'PM',
      emoji: '🇵🇲',
    },
    '+1784': {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      code: 'VC',
      emoji: '🇻🇨',
    },
    '+685': { name: 'Samoa', dial_code: '+685', code: 'WS', emoji: '🇼🇸' },
    '+378': { name: 'San Marino', dial_code: '+378', code: 'SM', emoji: '🇸🇲' },
    '+239': {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      code: 'ST',
      emoji: '🇸🇹',
    },
    '+966': {
      name: 'Saudi Arabia',
      dial_code: '+966',
      code: 'SA',
      emoji: '🇸🇦',
    },
    '+221': { name: 'Senegal', dial_code: '+221', code: 'SN', emoji: '🇸🇳' },
    '+381': { name: 'Serbia', dial_code: '+381', code: 'RS', emoji: '🇷🇸' },
    '+248': { name: 'Seychelles', dial_code: '+248', code: 'SC', emoji: '🇸🇨' },
    '+232': {
      name: 'Sierra Leone',
      dial_code: '+232',
      code: 'SL',
      emoji: '🇸🇱',
    },
    '+65': { name: 'Singapore', dial_code: '+65', code: 'SG', emoji: '🇸🇬' },
    '+421': { name: 'Slovakia', dial_code: '+421', code: 'SK', emoji: '🇸🇰' },
    '+386': { name: 'Slovenia', dial_code: '+386', code: 'SI', emoji: '🇸🇮' },
    '+677': {
      name: 'Solomon Islands',
      dial_code: '+677',
      code: 'SB',
      emoji: '🇸🇧',
    },
    '+252': { name: 'Somalia', dial_code: '+252', code: 'SO', emoji: '🇸🇴' },
    '+27': { name: 'South Africa', dial_code: '+27', code: 'ZA', emoji: '🇿🇦' },
    '+211': { name: 'South Sudan', dial_code: '+211', code: 'SS', emoji: '🇸🇸' },
    '+34': { name: 'Spain', dial_code: '+34', code: 'ES', emoji: '🇪🇸' },
    '+94': { name: 'Sri Lanka', dial_code: '+94', code: 'LK', emoji: '🇱🇰' },
    '+249': { name: 'Sudan', dial_code: '+249', code: 'SD', emoji: '🇸🇩' },
    '+597': { name: 'Suriname', dial_code: '+597', code: 'SR', emoji: '🇸🇷' },
    '+268': { name: 'Swaziland', dial_code: '+268', code: 'SZ', emoji: '🇸🇿' },
    '+46': { name: 'Sweden', dial_code: '+46', code: 'SE', emoji: '🇸🇪' },
    '+41': { name: 'Switzerland', dial_code: '+41', code: 'CH', emoji: '🇨🇭' },
    '+963': {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      code: 'SY',
      emoji: '🇸🇾',
    },
    '+886': { name: 'Taiwan', dial_code: '+886', code: 'TW', emoji: '🇹🇼' },
    '+992': { name: 'Tajikistan', dial_code: '+992', code: 'TJ', emoji: '🇹🇯' },
    '+255': {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ',
      emoji: '🇹🇿',
    },
    '+66': { name: 'Thailand', dial_code: '+66', code: 'TH', emoji: '🇹🇭' },
    '+670': { name: 'Timor-Leste', dial_code: '+670', code: 'TL', emoji: '🇹🇱' },
    '+228': { name: 'Togo', dial_code: '+228', code: 'TG', emoji: '🇹🇬' },
    '+690': { name: 'Tokelau', dial_code: '+690', code: 'TK', emoji: '🇹🇰' },
    '+676': { name: 'Tonga', dial_code: '+676', code: 'TO', emoji: '🇹🇴' },
    '+1868': {
      name: 'Trinidad and Tobago',
      dial_code: '+1868',
      code: 'TT',
      emoji: '🇹🇹',
    },
    '+216': { name: 'Tunisia', dial_code: '+216', code: 'TN', emoji: '🇹🇳' },
    '+90': { name: 'Turkey', dial_code: '+90', code: 'TR', emoji: '🇹🇷' },
    '+993': {
      name: 'Turkmenistan',
      dial_code: '+993',
      code: 'TM',
      emoji: '🇹🇲',
    },
    '+1649': {
      name: 'Turks and Caicos Islands',
      dial_code: '+1649',
      code: 'TC',
      emoji: '🇹🇨',
    },
    '+688': { name: 'Tuvalu', dial_code: '+688', code: 'TV', emoji: '🇹🇻' },
    '+256': { name: 'Uganda', dial_code: '+256', code: 'UG', emoji: '🇺🇬' },
    '+380': { name: 'Ukraine', dial_code: '+380', code: 'UA', emoji: '🇺🇦' },
    '+971': {
      name: 'United Arab Emirates',
      dial_code: '+971',
      code: 'AE',
      emoji: '🇦🇪',
    },
    '+598': { name: 'Uruguay', dial_code: '+598', code: 'UY', emoji: '🇺🇾' },
    '+998': { name: 'Uzbekistan', dial_code: '+998', code: 'UZ', emoji: '🇺🇿' },
    '+678': { name: 'Vanuatu', dial_code: '+678', code: 'VU', emoji: '🇻🇺' },
    '+58': {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE',
      emoji: '🇻🇪',
    },
    '+84': { name: 'Vietnam', dial_code: '+84', code: 'VN', emoji: '🇻🇳' },
    '+1284': {
      name: 'Virgin Islands, British',
      dial_code: '+1284',
      code: 'VG',
      emoji: '🇻🇬',
    },
    '+1340': {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1340',
      code: 'VI',
      emoji: '🇻🇮',
    },
    '+681': {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      code: 'WF',
      emoji: '🇼🇫',
    },
    '+967': { name: 'Yemen', dial_code: '+967', code: 'YE', emoji: '🇾🇪' },
    '+260': { name: 'Zambia', dial_code: '+260', code: 'ZM', emoji: '🇿🇲' },
    '+263': { name: 'Zimbabwe', dial_code: '+263', code: 'ZW', emoji: '🇿🇼' },
  },
  byCountryCode: {
    AF: { name: 'Afghanistan', dial_code: '+93', code: 'AF', emoji: '🇦🇫' },
    AX: { name: 'Aland Islands', dial_code: '+358', code: 'AX', emoji: '🇦🇽' },
    AL: { name: 'Albania', dial_code: '+355', code: 'AL', emoji: '🇦🇱' },
    DZ: { name: 'Algeria', dial_code: '+213', code: 'DZ', emoji: '🇩🇿' },
    AS: { name: 'American Samoa', dial_code: '+1684', code: 'AS', emoji: '🇦🇸' },
    AD: { name: 'Andorra', dial_code: '+376', code: 'AD', emoji: '🇦🇩' },
    AO: { name: 'Angola', dial_code: '+244', code: 'AO', emoji: '🇦🇴' },
    AI: { name: 'Anguilla', dial_code: '+1264', code: 'AI', emoji: '🇦🇮' },
    AQ: { name: 'Antarctica', dial_code: '+672', code: 'AQ', emoji: '🇦🇶' },
    AG: {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      code: 'AG',
      emoji: '🇦🇬',
    },
    AR: { name: 'Argentina', dial_code: '+54', code: 'AR', emoji: '🇦🇷' },
    AM: { name: 'Armenia', dial_code: '+374', code: 'AM', emoji: '🇦🇲' },
    AW: { name: 'Aruba', dial_code: '+297', code: 'AW', emoji: '🇦🇼' },
    AU: { name: 'Australia', dial_code: '+61', code: 'AU', emoji: '🇦🇺' },
    AT: { name: 'Austria', dial_code: '+43', code: 'AT', emoji: '🇦🇹' },
    AZ: { name: 'Azerbaijan', dial_code: '+994', code: 'AZ', emoji: '🇦🇿' },
    BS: { name: 'Bahamas', dial_code: '+1242', code: 'BS', emoji: '🇧🇸' },
    BH: { name: 'Bahrain', dial_code: '+973', code: 'BH', emoji: '🇧🇭' },
    BD: { name: 'Bangladesh', dial_code: '+880', code: 'BD', emoji: '🇧🇩' },
    BB: { name: 'Barbados', dial_code: '+1246', code: 'BB', emoji: '🇧🇧' },
    BY: { name: 'Belarus', dial_code: '+375', code: 'BY', emoji: '🇧🇾' },
    BE: { name: 'Belgium', dial_code: '+32', code: 'BE', emoji: '🇧🇪' },
    BZ: { name: 'Belize', dial_code: '+501', code: 'BZ', emoji: '🇧🇿' },
    BJ: { name: 'Benin', dial_code: '+229', code: 'BJ', emoji: '🇧🇯' },
    BM: { name: 'Bermuda', dial_code: '+1441', code: 'BM', emoji: '🇧🇲' },
    BT: { name: 'Bhutan', dial_code: '+975', code: 'BT', emoji: '🇧🇹' },
    BO: {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
      emoji: '🇧🇴',
    },
    BA: {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      code: 'BA',
      emoji: '🇧🇦',
    },
    BW: { name: 'Botswana', dial_code: '+267', code: 'BW', emoji: '🇧🇼' },
    BR: { name: 'Brazil', dial_code: '+55', code: 'BR', emoji: '🇧🇷' },
    IO: {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      code: 'IO',
      emoji: '🇮🇴',
    },
    BN: {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      code: 'BN',
      emoji: '🇧🇳',
    },
    BG: { name: 'Bulgaria', dial_code: '+359', code: 'BG', emoji: '🇧🇬' },
    BF: { name: 'Burkina Faso', dial_code: '+226', code: 'BF', emoji: '🇧🇫' },
    BI: { name: 'Burundi', dial_code: '+257', code: 'BI', emoji: '🇧🇮' },
    KH: { name: 'Cambodia', dial_code: '+855', code: 'KH', emoji: '🇰🇭' },
    CM: { name: 'Cameroon', dial_code: '+237', code: 'CM', emoji: '🇨🇲' },
    CA: { name: 'Canada', dial_code: '+1', code: 'CA', emoji: '🇨🇦' },
    CV: { name: 'Cape Verde', dial_code: '+238', code: 'CV', emoji: '🇨🇻' },
    KY: { name: 'Cayman Islands', dial_code: '+ 345', code: 'KY', emoji: '🇰🇾' },
    CF: {
      name: 'Central African Republic',
      dial_code: '+236',
      code: 'CF',
      emoji: '🇨🇫',
    },
    TD: { name: 'Chad', dial_code: '+235', code: 'TD', emoji: '🇹🇩' },
    CL: { name: 'Chile', dial_code: '+56', code: 'CL', emoji: '🇨🇱' },
    CN: { name: 'China', dial_code: '+86', code: 'CN', emoji: '🇨🇳' },
    CX: { name: 'Christmas Island', dial_code: '+61', code: 'CX', emoji: '🇨🇽' },
    CC: {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      code: 'CC',
      emoji: '🇨🇨',
    },
    CO: { name: 'Colombia', dial_code: '+57', code: 'CO', emoji: '🇨🇴' },
    KM: { name: 'Comoros', dial_code: '+269', code: 'KM', emoji: '🇰🇲' },
    CG: { name: 'Congo', dial_code: '+242', code: 'CG', emoji: '🇨🇬' },
    CD: {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD',
      emoji: '🇨🇩',
    },
    CK: { name: 'Cook Islands', dial_code: '+682', code: 'CK', emoji: '🇨🇰' },
    CR: { name: 'Costa Rica', dial_code: '+506', code: 'CR', emoji: '🇨🇷' },
    CI: { name: "Cote d'Ivoire", dial_code: '+225', code: 'CI', emoji: '🇨🇮' },
    HR: { name: 'Croatia', dial_code: '+385', code: 'HR', emoji: '🇭🇷' },
    CU: { name: 'Cuba', dial_code: '+53', code: 'CU', emoji: '🇨🇺' },
    CY: { name: 'Cyprus', dial_code: '+357', code: 'CY', emoji: '🇨🇾' },
    CZ: { name: 'Czech Republic', dial_code: '+420', code: 'CZ', emoji: '🇨🇿' },
    DK: { name: 'Denmark', dial_code: '+45', code: 'DK', emoji: '🇩🇰' },
    DJ: { name: 'Djibouti', dial_code: '+253', code: 'DJ', emoji: '🇩🇯' },
    DM: { name: 'Dominica', dial_code: '+1767', code: 'DM', emoji: '🇩🇲' },
    DO: {
      name: 'Dominican Republic',
      dial_code: '+1849',
      code: 'DO',
      emoji: '🇩🇴',
    },
    EC: { name: 'Ecuador', dial_code: '+593', code: 'EC', emoji: '🇪🇨' },
    EG: { name: 'Egypt', dial_code: '+20', code: 'EG', emoji: '🇪🇬' },
    SV: { name: 'El Salvador', dial_code: '+503', code: 'SV', emoji: '🇸🇻' },
    GQ: {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      code: 'GQ',
      emoji: '🇬🇶',
    },
    ER: { name: 'Eritrea', dial_code: '+291', code: 'ER', emoji: '🇪🇷' },
    EE: { name: 'Estonia', dial_code: '+372', code: 'EE', emoji: '🇪🇪' },
    ET: { name: 'Ethiopia', dial_code: '+251', code: 'ET', emoji: '🇪🇹' },
    FK: {
      name: 'Falkland Islands (Malvinas)',
      dial_code: '+500',
      code: 'FK',
      emoji: '🇫🇰',
    },
    FO: { name: 'Faroe Islands', dial_code: '+298', code: 'FO', emoji: '🇫🇴' },
    FJ: { name: 'Fiji', dial_code: '+679', code: 'FJ', emoji: '🇫🇯' },
    FI: { name: 'Finland', dial_code: '+358', code: 'FI', emoji: '🇫🇮' },
    FR: { name: 'France', dial_code: '+33', code: 'FR', emoji: '🇫🇷' },
    GF: { name: 'French Guiana', dial_code: '+594', code: 'GF', emoji: '🇬🇫' },
    PF: {
      name: 'French Polynesia',
      dial_code: '+689',
      code: 'PF',
      emoji: '🇵🇫',
    },
    GA: { name: 'Gabon', dial_code: '+241', code: 'GA', emoji: '🇬🇦' },
    GM: { name: 'Gambia', dial_code: '+220', code: 'GM', emoji: '🇬🇲' },
    GE: { name: 'Georgia', dial_code: '+995', code: 'GE', emoji: '🇬🇪' },
    DE: { name: 'Germany', dial_code: '+49', code: 'DE', emoji: '🇩🇪' },
    GH: { name: 'Ghana', dial_code: '+233', code: 'GH', emoji: '🇬🇭' },
    GI: { name: 'Gibraltar', dial_code: '+350', code: 'GI', emoji: '🇬🇮' },
    GR: { name: 'Greece', dial_code: '+30', code: 'GR', emoji: '🇬🇷' },
    GL: { name: 'Greenland', dial_code: '+299', code: 'GL', emoji: '🇬🇱' },
    GD: { name: 'Grenada', dial_code: '+1473', code: 'GD', emoji: '🇬🇩' },
    GP: { name: 'Guadeloupe', dial_code: '+590', code: 'GP', emoji: '🇬🇵' },
    GU: { name: 'Guam', dial_code: '+1671', code: 'GU', emoji: '🇬🇺' },
    GT: { name: 'Guatemala', dial_code: '+502', code: 'GT', emoji: '🇬🇹' },
    GG: { name: 'Guernsey', dial_code: '+44', code: 'GG', emoji: '🇬🇬' },
    GN: { name: 'Guinea', dial_code: '+224', code: 'GN', emoji: '🇬🇳' },
    GW: { name: 'Guinea-Bissau', dial_code: '+245', code: 'GW', emoji: '🇬🇼' },
    GY: { name: 'Guyana', dial_code: '+595', code: 'GY', emoji: '🇬🇾' },
    HT: { name: 'Haiti', dial_code: '+509', code: 'HT', emoji: '🇭🇹' },
    VA: {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      code: 'VA',
      emoji: '🇻🇦',
    },
    HN: { name: 'Honduras', dial_code: '+504', code: 'HN', emoji: '🇭🇳' },
    HK: { name: 'Hong Kong', dial_code: '+852', code: 'HK', emoji: '🇭🇰' },
    HU: { name: 'Hungary', dial_code: '+36', code: 'HU', emoji: '🇭🇺' },
    IS: { name: 'Iceland', dial_code: '+354', code: 'IS', emoji: '🇮🇸' },
    IN: { name: 'India', dial_code: '+91', code: 'IN', emoji: '🇮🇳' },
    ID: { name: 'Indonesia', dial_code: '+62', code: 'ID', emoji: '🇮🇩' },
    IR: {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR',
      emoji: '🇮🇷',
    },
    IQ: { name: 'Iraq', dial_code: '+964', code: 'IQ', emoji: '🇮🇶' },
    IE: { name: 'Ireland', dial_code: '+353', code: 'IE', emoji: '🇮🇪' },
    IM: { name: 'Isle of Man', dial_code: '+44', code: 'IM', emoji: '🇮🇲' },
    IL: { name: 'Israel', dial_code: '+972', code: 'IL', emoji: '🇮🇱' },
    IT: { name: 'Italy', dial_code: '+39', code: 'IT', emoji: '🇮🇹' },
    JM: { name: 'Jamaica', dial_code: '+1876', code: 'JM', emoji: '🇯🇲' },
    JP: { name: 'Japan', dial_code: '+81', code: 'JP', emoji: '🗾' },
    JE: { name: 'Jersey', dial_code: '+44', code: 'JE', emoji: '🇯🇪' },
    JO: { name: 'Jordan', dial_code: '+962', code: 'JO', emoji: '🇯🇴' },
    KZ: { name: 'Kazakhstan', dial_code: '+77', code: 'KZ', emoji: '🇰🇿' },
    KE: { name: 'Kenya', dial_code: '+254', code: 'KE', emoji: '🇰🇪' },
    KI: { name: 'Kiribati', dial_code: '+686', code: 'KI', emoji: '🇰🇮' },
    KP: {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      code: 'KP',
      emoji: '🇰🇵',
    },
    KR: {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      code: 'KR',
      emoji: '🇰🇷',
    },
    KW: { name: 'Kuwait', dial_code: '+965', code: 'KW', emoji: '🇰🇼' },
    KG: { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG', emoji: '🇰🇬' },
    LA: { name: 'Laos', dial_code: '+856', code: 'LA', emoji: '🇱🇦' },
    LV: { name: 'Latvia', dial_code: '+371', code: 'LV', emoji: '🇱🇻' },
    LB: { name: 'Lebanon', dial_code: '+961', code: 'LB', emoji: '🇱🇧' },
    LS: { name: 'Lesotho', dial_code: '+266', code: 'LS', emoji: '🇱🇸' },
    LR: { name: 'Liberia', dial_code: '+231', code: 'LR', emoji: '🇱🇷' },
    LY: {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      code: 'LY',
      emoji: '🇱🇾',
    },
    LI: { name: 'Liechtenstein', dial_code: '+423', code: 'LI', emoji: '🇱🇮' },
    LT: { name: 'Lithuania', dial_code: '+370', code: 'LT', emoji: '🇱🇹' },
    LU: { name: 'Luxembourg', dial_code: '+352', code: 'LU', emoji: '🇱🇺' },
    MO: { name: 'Macao', dial_code: '+853', code: 'MO', emoji: '🇲🇴' },
    MK: { name: 'Macedonia', dial_code: '+389', code: 'MK', emoji: '🇲🇰' },
    MG: { name: 'Madagascar', dial_code: '+261', code: 'MG', emoji: '🇲🇬' },
    MW: { name: 'Malawi', dial_code: '+265', code: 'MW', emoji: '🇲🇼' },
    MY: { name: 'Malaysia', dial_code: '+60', code: 'MY', emoji: '🇲🇾' },
    MV: { name: 'Maldives', dial_code: '+960', code: 'MV', emoji: '🇲🇻' },
    ML: { name: 'Mali', dial_code: '+223', code: 'ML', emoji: '🇲🇱' },
    MT: { name: 'Malta', dial_code: '+356', code: 'MT', emoji: '🇲🇹' },
    MH: {
      name: 'Marshall Islands',
      dial_code: '+692',
      code: 'MH',
      emoji: '🇲🇭',
    },
    MQ: { name: 'Martinique', dial_code: '+596', code: 'MQ', emoji: '🇲🇶' },
    MR: { name: 'Mauritania', dial_code: '+222', code: 'MR', emoji: '🇲🇷' },
    MU: { name: 'Mauritius', dial_code: '+230', code: 'MU', emoji: '🇲🇺' },
    YT: { name: 'Mayotte', dial_code: '+262', code: 'YT', emoji: '🇾🇹' },
    MX: { name: 'Mexico', dial_code: '+52', code: 'MX', emoji: '🇲🇽' },
    FM: {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM',
      emoji: '🇫🇲',
    },
    MD: { name: 'Moldova', dial_code: '+373', code: 'MD', emoji: '🇲🇩' },
    MC: { name: 'Monaco', dial_code: '+377', code: 'MC', emoji: '🇲🇨' },
    MN: { name: 'Mongolia', dial_code: '+976', code: 'MN', emoji: '🇲🇳' },
    ME: { name: 'Montenegro', dial_code: '+382', code: 'ME', emoji: '🇲🇪' },
    MS: { name: 'Montserrat', dial_code: '+1664', code: 'MS', emoji: '🇲🇸' },
    MA: { name: 'Morocco', dial_code: '+212', code: 'MA', emoji: '🇲🇦' },
    MZ: { name: 'Mozambique', dial_code: '+258', code: 'MZ', emoji: '🇲🇿' },
    MM: { name: 'Myanmar', dial_code: '+95', code: 'MM', emoji: '🇲🇲' },
    NA: { name: 'Namibia', dial_code: '+264', code: 'NA', emoji: '🇳🇦' },
    NR: { name: 'Nauru', dial_code: '+674', code: 'NR', emoji: '🇳🇷' },
    NP: { name: 'Nepal', dial_code: '+977', code: 'NP', emoji: '🇳🇵' },
    NL: { name: 'Netherlands', dial_code: '+31', code: 'NL', emoji: '🇳🇱' },
    AN: {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      code: 'AN',
      emoji: '🇳🇱',
    },
    NC: { name: 'New Caledonia', dial_code: '+687', code: 'NC', emoji: '🇳🇨' },
    NZ: { name: 'New Zealand', dial_code: '+64', code: 'NZ', emoji: '🇳🇿' },
    NI: { name: 'Nicaragua', dial_code: '+505', code: 'NI', emoji: '🇳🇮' },
    NE: { name: 'Niger', dial_code: '+227', code: 'NE', emoji: '🇳🇪' },
    NG: { name: 'Nigeria', dial_code: '+234', code: 'NG', emoji: '🇳🇬' },
    NU: { name: 'Niue', dial_code: '+683', code: 'NU', emoji: '🇳🇺' },
    NF: { name: 'Norfolk Island', dial_code: '+672', code: 'NF', emoji: '🇳🇫' },
    MP: {
      name: 'Northern Mariana Islands',
      dial_code: '+1670',
      code: 'MP',
      emoji: '🇲🇵',
    },
    NO: { name: 'Norway', dial_code: '+47', code: 'NO', emoji: '🇳🇴' },
    OM: { name: 'Oman', dial_code: '+968', code: 'OM', emoji: '🇴🇲' },
    PK: { name: 'Pakistan', dial_code: '+92', code: 'PK', emoji: '🇵🇰' },
    PW: { name: 'Palau', dial_code: '+680', code: 'PW', emoji: '🇵🇼' },
    PS: {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      code: 'PS',
      emoji: '🇵🇸',
    },
    PA: { name: 'Panama', dial_code: '+507', code: 'PA', emoji: '🇵🇦' },
    PG: {
      name: 'Papua New Guinea',
      dial_code: '+675',
      code: 'PG',
      emoji: '🇵🇬',
    },
    PY: { name: 'Paraguay', dial_code: '+595', code: 'PY', emoji: '🇵🇾' },
    PE: { name: 'Peru', dial_code: '+51', code: 'PE', emoji: '🇵🇪' },
    PH: { name: 'Philippines', dial_code: '+63', code: 'PH', emoji: '🇵🇭' },
    PN: { name: 'Pitcairn', dial_code: '+872', code: 'PN', emoji: '🇵🇳' },
    PL: { name: 'Poland', dial_code: '+48', code: 'PL', emoji: '🇵🇱' },
    PT: { name: 'Portugal', dial_code: '+351', code: 'PT', emoji: '🇵🇹' },
    PR: { name: 'Puerto Rico', dial_code: '+1939', code: 'PR', emoji: '🇵🇷' },
    QA: { name: 'Qatar', dial_code: '+974', code: 'QA', emoji: '🇶🇦' },
    RO: { name: 'Romania', dial_code: '+40', code: 'RO', emoji: '🇷🇴' },
    RU: { name: 'Russia', dial_code: '+7', code: 'RU', emoji: '🇷🇺' },
    RW: { name: 'Rwanda', dial_code: '+250', code: 'RW', emoji: '🇷🇼' },
    RE: { name: 'Reunion', dial_code: '+262', code: 'RE', emoji: '🇷🇪' },
    BL: {
      name: 'Saint Barthelemy',
      dial_code: '+590',
      code: 'BL',
      emoji: '🇧🇱',
    },
    SH: {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH',
      emoji: '🇸🇭',
    },
    KN: {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1869',
      code: 'KN',
      emoji: '🇰🇳',
    },
    LC: { name: 'Saint Lucia', dial_code: '+1758', code: 'LC', emoji: '🇱🇨' },
    MF: { name: 'Saint Martin', dial_code: '+590', code: 'MF', emoji: '🇫🇷' },
    PM: {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      code: 'PM',
      emoji: '🇵🇲',
    },
    VC: {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      code: 'VC',
      emoji: '🇻🇨',
    },
    WS: { name: 'Samoa', dial_code: '+685', code: 'WS', emoji: '🇼🇸' },
    SM: { name: 'San Marino', dial_code: '+378', code: 'SM', emoji: '🇸🇲' },
    ST: {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      code: 'ST',
      emoji: '🇸🇹',
    },
    SA: { name: 'Saudi Arabia', dial_code: '+966', code: 'SA', emoji: '🇸🇦' },
    SN: { name: 'Senegal', dial_code: '+221', code: 'SN', emoji: '🇸🇳' },
    RS: { name: 'Serbia', dial_code: '+381', code: 'RS', emoji: '🇷🇸' },
    SC: { name: 'Seychelles', dial_code: '+248', code: 'SC', emoji: '🇸🇨' },
    SL: { name: 'Sierra Leone', dial_code: '+232', code: 'SL', emoji: '🇸🇱' },
    SG: { name: 'Singapore', dial_code: '+65', code: 'SG', emoji: '🇸🇬' },
    SK: { name: 'Slovakia', dial_code: '+421', code: 'SK', emoji: '🇸🇰' },
    SI: { name: 'Slovenia', dial_code: '+386', code: 'SI', emoji: '🇸🇮' },
    SB: { name: 'Solomon Islands', dial_code: '+677', code: 'SB', emoji: '🇸🇧' },
    SO: { name: 'Somalia', dial_code: '+252', code: 'SO', emoji: '🇸🇴' },
    ZA: { name: 'South Africa', dial_code: '+27', code: 'ZA', emoji: '🇿🇦' },
    SS: { name: 'South Sudan', dial_code: '+211', code: 'SS', emoji: '🇸🇸' },
    GS: {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS',
      emoji: '🇬🇸',
    },
    ES: { name: 'Spain', dial_code: '+34', code: 'ES', emoji: '🇪🇸' },
    LK: { name: 'Sri Lanka', dial_code: '+94', code: 'LK', emoji: '🇱🇰' },
    SD: { name: 'Sudan', dial_code: '+249', code: 'SD', emoji: '🇸🇩' },
    SR: { name: 'Suriname', dial_code: '+597', code: 'SR', emoji: '🇸🇷' },
    SJ: {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      code: 'SJ',
      emoji: '🇳🇴',
    },
    SZ: { name: 'Swaziland', dial_code: '+268', code: 'SZ', emoji: '🇸🇿' },
    SE: { name: 'Sweden', dial_code: '+46', code: 'SE', emoji: '🇸🇪' },
    CH: { name: 'Switzerland', dial_code: '+41', code: 'CH', emoji: '🇨🇭' },
    SY: {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      code: 'SY',
      emoji: '🇸🇾',
    },
    TW: { name: 'Taiwan', dial_code: '+886', code: 'TW', emoji: '🇹🇼' },
    TJ: { name: 'Tajikistan', dial_code: '+992', code: 'TJ', emoji: '🇹🇯' },
    TZ: {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ',
      emoji: '🇹🇿',
    },
    TH: { name: 'Thailand', dial_code: '+66', code: 'TH', emoji: '🇹🇭' },
    TL: { name: 'Timor-Leste', dial_code: '+670', code: 'TL', emoji: '🇹🇱' },
    TG: { name: 'Togo', dial_code: '+228', code: 'TG', emoji: '🇹🇬' },
    TK: { name: 'Tokelau', dial_code: '+690', code: 'TK', emoji: '🇹🇰' },
    TO: { name: 'Tonga', dial_code: '+676', code: 'TO', emoji: '🇹🇴' },
    TT: {
      name: 'Trinidad and Tobago',
      dial_code: '+1868',
      code: 'TT',
      emoji: '🇹🇹',
    },
    TN: { name: 'Tunisia', dial_code: '+216', code: 'TN', emoji: '🇹🇳' },
    TR: { name: 'Turkey', dial_code: '+90', code: 'TR', emoji: '🇹🇷' },
    TM: { name: 'Turkmenistan', dial_code: '+993', code: 'TM', emoji: '🇹🇲' },
    TC: {
      name: 'Turks and Caicos Islands',
      dial_code: '+1649',
      code: 'TC',
      emoji: '🇹🇨',
    },
    TV: { name: 'Tuvalu', dial_code: '+688', code: 'TV', emoji: '🇹🇻' },
    UG: { name: 'Uganda', dial_code: '+256', code: 'UG', emoji: '🇺🇬' },
    UA: { name: 'Ukraine', dial_code: '+380', code: 'UA', emoji: '🇺🇦' },
    AE: {
      name: 'United Arab Emirates',
      dial_code: '+971',
      code: 'AE',
      emoji: '🇦🇪',
    },
    UK: { name: 'United Kingdom', dial_code: '+44', code: 'UK', emoji: '🇬🇧' },
    US: { name: 'United States', dial_code: '+1', code: 'US', emoji: '🇺🇸' },
    UY: { name: 'Uruguay', dial_code: '+598', code: 'UY', emoji: '🇺🇾' },
    UZ: { name: 'Uzbekistan', dial_code: '+998', code: 'UZ', emoji: '🇺🇿' },
    VU: { name: 'Vanuatu', dial_code: '+678', code: 'VU', emoji: '🇻🇺' },
    VE: {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE',
      emoji: '🇻🇪',
    },
    VN: { name: 'Vietnam', dial_code: '+84', code: 'VN', emoji: '🇻🇳' },
    VG: {
      name: 'Virgin Islands, British',
      dial_code: '+1284',
      code: 'VG',
      emoji: '🇻🇬',
    },
    VI: {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1340',
      code: 'VI',
      emoji: '🇻🇮',
    },
    WF: {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      code: 'WF',
      emoji: '🇼🇫',
    },
    YE: { name: 'Yemen', dial_code: '+967', code: 'YE', emoji: '🇾🇪' },
    ZM: { name: 'Zambia', dial_code: '+260', code: 'ZM', emoji: '🇿🇲' },
    ZW: { name: 'Zimbabwe', dial_code: '+263', code: 'ZW', emoji: '🇿🇼' },
  },
};
export default countryCodesIndexed;
