import { FC, useEffect, useMemo } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CompanyForm from 'src/components/CompanyForm/CompanyForm';
import HistoryBlock from 'src/components/History/HistoryBlock';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { createCompany, getCompanies } from 'src/store/companies/thunk';
import { CompanyFormModel } from 'src/types/types';

const CompanyFormPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { companies, myCompany } = useSelector(selectCompanies);

  const isCompaniesLimitAchieved = useMemo(() => {
    return !!(myCompany && myCompany.companiesLimit && myCompany.companiesLimit <= companies.length);
  }, [companies.length, myCompany]);

  const onSubmit = async (company: CompanyFormModel) => {
    const action = await dispatch(createCompany({ company }));
    if (isFulfilled(action)) navigate(routeNames.COMPANIES);
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  return (
    <div className="companies-form__container">
      <HistoryBlock link={routeNames.COMPANIES} currentPageTitle="Add company" linkedPageTitle="Companies" />
      <CompanyForm
        onSubmit={onSubmit}
        title="Add company"
        buttonTitle="Add company"
        isCompaniesLimitAchieved={isCompaniesLimitAchieved}
      />
    </div>
  );
};

export default CompanyFormPage;
