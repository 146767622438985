import { FC } from 'react';

type TextareaProps = {
  label?: string,
  value: string,
  onChange: (v: string) => void,
  placeholder: string
}

const Textarea: FC<TextareaProps> = ({ label, value, onChange, placeholder }) => {
  return (
    <div className="textarea">
      {label && <p className="textarea__label">{label}</p>}
      <textarea
        className="textarea__field"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Textarea;
