import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { getCustomiseFilterPolicies } from 'src/utils/getCustomiseFilterPolicies';
import { getFilteredPolicies } from 'src/utils/getFilteredPolicies';
import { getGrouptedPolicies } from 'src/utils/getGrouptedPolicies';

const baseState = (state: RootState) => state.policies;

export const selectPolicies = createDraftSafeSelector(baseState, (state) => {
  return state;
});

export const selectFilteredPolicies = createDraftSafeSelector(baseState, (state) => {
  if (Object.keys(state.policyPageCustomFilter).length) {
    return getCustomiseFilterPolicies(state.policies, state.policyPageCustomFilter);
  }
  return getFilteredPolicies(state.policies, state.policyPageFilterBy);
});

export const selectGroupedPolicies = createDraftSafeSelector(baseState, (state) => {
  return getGrouptedPolicies(getFilteredPolicies(state.policies, state.policyPageFilterBy), state.policyPageGroupedBy);
});

export const selectReviewingFilteredPolicies = createDraftSafeSelector(baseState, (state) => {
  if (Object.keys(state.policyPageCustomFilter).length) {
    return getCustomiseFilterPolicies(state.reviewingPolicies, state.reviewingPageCustomFilter);
  }
  return getFilteredPolicies(state.reviewingPolicies, state.reviewingPageFilterBy);
});

export const selectReviewingGroupedPolicies = createDraftSafeSelector(baseState, (state) => {
  return getGrouptedPolicies(
    getFilteredPolicies(state.reviewingPolicies, state.reviewingPageFilterBy), state.reviewingPageGroupedBy,
  );
});
