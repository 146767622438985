import { FC, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import { differenceInDays, format } from 'date-fns';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

import { ReactComponent as Attach } from 'src/assets/icons/Attach.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/Calendar.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/Delete.svg';
import { PolicyModel } from 'src/types/types';
import { onChangeTimeHandler } from 'src/utils/timeUtils';

import Button from '../Button/Button';

const fileSize = 10048576;

const initialValues = {
  name: '',
  startDate: '',
  finishDate: '',
  file: null,
  notifications: [],
};

type UpdateForm = {
  onSubmit: (startDate: string, finishDate: string, notifications, file) => void,
  policy: PolicyModel,
}

const UpdatePoliciesForm: FC<UpdateForm> = ({ onSubmit, policy }) => {
  const [finishDate, setFinishDate] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [attachmentLabel, setAttachmentLabel] = useState('Upload file');
  const [fileSizeError, setFileSizeError] = useState('');
  const [time, setTime] = useState('');
  const [diff, setDiff] = useState<number[] | []>([]);

  const onSetFiles = (file: FileList | null) => {
    if (file && file.length && file[0].size < fileSize) {
      setFile(file[0]);
      setAttachmentLabel(file[0].name);
    } else {
      setFileSizeError('File size exceeds 10mb');
      setFile(null);
      setAttachmentLabel('Upload file');
    }
  };

  const deleteFile = () => {
    setFile(null);
    setAttachmentLabel('Upload file');
  };

  const formatChars = {
    1: '[0-2]',
    2: time[0] === '2' ? '[0-3]' : '[0-9]',
    3: '[0-5]',
    4: '[0-9]',
  };

  const updateNotification = (finishDate) => {
    if (diff.length === 0) {
      return [];
    }
    return diff.map((d) => {
      const date = new Date(finishDate);
      return new Date(date.setDate(date.getDate() - d));
    });
  };

  useEffect(() => {
    if (policy.notifications) {
      if (policy.notifications.length > 0) {
        const diferenceInDays = policy.notifications.map((n, i) => {
          return differenceInDays(new Date(policy.finishDate), new Date(policy.notifications[i]));
        });
        setDiff(diferenceInDays);
      }
    }
  }, [policy]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={((values) => {
        onSubmit(values.startDate, values.finishDate, values.notifications, file);
        setFinishDate('');
      })}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, setFieldValue, isValid, dirty }) => {
        return (
          <Form className="form">
            <div className="form__two-blocks-container">
              <div className="form__left-block">
                <div className="form__item-block">
                  <h5>Name</h5>
                  <Field
                    value={policy.name}
                    name="name"
                    className={classNames({ 'input-text': true, 'input-text_error': errors.name })}
                    disabled
                  />
                  <span className="form__error">
                    <ErrorMessage name="name" />
                  </span>
                </div>

                <div className="form__item-block">
                  <h5>Expiry date</h5>
                  <div className={classNames({
                    calendar__container: true,
                  })}
                  >
                    <DatePicker
                      selected={values.finishDate && new Date(values.finishDate)}
                      onChange={(date) => {
                        setFinishDate(format(new Date(date), 'd MMM y'));
                        setFieldValue('finishDate', date);
                        setFieldValue('notifications',
                          updateNotification(date));
                      }}
                      filterDate={(date) => {
                        if (values.finishDate) {
                          return date > new Date();
                        }
                        return differenceInDays(date, new Date()) >= 0;
                      }}
                      customInput={(
                        <span className="calendar__input white-block">
                          <Field name="finishDate" value={finishDate} placeholder="Select date" disabled />
                          <CalendarIcon />
                        </span>
                      )}
                    />
                    <InputMask
                      name="time"
                      mask="12:34"
                      className="calendar__time white-block"
                      placeholder="00:00"
                      formatChars={formatChars}
                      onChange={(e) => {
                        setTime(e.target.value);
                        return onChangeTimeHandler(e.target.value.split(':')[0], e.target.value.split(':')[1]);
                      }}
                    />
                  </div>
                  <span className="form__error">
                    <ErrorMessage name="finishDate" />
                  </span>
                </div>
              </div>
            </div>

            <div className="form__item-block" style={{ maxWidth: '280px' }}>
              <h5>Attachment</h5>
              <label
                htmlFor="upload-file"
                className={classNames({
                  'form__input-file__label': true,
                  'white-block': true,
                  'form__input-file__label_not-empty': file,
                  'input-text_error': fileSizeError,
                })}
              >
                <span className="form__file-label">{attachmentLabel}</span>
                {file ? <DeleteIcon onClick={deleteFile} /> : <Attach />}

                <input
                  type="file"
                  className={classNames({ 'form__input-file white-block': true })}
                  placeholder="Upload file"
                  id="upload-file"
                  onChange={(e) => { onSetFiles(e.target.files); setFieldValue('file', e.target.files); }}
                  disabled={file !== null}
                />

              </label>
              {fileSizeError && (
                <span className="form__error">
                  {fileSizeError}
                </span>
              )}
            </div>

            <div className="form__button-block">
              <Button disabled={!isValid && !dirty} title="Submit" type="submit" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdatePoliciesForm;
