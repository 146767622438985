import { createAsyncThunk } from '@reduxjs/toolkit';

import CompanyApi from 'src/api/CompanyApi';
import { CompanyFormModel } from 'src/types/types';
import { LightenDarkenColor } from 'src/utils/color';

export const getCompanies = createAsyncThunk(
  'companies/getCompanies',
  async () => {
    const res = await CompanyApi.fetchCompanies();
    return res;
  },
);

export const getAllCompanies = createAsyncThunk(
  'companies/getAllCompanies',
  async () => {
    const res = await CompanyApi.fetchAllCompanies();
    return res;
  },
);

export const createCompany = createAsyncThunk(
  'companies/createCompany',
  async (params: { company: CompanyFormModel }) => {
    const { company } = params;
    const { logo } = company;
    const res = await CompanyApi.createCompany(company, logo);
    return res;
  },
);

export const getCompanyById = createAsyncThunk(
  'companies/getCompanyById',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await CompanyApi.getCompanyById(id);
    return res;
  },
);

export const getMyCompany = createAsyncThunk(
  'companies/getMyCompany',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await CompanyApi.getCompanyById(id);

    document.documentElement.style.setProperty('--color-blue', res.color || '#144DDE');
    document.documentElement.style.setProperty(
      '--color-dark-blue',
      res.color ? LightenDarkenColor(res.color, -50) : '#0e43c8',
    );
    return res;
  },
);

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (params: { company: CompanyFormModel, id: string }) => {
    const { company, id } = params;
    const { logo } = company;
    const res = await CompanyApi.updateCompany(company, id, logo);
    return res;
  },
);

export const onboardCompany = createAsyncThunk(
  'companies/onboardCompany',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await CompanyApi.onboardCompany(id);
    return res;
  },
);

export const getCompaniesCount = createAsyncThunk(
  'policies/getCompaniesCount',
  async () => {
    const res = await CompanyApi.fetchCompaniesCount();
    return res;
  },
);

export const deleteCompany = createAsyncThunk(
  'companies/deleteCompany',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await CompanyApi.deleteCompany(id);
    return res;
  },
);
