import { FC, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from 'src/assets/icons/Plus.svg';
import Button from 'src/components/Button/Button';
import Search from 'src/components/Search/Search';
import SmallMobileTable from 'src/components/SmallTable/SmallMobileTable';
import SmallTable from 'src/components/SmallTable/SmallTable';
import { userRoleItems } from 'src/constants';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getCompanies, getCompaniesCount } from 'src/store/companies/thunk';
import { selectUsers } from 'src/store/users/selectors';
import { CompanyModel } from 'src/types/types';

const tableHeaders = ['Name', 'Type', 'Contact name', 'Contact email', 'Contact phone', 'Added'];

const getSearchedCompanies = (companies: CompanyModel[], searchValue: string) => {
  return companies.filter((company) => company.companyName.toLowerCase().includes(searchValue.toLowerCase()));
};

const CompaniesPage: FC = () => {
  const dispatch = useAppDispatch();
  const { companies, currentPage, myCompany } = useSelector(selectCompanies);
  const { me } = useSelector(selectUsers);
  const [searchValue, setSearchValue] = useState('');

  const userRole = userRoleItems.find((r) => r.role === me?.role.name);

  const isCompaniesLimitAchieved = useMemo(() => {
    return !!(myCompany && myCompany.companiesLimit && myCompany.companiesLimit <= companies.length);
  }, [companies.length, myCompany]);

  useEffect(() => {
    dispatch(getCompaniesCount());
    dispatch(getCompanies());
  }, [dispatch, currentPage]);

  return (
    <div className="companies__container">
      <h1>Companies</h1>
      <div className="companies__search-block">
        <Search placeholder="Search by company or agency name" onChange={(value: string) => setSearchValue(value)} />
        {userRole?.canUserAddCompanies && (
          <Link to={isCompaniesLimitAchieved ? routeNames.BILLING : routeNames.ADD_COMPANY}>
            <Button title="Add company" className="companies__add-button">
              <PlusIcon />
            </Button>
          </Link>
        )}
      </div>
      <div className="companies__table">
        {me && (
          <SmallTable
            companies={getSearchedCompanies(companies, searchValue)}
            editLink={routeNames.EDIT_COMPANY}
            tableHeaders={tableHeaders}
            me={me}
          />
        )}

        {me && (
          <SmallMobileTable
            companies={getSearchedCompanies(companies, searchValue)}
            me={me}
          />
        )}
      </div>

      {/* Pagination functional is not ready for now */}
      {/* {pages.length > 1 && (
        <Pagination
          setPage={(page) => dispatch(setCurrentPage(page))}
          itemsAmount={3}
          currentPage={currentPage}
        />
      )} */}
    </div>
  );
};

export default CompaniesPage;
