import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import BillingCard from 'src/components/BillingCard/BillingCard';
import Button from 'src/components/Button/Button';
import { freeBillingPlan } from 'src/constants';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { selectFiltredProducts } from 'src/store/billings/selectors';
import { getPaymentLink, unsubscribe } from 'src/store/billings/thunk';
import { selectCompanies } from 'src/store/companies/selectors';
import { getMyCompany } from 'src/store/companies/thunk';
import { selectUsers } from 'src/store/users/selectors';
import { BillingType } from 'src/types/types';

import Toggle from '../components/Toggle/Toggle';

type AlertProps = {
  onClose: () => void,
  callback: () => void
}

const Alert: FC<AlertProps> = ({ onClose, callback }) => {
  return (
    <div className="alert-overlay" onClick={onClose} role="presentation">
      <div className="alert" onClick={(e) => e.stopPropagation()} role="presentation">
        <CloseIcon className="alert__close-icon" onClick={onClose} />
        <h3 className="alert__title">Unsubscribe</h3>
        <p className="alert__text">Are you sure you want to cancel your subscription?</p>
        <div className="alert__buttons">
          <Button title="Go back" className="alert__button" onClick={onClose} />
          <Button title="Yes, unsubscribe" appearance="transparent" className="alert__button" onClick={callback} />
        </div>
      </div>
    </div>
  );
};

const BillingPage: FC = () => {
  const dispatch = useAppDispatch();
  const { me } = useSelector(selectUsers);
  const { myCompany } = useSelector(selectCompanies);

  const [selectedBilling, setSelectedBilling] = useState<null | string>(null);
  const [isAlertShown, setAlertShown] = useState(false);
  const [toggleRole, setToggleRole] = useState(false);

  const { companyProducts, agencyProducts } = useSelector(selectFiltredProducts);

  const proceed = () => {
    if (selectedBilling) {
      dispatch(getPaymentLink({ id: selectedBilling })).then((res) => {
        const payload = res.payload as { url: string };
        window.location.replace(payload.url);
      });
    }
  };

  const onCardClick = (billing: BillingType) => {
    if (billing.id === myCompany?.billingId) {
      setAlertShown(true);
    } else if (billing.metadata.bookingLink) {
      window.open(billing.metadata.bookingLink, '_blank');
    } else {
      setSelectedBilling(billing.id);
    }
  };

  const unsubscribeBilling = () => {
    dispatch(unsubscribe()).then(() => {
      setAlertShown(false);
      if (me) dispatch(getMyCompany({ id: me.companyId }));
    });
  };

  // const getBillingsByCompanyType = useCallback(async () => {
  //   const action = await dispatch(getBillings());

  //   if (isFulfilled(action)) {
  //     const billingType = toggleRole ? 'agency' : 'company';
  //     const filteredBillings = action.payload.filter((billing) => {
  //       return billing.metadata.companyType === billingType;
  //     });

  //     setBillingsByCompanyType(filteredBillings);
  //   }
  // }, [dispatch, toggleRole]);

  const switchRole = () => {
    setToggleRole(!toggleRole);
  };

  useEffect(() => {
    if (me) dispatch(getMyCompany({ id: me.companyId }));
  }, [dispatch, me]);

  useEffect(() => {
    setToggleRole(myCompany?.companyType === 'agency');
  }, [myCompany]);

  return (
    <div className="billing">
      <h1 className="billing__title">Billing</h1>
      <div className="billing__toggle-box">
        <Toggle checked={toggleRole} changeHandler={switchRole} />
        <p>Single Company / Multi Company</p>
      </div>

      <ul className="billing__billings-list">
        {!!(toggleRole ? agencyProducts : companyProducts).length && (
          <>
            {!toggleRole && (
              <li className="billing__billing-item">
                <BillingCard
                  title="Free"
                  usersLimit={+freeBillingPlan.metadata.usersLimit}
                  features={freeBillingPlan.metadata.features}
                  featuresPrefix=""
                  companiesLimit={+freeBillingPlan.metadata.companiesLimit}
                  policiesLimit={+freeBillingPlan.metadata.policiesLimit}
                  active={!myCompany?.billingId}
                  showButtons={me?.role.name.includes('admin')}
                  multiple={false}
                  onClick={() => setAlertShown(true)}
                />
              </li>
            )}
            {(toggleRole ? agencyProducts : companyProducts).map((billing) => {
              return (
                <li className="billing__billing-item" key={billing.id}>
                  <BillingCard
                    title={billing.name}
                    price={billing.prices[0].price}
                    features={billing.metadata.features ? billing.metadata.features : ''}
                    featuresPrefix={billing.metadata.featuresPrefix ? billing.metadata.featuresPrefix : ''}
                    usersLimit={billing.metadata.usersLimit ? +billing.metadata.usersLimit : null}
                    companiesLimit={billing.metadata.companiesLimit ? +billing.metadata.companiesLimit : null}
                    policiesLimit={billing.metadata.policiesLimit ? +billing.metadata.policiesLimit : null}
                    active={billing.id === myCompany?.billingId}
                    selected={selectedBilling === billing.id}
                    multiple={toggleRole}
                    onClick={() => onCardClick(billing)}
                    // TODO use constants here
                    showButtons={me?.role.name.includes('admin')}
                  />
                </li>
              );
            })}
          </>
        )}
      </ul>

      {selectedBilling && (
        <Button
          title="Proceed to checkout"
          className="billing__proceed-button"
          onClick={proceed}
        />
      )}
      {isAlertShown && <Alert onClose={() => setAlertShown(false)} callback={unsubscribeBilling} />}
    </div>
  );
};

export default BillingPage;
