/* eslint-disable no-param-reassign */
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { PolicyFormValues } from 'src/components/PolicyForm/PolicyForm';
import { CompanyModel, PolicyFormModel, PolicyModel } from 'src/types/types';

type GroupedPolicies = Record<string, PolicyModel[]>

export const serializePolicy = (values: PolicyFormValues): PolicyFormModel => {
  return {
    name: values.name,
    companyId: values.company.companyId,
    contactName: values.contactName,
    coverageAmount: values.coverageAmount,
    emailToNotify: values.emailToNotify,
    finishDate: values.finishDate?.toISOString() || null,
    notes: values.notes,
    policyNumber: values.policyNumber,
    notifications: values.notifications.map((n) => n.toISOString()),
  };
};

export const deserializePolicy = (values: PolicyModel, company: CompanyModel): PolicyFormValues => {
  return {
    name: values.name,
    policyNumber: values.policyNumber,
    contactName: values.contactName,
    emailToNotify: values.emailToNotify,
    company,
    coverageAmount: values.coverageAmount,
    finishDate: values.finishDate !== null ? new Date(values.finishDate) : null,
    notifications: values.notifications.map((n) => new Date(n)),
    notes: values.notes,
    attachment: values.attachment,
  };
};

export const isPolicyExpired = (policy: PolicyModel): boolean => {
  return policy.finishDate ? new Date(policy.finishDate).getTime() < new Date().getTime() : false;
};

export const isPolicyExpiresSoon = (policy: PolicyModel): boolean => {
  const diff = differenceInCalendarDays(new Date(policy.finishDate), new Date());
  return policy.finishDate ? diff < 8 && diff > 0 : false;
};

export const isPolicyExpiredIsOnOrAfterWeek = (policy: PolicyModel): boolean => {
  const diff = differenceInCalendarDays(new Date(policy.finishDate), new Date());
  return policy.finishDate ? diff > 7 && diff < 61 : false;
};

export const isPolicyExpiredIsOnOrAfterTwoMonth = (policy: PolicyModel): boolean => {
  const diff = differenceInCalendarDays(new Date(policy.finishDate), new Date());
  return policy.finishDate ? diff > 60 : false;
};

export const sortPolicies = (policies: PolicyModel[], sortByKey: string, order: boolean): PolicyModel[] => {
  return [...policies].sort((a, b) => {
    if (a[sortByKey].toLocaleLowerCase() > b[sortByKey].toLocaleLowerCase()) return order ? -1 : 1;
    return order ? 1 : -1;
  });
};

export const sortPoliciesByDate = (policies: PolicyModel[], key: string, order: boolean): PolicyModel[] => {
  return [...policies].sort((a, b) => {
    const dateA = a[key] ? new Date(a[key]).getTime().toString() : '0';
    const dateB = b[key] ? new Date(b[key]).getTime().toString() : '0';

    if (dateA > dateB) return order ? -1 : 1;
    return order ? 1 : -1;
  });
};

export const groupPoliciesByKey = (
  policies: PolicyModel[],
  groupBy: string,
  keyMatcher?: (policy: PolicyModel, key: string) => string,
): GroupedPolicies => {
  return policies.reduce((acc, v) => {
    const key = Object.keys(v).find((k) => k === groupBy);
    if (key) {
      let keyValue = v[key];
      if (keyMatcher) {
        keyValue = keyMatcher(v, keyValue);
      }
      if (keyValue in acc) {
        acc[keyValue].push(v);
      } else {
        acc = { ...acc, [keyValue]: [v] };
      }
    }
    return acc;
  }, {});
};

export const groupPoliciesByFinishDateStatus = (policies: PolicyModel[]): GroupedPolicies => {
  return policies.reduce((acc, v) => {
    const expired = isPolicyExpired(v);

    if (expired) {
      acc['Expiry Date is on or before one week from now'].push(v);
      return acc;
    }

    const expiresSoon = isPolicyExpiresSoon(v);

    if (expiresSoon) {
      acc['Expiry Date is on or before one week from now'].push(v);
      return acc;
    }

    const expiresFromNextWeek = isPolicyExpiredIsOnOrAfterWeek(v);

    if (expiresFromNextWeek) {
      acc['Expiry Date is on or after 8 days from now'].push(v);
      return acc;
    }

    const expiresInTwoMonth = isPolicyExpiredIsOnOrAfterTwoMonth(v);

    if (expiresInTwoMonth) {
      acc['Expiry Date is on or after 61 days from now'].push(v);
      return acc;
    }

    return acc;
  }, {
    'Expiry Date is on or before one week from now': [] as PolicyModel[],
    'Expiry Date is on or after 8 days from now': [] as PolicyModel[],
    'Expiry Date is on or after 61 days from now': [] as PolicyModel[],
  });
};
