import { createAsyncThunk } from '@reduxjs/toolkit';

import PolicyApi from 'src/api/PolicyApi';
import { PolicyFormModel } from 'src/types/types';

export const getPolicies = createAsyncThunk(
  'policies/getPolicies',
  async () => {
    const res = await PolicyApi.fetchPolicies();
    return res;
  },
);

export const createPolicy = createAsyncThunk(
  'policies/createPolicy',
  async (params: { policy: PolicyFormModel, file: File | null }) => {
    const { policy, file } = params;
    const res = await PolicyApi.createpolicy(policy, file);
    return res;
  },
);

export const getPolicyById = createAsyncThunk(
  'policies/getPolicyById',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await PolicyApi.findPolicyById(id);
    return res;
  },
);

export const deletePolicy = createAsyncThunk(
  'policies/deletePolicy',
  async (params: { id: string }) => {
    const { id } = params;
    const res = await PolicyApi.deletePolicy(id);
    return res;
  },
);

export const updatePolicy = createAsyncThunk(
  'policies/updatePolicy',
  async (params: { id: string, policy: PolicyFormModel, file: File | null }) => {
    const { policy, id, file } = params;
    const res = await PolicyApi.updatePolicy(id, policy, file);
    return res;
  },
);

export const deletePolicies = createAsyncThunk(
  'policies/deletePolicies',
  async (params: { ids: string[] }) => {
    const { ids } = params;

    const promises = ids.map((id) => {
      return PolicyApi.deletePolicy(id);
    });
    const res = Promise.allSettled(promises);
    return res;
  },
);

export const approvedPolicies = createAsyncThunk(
  'policies/approvedPolicies',
  async (params: { ids: string[] }) => {
    const { ids } = params;

    const promises = ids.map((id) => PolicyApi.approvePolicy(id));
    const res = Promise.allSettled(promises);
    return res;
  },
);

export const getPoliciesCount = createAsyncThunk(
  'policies/getPoliciesCount',
  async () => {
    const res = await PolicyApi.fetchPoliciesCount();
    return res;
  },
);

export const sendNotification = createAsyncThunk(
  'policies/sendNotification',
  async (params: { id: string }) => {
    const res = await PolicyApi.sendNotification(params.id);
    return res;
  },
);

export const getNotifications = createAsyncThunk(
  'policies/getNotifications',
  async (params: { id: string }) => {
    const res = await PolicyApi.getNotifications(params.id);
    return res;
  },
);
