import { createSlice } from '@reduxjs/toolkit';

import { sliceNames } from 'src/constants';
import { BillingType } from 'src/types/types';

import { getBillings, getPaymentLink } from './thunk';

const billingsSlice = createSlice({
  name: sliceNames.billings,
  initialState: {
    billings: [] as BillingType[],
    paymentLink: '',
    myBilling: null as (null | BillingType),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBillings.fulfilled, (state, action) => {
      state.billings = action.payload;
    });

    builder.addCase(getPaymentLink.fulfilled, (state, action) => {
      state.paymentLink = action.payload.url;
    });
  },
});

export default billingsSlice.reducer;
