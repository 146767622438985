import { FC, useMemo } from 'react';

import classNames from 'classnames';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as AttachIcon } from 'src/assets/icons/Attach.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/editIcon.svg';
import { ReactComponent as NotificationIcon } from 'src/assets/icons/Notif.svg';
import { userRoleItems } from 'src/constants';
import { OptionSize, TableConfig } from 'src/pages/Policy/TableConfig';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { PolicyModel, UserModel } from 'src/types/types';
import { downloadFiles } from 'src/utils/browser';
import { coverageFormatter } from 'src/utils/formatters';
import {
  isPolicyExpired,
  isPolicyExpiredIsOnOrAfterTwoMonth,
  isPolicyExpiredIsOnOrAfterWeek,
  isPolicyExpiresSoon,
} from 'src/utils/policy';

import Checkbox from '../Checkbox/Checkbox';
import NotificationsTips from './NotificationTips';

type FieldProps = {
  policy: PolicyModel,
  selectedPolicies: PolicyModel[],
  config: TableConfig[],
  changeSelectedPolicies: (p: PolicyModel[]) => void,
  enableTips?: boolean,
  user: UserModel,
  enableCheckboxes: boolean
}

const getFieldSize = (totalLength: number, size: OptionSize) => 100 / totalLength / size;

type PolicyNameProps = {
  policy: PolicyModel
}

const PolicyName: FC<PolicyNameProps> = ({ policy }) => {
  const expired = isPolicyExpired(policy);
  const expiresSoon = isPolicyExpiresSoon(policy);
  const expiresInOnOrAfterWeek = isPolicyExpiredIsOnOrAfterWeek(policy);
  const expiresInOnOrAfterTwoMonth = isPolicyExpiredIsOnOrAfterTwoMonth(policy);

  const policyNameClassName = classNames(
    'policy-name',
    { 'policy-name--danger': expired || expiresSoon },
    { 'policy-name--normal': expiresInOnOrAfterWeek },
    { 'policy-name--safe': expiresInOnOrAfterTwoMonth },
  );

  return (
    <div className={policyNameClassName}>
      <div className="policy-name__mobile-name">Policy name</div>
      <p className="policy-name__name">{policy.name || '---'}</p>
      {expired && <p className="policy-name__subtext--expired">Expired already</p>}
      {expiresSoon && <p className="policy-name__subtext--expired">Expires soon</p>}
    </div>
  );
};

type PolicyNumberProps = {
  policy: PolicyModel
}

const PolicyNumber: FC<PolicyNumberProps> = ({ policy }) => {
  const { companies } = useSelector(selectCompanies);

  const policyCompany = companies.find((c) => c.companyId === policy.companyId);

  return (
    <div className="policy-number">
      <div className="policy-number__mobile-number">Policy number</div>
      <p className="policy-number__number">{policy.policyNumber || '---'}</p>
      {policyCompany && <p className="policy-number__company-name">{policyCompany.companyName}</p>}
    </div>
  );
};

type PolicyDateProps = {
  date: Date | null
}

const PolicyExpiryDate: FC<PolicyDateProps> = ({ date }) => {
  return (
    <div className="policy-date policy-expiry-date">
      {date && (
        <>
          <div className="policy-date__mobile-date">Expiry date</div>
          <p className="policy-date__date">{format(new Date(date), 'dd LLL y')}</p>
          <p className="policy-date__time">{format(new Date(date), 'hh:mm aaa')}</p>
        </>
      )}
      {!date && '---'}
    </div>
  );
};

const PolicyUpdateDate: FC<PolicyDateProps> = ({ date }) => {
  return (
    <div className="policy-date policy-update-date">
      {date && (
        <>
          <p className="policy-date__date">{format(new Date(date), 'dd LLL y')}</p>
          <p className="policy-date__time">{format(new Date(date), 'hh:mm aaa')}</p>
        </>
      )}
      {!date && '---'}
    </div>
  );
};

type PolicyTextProps = {
  text: string,
  preventWrap?: boolean
}

const PolicyText: FC<PolicyTextProps> = ({ text, preventWrap }) => {
  const className = classNames('policy-text', { 'policy-text--no-wrap': preventWrap });
  return (
    <div className={className}>{text}</div>
  );
};

type PolicyNotesProps = {
  text: string,
}

const PolicyNotes: FC<PolicyNotesProps> = ({ text }) => {
  return (
    <div className="policy-notes">{text.length ? [...text.split('').slice(0, 35), '...'] : '---'}</div>
  );
};

type PolicyNotificationProps = {
  policy: PolicyModel,
  selectedPolicies: PolicyModel[],
  user: UserModel,
  enableCheckboxes?: boolean,
  changeSelectedPolicies: (v: PolicyModel[]) => void
}

const PolicyNotifications: FC<PolicyNotificationProps> = (props) => {
  const { policy, user, enableCheckboxes, selectedPolicies, changeSelectedPolicies } = props;

  const canUserManagePolicies = (() => {
    const userRole = userRoleItems.find((r) => r.role === user.role.name);
    if (userRole) return userRole.canUserManagePolicies;
    return false;
  })();

  const isCheckboxActive = useMemo(() => {
    return selectedPolicies.findIndex((p) => p.policyId === policy.policyId) > -1;
  }, [policy.policyId, selectedPolicies]);

  const handleCheckboxClick = () => {
    if (isCheckboxActive) changeSelectedPolicies(selectedPolicies.filter((p) => p.policyId !== policy.policyId));
    if (!isCheckboxActive) changeSelectedPolicies([...selectedPolicies, policy]);
  };

  const handleAttachmentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    downloadFiles(policy.attachment);
  };

  return (
    <div className="policy-notifications">

      <div className="policy-notifications__notifications">
        <div className="policy-notifications__notification-icon">
          <NotificationIcon />
          <span className="policy-notifications__notifications-count">{policy.notifications.length}</span>
        </div>
        <NotificationsTips policy={policy} notifications={policy.notifications} finishDate={policy.finishDate} />
      </div>

      <div className="policy-notifications__attach" onClick={handleAttachmentClick} role="presentation">
        {!!policy.attachment.length && <AttachIcon />}
      </div>

      {(canUserManagePolicies && !enableCheckboxes) && (
        <div onClick={(e) => e.stopPropagation()} role="presentation">
          <Link className="policy-notifications__edit" to={`${routeNames.POLICIES}/edit/${policy.policyId}`}>
            <EditIcon />
          </Link>
        </div>
      )}

      {(canUserManagePolicies && enableCheckboxes) && (
        <div onClick={(e) => e.stopPropagation()} role="presentation">
          <Checkbox onClick={handleCheckboxClick} active={isCheckboxActive} />
        </div>
      )}
    </div>
  );
};

const PolicyField: FC<FieldProps> = (props) => {
  const { config, policy, user, enableCheckboxes, selectedPolicies, changeSelectedPolicies } = props;

  const isExpired = isPolicyExpired(policy);
  const fieldClassName = classNames('policy-table-field', { 'policy-table-field--expired': isExpired });

  return (
    <ul className={fieldClassName}>
      {config.map((c) => {
        const size = getFieldSize(config.length, c.size);
        return (
          <li className="policy-table-field__item" key={c.name} style={{ width: `${size}%` }}>
            {c.name === 'Policy name' && <PolicyName policy={policy} />}
            {c.name === 'Policy number' && <PolicyNumber policy={policy} />}
            {c.name === 'Expiry date' && <PolicyExpiryDate date={policy.finishDate ? new Date(policy.finishDate) : null} />}
            {c.name === 'Contact name' && <PolicyText text={policy.contactName.length ? policy.contactName : '---'} />}
            {c.name === 'Last update' && <PolicyUpdateDate date={policy.updated_at ? new Date(policy.updated_at) : null} />}
            {c.name === 'Notes' && <PolicyNotes text={policy.notes} />}
            {c.name === 'Notifications' && (
              <PolicyNotifications
                policy={policy}
                user={user}
                enableCheckboxes={enableCheckboxes}
                selectedPolicies={selectedPolicies}
                changeSelectedPolicies={changeSelectedPolicies}
              />
            )}
            {c.name === 'Coverage amount'
              && (
                <PolicyText
                  text={coverageFormatter(policy[c.value])}
                  preventWrap
                />
              )}
          </li>
        );
      })}
    </ul>
  );
};

export default PolicyField;
