import { FC } from 'react';

import { ReactComponent as WarningIcon } from 'src/assets/icons/Expired.svg';

import Button from '../components/Button/Button';

const WarningPage: FC = () => {
  return (
    <div className="container warning-page__container">
      <WarningIcon />
      <h1>Your free trial has expired </h1>
      <p>Upgrade your plan to get access to Insurely</p>
      <Button title="Upgrade now!" />
    </div>
  );
};

export default WarningPage;
