import axios, { AxiosResponse } from 'axios';

import { mainInstanse } from 'src/axios';
import { BillingType } from 'src/types/types';

const BillingApi = {

  async fetchBillings(): Promise<AxiosResponse<BillingType[]>> {
    const res = await mainInstanse.get<BillingType[]>('/billings');
    return res;
  },

  async getPaymantLink(id: string): Promise<AxiosResponse<{ url: string }>> {
    const res = await axios.get<{ url: string }>(`${process.env.REACT_APP_API_URL}/billings/checkout/${id}`,
      { headers: { Authorization: `Bearer ${localStorage.getItem('mycomply_token')}` } });
    return res;
  },

  async unsubscribe(): Promise<AxiosResponse<unknown>> {
    const res = await axios.delete<unknown>(`${process.env.REACT_APP_API_URL}/billings/unsubscribe`,
      { headers: { Authorization: `Bearer ${localStorage.getItem('mycomply_token')}` } });
    return res;
  },
};

export default BillingApi;
