import { FC, useMemo, useState } from 'react';

import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { TwitterPicker } from 'react-color';

import { CompanyModel, CompanyFormModel } from 'src/types/types';

import Button from '../Button/Button';
import FileInput from '../FileInput/FileInput';
import Phone from '../Phone/Phone';
import Toggle from '../Toggle/Toggle';
import { validationSchema } from './validationSchema';

type CompanyFormProps = {
  title: string,
  buttonTitle: string,
  onSubmit: (company: CompanyFormModel) => void,
  company?: CompanyModel,
  isCompaniesLimitAchieved?: boolean
}

const CompanyForm: FC<CompanyFormProps> = ({ title, buttonTitle, onSubmit, children, company, isCompaniesLimitAchieved }) => {
  const [sendWeeklyWrapup, setSendWeeklyWrapup] = useState(company?.sendWeeklyWrapup);
  const [sendMonthlyWrapup, setSendMonthlyWrapup] = useState(company?.sendMonthlyWrapup);
  const [color, setColor] = useState(company?.color || '#144dde');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [logo, setLogo] = useState<File | null>({ name: company?.logo?.name || '---' } as any);
  const initialValues = {
    contactName: '',
    companyType: 'company',
    companyName: '',
    contactEmail: '',
    bccEmail: '',
    phone: '',
    logo: null,
    color: '#144dde',
    sendWeeklyWrapup: false,
    sendMonthlyWrapup: false,
  };

  const updateLogo = (setFieldValue, setFieldTouched, validateField, value: File | null) => {
    setLogo(value);
    setFieldValue('logo', value, true);
    setTimeout(() => {
      setFieldTouched('logo', true);
    });
  };

  const updatePhone = (setFieldValue, setFieldTouched, validateField, value: string) => {
    setFieldValue('phone', value, true);
    setTimeout(() => {
      setFieldTouched('phone', true);
    });
  };

  const submit = (values: CompanyFormModel) => {
    onSubmit(values);
  };

  const setInitialValues = useMemo(() => {
    if (company) {
      Object.keys(initialValues).forEach((k) => initialValues[k] = company[k]);
    }
    return initialValues;
  }, [company]);

  return (
    <Formik
      initialValues={setInitialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ setFieldValue, setFieldTouched, validateField, errors, isValid }) => {
        return (
          <Form className="form users-form">
            <h1>{title}</h1>
            <div className="form__two-blocks-container">
              <div className="form__left-block">
                <div className="form__item-block">
                  <h5>Company name</h5>
                  <Field
                    placeholder="Company name"
                    name="companyName"
                    className={classNames({ 'input-text': true, 'input-text_error': errors.companyName })}
                  />
                  <span className="form__error">
                    <ErrorMessage name="companyName" />
                  </span>
                </div>

                <div className="form__item-block">
                  <h5>Contact name</h5>
                  <Field
                    placeholder="Contact name"
                    name="contactName"
                    className={classNames({ 'input-text': true, 'input-text_error': errors.contactName })}
                  />
                  <span className="form__error">
                    <ErrorMessage name="contactName" />
                  </span>
                </div>

                <div className="form__item-block">
                  <h5>Contact email</h5>
                  <Field
                    placeholder="mail@company-name.com"
                    name="contactEmail"
                    className={classNames({ 'input-text': true, 'input-text_error': errors.contactEmail })}
                  />
                  <span className="form__error">
                    <ErrorMessage name="contactEmail" />
                  </span>
                </div>

                <div className="form__item-block">
                  <h5>BCC email</h5>
                  <Field
                    placeholder="mail@company-name.com"
                    name="bccEmail"
                    className={classNames({ 'input-text': true, 'input-text_error': errors.bccEmail })}
                  />
                  <span className="form__error">
                    <ErrorMessage name="bccEmail" />
                  </span>
                </div>

                <div className="form__item-block">
                  <Phone
                    className={classNames({ 'input-text': true, 'input-text_error': errors.phone })}
                    onChange={(value) => { updatePhone(setFieldValue, setFieldTouched, validateField, value); }}
                  />
                </div>
                <div className="form__item-block">
                  <h5>Send weekly wrap-up</h5>
                  <Toggle
                    checked={!!sendWeeklyWrapup}
                    changeHandler={() => {
                      setSendWeeklyWrapup(!sendWeeklyWrapup);
                      setFieldValue('sendWeeklyWrapup', !sendWeeklyWrapup);
                    }}
                  />
                </div>

                <div className="form__item-block">
                  <h5>Send monthly wrap-up</h5>
                  <Toggle
                    checked={!!sendMonthlyWrapup}
                    changeHandler={() => {
                      setSendMonthlyWrapup(!sendMonthlyWrapup);
                      setFieldValue('sendMonthlyWrapup', !sendMonthlyWrapup);
                    }}
                  />
                </div>

              </div>
              <div className="form__right-block">
                <div className="form__item-block">
                  <h5>Company accent color
                    <span style={{
                      backgroundColor: color,
                      width: '32px',
                      position: 'absolute',
                      marginLeft: '1em',
                      borderRadius: '12px',
                    }}
                    >&nbsp;
                    </span>
                  </h5>
                  <TwitterPicker
                    color={color}
                    triangle="hide"
                    onChangeComplete={(value) => {
                      setColor(value.hex);
                      setFieldValue('color', value.hex);
                    }}
                  />
                </div>

                <div className="form__item-block">
                  <h5>Company logo</h5>
                  <FileInput
                    onChange={(file) => updateLogo(setFieldValue, setFieldTouched, validateField, file)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    value={logo ? [logo] : []}
                  />
                  <br />
                  {(logo && !logo?.size && company?.logo?.url) && (
                    <img
                      style={{ maxWidth: '500px', maxHeight: '40px' }}
                      alt=""
                      src={`${process.env.REACT_APP_API_URL}${company?.logo?.url}`}
                    />
                  )}
                  {logo?.size && (
                    <img
                      style={{ maxWidth: '500px', maxHeight: '40px' }}
                      alt=""
                      src={URL.createObjectURL(logo)}
                    />
                  )}
                </div>
              </div>
            </div>
            {
              !isCompaniesLimitAchieved && (
                <div className="form__buttons">
                  <Button disabled={!isValid} title={buttonTitle} type="submit" />
                  {children}
                </div>
              )
            }
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyForm;
