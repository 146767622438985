import { FC, Key, useRef, useState } from 'react';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/blue-arrow.svg';

import countryCodesIndexed from '../SignUpForm/countries';

type PhoneProps = {
  onChange: (v: string) => void,
  className?: string,
}

interface Country {
  name: string;
  // eslint-disable-next-line camelcase
  dial_code: string;
  code: string;
  emoji: string;
}

const Phone: FC<PhoneProps> = ({ onChange, className }) => {
  const searchInput = useRef<HTMLInputElement>(null);
  const phoneInput = useRef<HTMLInputElement>(null);
  const [isOptionsShown, setIsOptionsShown] = useState(false);
  const [countries, setCountries] = useState(Object.values(countryCodesIndexed.byName));
  const [searchString, setSearchString] = useState('');
  const [phoneString, setPhoneString] = useState('');
  const [country, setCountry] = useState<Country>(countryCodesIndexed.byName.Australia);

  const search = (value: string) => {
    setSearchString(value);
    setCountries(
      Object.values(countryCodesIndexed.byName).filter(
        (v) => (value ? `${v.code}/${v.name}/${v.dial_code}`.toLowerCase().indexOf(value.toLowerCase()) > -1 : true),
      ),
    );
  };

  const toggleCodeSearch = () => {
    setIsOptionsShown(!isOptionsShown);
    if (!isOptionsShown) {
      setTimeout(() => searchInput.current && searchInput.current.focus(), 100);
    } else {
      setTimeout(() => phoneInput.current && phoneInput.current.focus(), 100);
    }
  };

  const onChangeCode = (country: Country) => {
    setCountry(country);
    onChange(`${country?.dial_code}${phoneString}`);
    toggleCodeSearch();
  };

  const onChangePhone = (phone: string) => {
    setPhoneString(phone);
    onChange(`${country?.dial_code}${phone}`);
  };

  return (
    <>
      <div
        role="presentation"
        className={`${className} phone-input`}
      >
        {!isOptionsShown && (
          <>
            <p
              className="phone-input__phone-code-select"
              role="presentation"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
              onFocus={toggleCodeSearch}
              onClick={toggleCodeSearch}
            >{country?.emoji} {country?.dial_code}
            </p>
            <ArrowIcon className="phone-input__phone-code-arrow" onClick={toggleCodeSearch} />
          </>
        )}
        <input
          type="text"
          className="phone-input__phone"
          ref={phoneInput}
          placeholder="123456789"
          value={phoneString}
          onChange={(e) => onChangePhone(e.target.value)}
        />

        {isOptionsShown && (
          <div className="phone-input__phone-code-dropdown">
            <input
              type="text"
              ref={searchInput}
              className="phone-input__phone-code-search"
              placeholder="Search countries"
              onBlur={() => { setTimeout(() => toggleCodeSearch(), 100); }}
              value={searchString}
              onChange={(e) => search(e.target.value)}
            />
            <ul className="phone-input__phone-code-list">
              {countries.map((option, k) => (
                <li
                  role="presentation"
                  key={k as Key}
                  className="phone-input__phone-code-list-item"
                  onClick={() => onChangeCode(option)}
                >
                  <div>{option.emoji} {option.name}</div><div>{option.dial_code}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

    </>
  );
};

export default Phone;
