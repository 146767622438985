export enum successMessages {
  addPolicy = 'You policy has been added',
  updatePolicy = 'Your policy has been updated. Notification dates will be updated automatically.',
  deletePolicy = 'Your policy has been deleted',
  addCompany = 'You company/agency has been added',
  updateCompany = 'Company has been updated',
  inviteUser = 'The invitation letter has been sent to the email',
  updateUser = 'User has been updated'
}

export enum errorMessages {
  policies = 'Something went wrong. Please reload the page',
  addPolicy = 'Something went wrong. Please try adding the policy again',
  updatePolicy = 'Something went wrong. Please try changing your policy again',
  login = 'Email or password wrong',
  register = 'Something went wrong. Please check the entered data and try again',
  addCompany = 'Something went wrong. Please try adding company again',
  updateCompany = 'Something went wrong. Please try changing company again',
  users = 'Something went wrong. Please reload the page',
  updateUser = 'Something went wrong. Please try changing user again',
  approvedPolicy = 'Something went wrong. Some policy is unsuccessfully confirmed. Please try again',
  deletePolicy = 'Something went wrong. Some policy is unsuccessfully deleted. Please try again'
}
