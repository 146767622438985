import { FC, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/Chevron.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { CompanyModel } from 'src/types/types';

type SelectCompanyProps = {
  companies: CompanyModel[],
  initialCompany: CompanyModel,
  onClick: (v: CompanyModel) => void;
}

const SelectCompany: FC<SelectCompanyProps> = ({ companies, initialCompany, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const iconClassName = classNames('form-company-select__icon', { 'form-company-select__icon--open': isOpen });
  const fieldClassName = classNames('form-company-select__field', { 'form-company-select__field--open': isOpen });

  const toggleSelect = () => setIsOpen(!isOpen);

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div className="form-company-select" onClick={toggleSelect} role="presentation" ref={ref}>
      <p className="form-company-select__label">
        Company name
      </p>

      <div className="form-company-select__field-wrapper">
        <div className={fieldClassName}>{initialCompany.companyName}</div>
        <ArrowIcon className={iconClassName} />
      </div>

      {isOpen && (
        <ul className="form-company-select__options">
          {companies.map((company) => (
            <li
              role="presentation"
              className="form-company-select__option"
              key={company.companyId}
              onClick={() => onClick(company)}
            >
              <p>{company.companyName}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectCompany;
