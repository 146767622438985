import { createSlice } from '@reduxjs/toolkit';

import { sliceNames } from 'src/constants';
import { errorMessages, successMessages } from 'src/types/enums';

import { loginUser, registerUser } from '../auth/thunk';
import { createCompany, updateCompany } from '../companies/thunk';
import { approvedPolicies, createPolicy, deletePolicies, getPolicies, updatePolicy } from '../policies/thunk';
import { getUsers, inviteUser, updateUser } from '../users/thunk';

const activitiesSlice = createSlice({
  name: sliceNames.activities,
  initialState: {
    error: '',
    message: '',
  },
  reducers: {
    clear(state) {
      state.error = '';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    // policies activities
    builder.addCase(getPolicies.fulfilled, (state) => {
      state.error = '';
    });

    builder.addCase(getPolicies.rejected, (state) => {
      state.error = errorMessages.policies;
    });

    builder.addCase(createPolicy.fulfilled, (state) => {
      state.message = successMessages.addPolicy;
      state.error = '';
    });

    builder.addCase(createPolicy.rejected, (state) => {
      state.error = errorMessages.addPolicy;
    });

    builder.addCase(updatePolicy.fulfilled, (state) => {
      state.error = '';
      state.message = successMessages.updatePolicy;
    });

    builder.addCase(updatePolicy.rejected, (state) => {
      state.error = errorMessages.updatePolicy;
    });

    // companies activities
    builder.addCase(createCompany.fulfilled, (state) => {
      state.message = successMessages.addCompany;
      state.error = '';
    });

    builder.addCase(createCompany.rejected, (state) => {
      state.error = errorMessages.addCompany;
    });

    builder.addCase(updateCompany.fulfilled, (state) => {
      state.error = '';
      state.message = successMessages.updateCompany;
    });

    builder.addCase(updateCompany.rejected, (state) => {
      state.error = errorMessages.updateCompany;
    });

    // auth activities

    builder.addCase(loginUser.rejected, (state) => {
      state.error = errorMessages.login;
    });

    builder.addCase(registerUser.rejected, (state) => {
      state.error = errorMessages.register;
    });

    // users activities

    builder.addCase(getUsers.rejected, (state) => {
      state.error = errorMessages.users;
    });

    builder.addCase(updateUser.fulfilled, (state) => {
      state.error = '';
      state.message = successMessages.updateUser;
    });

    builder.addCase(updateUser.rejected, (state) => {
      state.error = errorMessages.updateUser;
    });

    builder.addCase(inviteUser.fulfilled, (state) => {
      state.message = successMessages.inviteUser;
      state.error = '';
    });

    builder.addCase(inviteUser.rejected, (state) => {
      state.error = successMessages.inviteUser;
    });

    builder.addCase(deletePolicies.fulfilled, (state, action) => {
      if (action.payload.some((obj) => obj.status === 'rejected')) {
        state.error = errorMessages.deletePolicy;
      }
    });

    builder.addCase(approvedPolicies.fulfilled, (state, action) => {
      if (action.payload.some((obj) => obj.status === 'rejected')) {
        state.error = errorMessages.approvedPolicy;
      }
    });
  },
});

export const { clear } = activitiesSlice.actions;

export default activitiesSlice.reducer;
