import { FC, useState } from 'react';

import { Link } from 'react-router-dom';

import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { loginUser } from 'src/store/auth/thunk';
import { LoginFormValues } from 'src/types/types';

import LoginForm from '../components/LoginForm/LoginForm';

const Login: FC = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const onSubmitForm = async (values: LoginFormValues) => {
    setLoading(true);
    await dispatch(loginUser({ email: values.email, password: values.password }));
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="mb-40 login-title">Log in</h1>
      <LoginForm onSubmitForm={onSubmitForm} loading={loading} />

      <div className="form__subtitle">
        Don&apos;t have an account? &nbsp;
        <span className="form__label--blue">
          <Link to={routeNames.SIGNUP}>Sign up</Link>
        </span>
      </div>

    </div>
  );
};

export default Login;
