import { FC, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as SortIcon } from 'src/assets/icons/sort.svg';
import { ReactComponent as TipsIcon } from 'src/assets/icons/tips.svg';
import { GroupFilter } from 'src/pages/Policy/Policy';
import { OptionSize, TableConfig } from 'src/pages/Policy/TableConfig';
import { PolicyModel } from 'src/types/types';
import { sortPoliciesByDate, sortPolicies as sortPoliciesUtil } from 'src/utils/policy';

import Checkbox from '../Checkbox/Checkbox';
import Tips from './Tips';

type HeaderProps = {
  policies: PolicyModel[],
  selectedPolicies: PolicyModel[],
  config: TableConfig[],
  groupBy: GroupFilter,
  changePolicies: (p: PolicyModel[]) => void,
  changeSelectedPolicies: (p: PolicyModel[]) => void,
  enableTips?: boolean,
  enableCheckboxes: boolean
}

const getFieldSize = (totalLength: number, size: OptionSize) => 100 / totalLength / size;

const Header: FC<HeaderProps> = (props) => {
  const {
    config,
    enableTips,
    changePolicies,
    policies,
    groupBy,
    enableCheckboxes,
    selectedPolicies,
    changeSelectedPolicies,
  } = props;

  const valuesToSort = ['name', 'policyNumber', 'finishDate', 'updated_at'];
  const [sortKey, setSortKey] = useState(valuesToSort[0]);
  const headerClassName = classNames('policy-table-header', { 'policy-table-header--grouped': groupBy !== GroupFilter.none });

  const sortIconClassName = (value: string) => {
    return classNames('policy-table-header__sort-icon', { 'policy-table-header__sort-icon--active': value === sortKey });
  };

  const sortPolicies = (key: string) => {
    if (!valuesToSort.includes(key)) return;
    let sortedPolicies: PolicyModel[] = [];

    if (key === 'finishDate' || key === 'updated_at') {
      sortedPolicies = sortPoliciesByDate(policies, key, sortKey === key);
    } else {
      sortedPolicies = sortPoliciesUtil(policies, key, sortKey === key);
    }

    changePolicies(sortedPolicies);
    setSortKey(sortKey === key ? '' : key);
  };

  const handleCheckboxClick = () => {
    if (selectedPolicies.length === policies.length) changeSelectedPolicies([]);
    if (selectedPolicies.length !== policies.length) changeSelectedPolicies(policies);
  };

  return (
    <>
      <ul className={headerClassName}>
        {config.map((c, idx) => {
          const size = getFieldSize(config.length, c.size);

          if (c.checked && c.value !== 'notifications') {
            return (
              <li style={{ width: `${size}%` }} key={c.name} className="policy-table-header__field">
                {(enableTips && idx === 0) && (
                  <>
                    <TipsIcon className="policy-table-header__tips-icon" />
                    <Tips />
                  </>
                )}
                <p onClick={() => sortPolicies(c.value)} role="presentation">
                  {c.name}
                  {valuesToSort.includes(c.value) && <SortIcon className={sortIconClassName(c.value)} />}
                </p>
              </li>
            );
          }

          return undefined;
        })}
        {enableCheckboxes && (
          <div
            className="policy-table-header__checkbox"
            style={{ width: `${getFieldSize(config.length, config[config.length - 1].size)}%` }}
          >
            <Checkbox
              onClick={handleCheckboxClick}
              active={selectedPolicies.length === policies.length && !!policies.length}
            />
          </div>
        )}
      </ul>
    </>
  );
};

export default Header;
