import { filters } from 'src/constants';
import { PolicyModel } from 'src/types/types';

export const getFilteredPolicies = (policies: PolicyModel[], filter: string, email?: string): PolicyModel[] => {
  switch (filter) {
    case filters.expiresNextMonth:
      return policies.filter((policy) => new Date(policy.finishDate).getMonth() - new Date().getMonth() === 1);
      break;

    case filters.myPolicies:
      return policies.filter((policy) => (email ? policy.emailToNotify.indexOf(email) : false));
      break;

    case filters.expiresThisMonth:
      return policies.filter((policy) => new Date(policy.finishDate).getMonth() - new Date().getMonth() === 0);
      break;
    default: return policies;
  }
};
