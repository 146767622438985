import * as yup from 'yup';

import { phoneRegExp } from 'src/constants';

export const signUpValidationSchema = yup.object({
  companyName: yup.string().min(3, 'Must be at least 3 characters').required('Please check your company name'),
  email: yup.string().email('Invalid email address').required('Please check your email'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Please enter your phone'),
  password: yup
    .string()
    .min(5, 'Password is too short - should be 5 characters minimum')
    .required('Please enter your password')
    .matches(/[a-zA-Z]/, 'Password must contain letters and numbers'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match').required('Please confirm password'),
});

export const signUpByInviteValidationSchema = yup.object({
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Please enter your phone'),
  password: yup
    .string()
    .min(5, 'Password is too short - should be 5 characters minimum')
    .required('Please enter your password')
    .matches(/[a-zA-Z]/, 'Password must contain letters and numbers'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match').required('Please confirm password'),
});
