import { FC } from 'react';

import classNames from 'classnames';

import { ReactComponent as CheckIcon } from 'src/assets/icons/checkbox-icon.svg';

import Spinner from '../Spinner/Spinner';

interface ButonValues {
  title: string;
  type?: 'submit' | 'button';
  className?: string,
  appearance?: '' | 'transparent' | 'label',
  onClick?: () => void,
  disabled?: boolean,
  loading?: boolean,
  done?: boolean
}

const Button: FC<ButonValues> = ({
  title,
  type = 'button',
  children,
  appearance,
  onClick,
  disabled,
  className,
  loading,
  done,
}) => {
  const buttonClassName = classNames('button', {
    button____transparent: appearance === 'transparent',
    button____disabled: disabled,
    button____label: appearance === 'label',
    button____button: !appearance,
    button__loading: loading,
  });

  return (
    <button
      className={`${buttonClassName} ${className || ''}`}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <span className="button__text">
        {title}
      </span>
      {loading && (
        <Spinner visible />
      )}
      {done && (
        <CheckIcon />
      )}

    </button>
  );
};

export default Button;
