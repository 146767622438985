/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as ColumnIcon } from 'src/assets/icons/column.svg';
import { ReactComponent as EditorIcon } from 'src/assets/icons/Customise2.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { TableConfig } from 'src/pages/Policy/TableConfig';

import Button from '../Button/Button';
import Toggle from '../Toggle/Toggle';

type TableEditorProps = {
  title: string,
  className?: string,
  values: TableConfig[],
  onChange: (v: TableConfig[]) => void
}

type EditorFieldProps = {
  value: TableConfig,
}

const EditorField: FC<EditorFieldProps> = ({ value }) => {
  return (
    <div className="editor-field">
      <div className="editor-field__name">
        <ColumnIcon className="editor-field__icon" />
        <p>{value.name}</p>
      </div>
      <Toggle checked={value.checked} />
    </div>
  );
};

const TableEditor: FC<TableEditorProps> = ({ title, className, values, onChange }) => {
  const ref = useRef(null);

  const [isSetingsShown, setSettingsShown] = useState(false);
  const [settingsValues, setSettingsValues] = useState(values);

  const editorClassName = classNames('table-editor', { [className || '']: className });

  const toggleSettings = () => setSettingsShown(!isSetingsShown);

  const toggleValue = (valueName: string) => {
    const updatedValues = settingsValues.map((v) => (v.name === valueName ? { ...v, checked: !v.checked } : v));
    setSettingsValues(updatedValues);
  };

  const reset = () => {
    const resetedValues = values.map((v) => (v.customise ? { ...v, checked: v.default } : v));
    setSettingsValues(resetedValues);
  };

  useOutsideClick(ref, () => setSettingsShown(false));

  return (
    <div className={editorClassName} ref={ref}>
      <div className="table-editor__field" onClick={toggleSettings} role="presentation">
        <p>{title}</p>
        <EditorIcon />
      </div>

      {isSetingsShown && (
        <div className="table-editor__settings">
          <div className="table-editor__header">
            <h4 className="table-editor__settings-title">Customise table</h4>
            <p className="table-editor__reset" onClick={reset} role="presentation">Restore defaults</p>
          </div>
          <ul className="table-editor__fields">
            {settingsValues.map((value, idx) => {
              return !value.customise ? undefined
                : (
                  <li
                    key={value.name + idx.toString()}
                    className="table-editor__field-item"
                    onClick={() => toggleValue(value.name)}
                    role="presentation"
                  >
                    <EditorField value={value} />
                  </li>
                );
            })}
          </ul>
          <Button title="Apply" onClick={() => { onChange(settingsValues); setSettingsShown(false); }} />
        </div>
      )}
    </div>
  );
};

export default TableEditor;
