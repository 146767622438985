/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC } from 'react';

import classNames from 'classnames';

import Toggle from '../Toggle/Toggle';

type InputProps = {
  label: string,
  placeholder?: string,
  value: string,
  isValid?: boolean,
  errorMessage?: string,
  extraClassName?: string,
  toggleTitle?: string,
  toggler?: boolean,
  checked?: boolean,
  togglerHandleClick?: () => void,
  onChange: (v) => void
}

const Input: FC<InputProps> = ({
  label,
  placeholder,
  value,
  extraClassName,
  onChange,
  isValid = true,
  errorMessage = '',
  toggler,
  checked = false,
  toggleTitle = '',
  togglerHandleClick,
}) => {
  const className = extraClassName
    ? classNames(`form-input ${extraClassName}`, { 'form-input--invalid': !isValid })
    : classNames('form-input', { 'form-input--invalid': !isValid });

  return (
    <div className={className}>
      <div className="form-input__label-toggle">
        <label className="form-input__label">{label}</label>
        {toggler && (
          <div className="form-input__price-toggler">
            <Toggle
              checked={checked}
              changeHandler={togglerHandleClick}
            />
            <span className="form-input__toggler-text">{toggleTitle}</span>
          </div>
        )}
      </div>
      <input
        className="form-input__field"
        placeholder={placeholder || ''}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <p className="form-input__error-message">{!isValid && errorMessage}</p>
    </div>
  );
};

export default Input;
