import { FC, useRef, useState } from 'react';

import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';

import Button from 'src/components/Button/Button';
import { ForgotPasswordFormValues } from 'src/types/types';

import { validationSchema } from './validationSchema';

const initialValues: ForgotPasswordFormValues = {
  email: '',
};

type ForgotPasswordProps = {
  disableButton?: boolean,
  onSubmitForm: (values: ForgotPasswordFormValues) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordProps> = ({ onSubmitForm, disableButton }) => {
  const ref = useRef<FormikProps<ForgotPasswordFormValues>>(null);

  const [loading, setLoading] = useState(false);

  const submit = (values: ForgotPasswordFormValues) => {
    setLoading(true);
    onSubmitForm(values);
  };

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur={false}
      onSubmit={submit}
    >
      {({ errors }) => (
        <Form className="form">
          <div className="form__input-box">
            <div className="form__item-block">
              <h5>Email</h5>
              <Field
                className={classNames({ 'input-text': true, 'input-text_error': errors.email })}
                name="email"
                placeholder="mail@company-name.com"
              />
              <span className="form__error">
                <ErrorMessage name="email" />
              </span>
            </div>
          </div>

          <Button
            title="Request reset email"
            type="submit"
            disabled={disableButton}
            className={disableButton ? 'disabled' : ''}
            loading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
