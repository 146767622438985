import { FC } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as HistoryIcon } from 'src/assets/icons/HistoryArrow.svg';

type Props = {
  link: string,
  linkedPageTitle: string,
  currentPageTitle: string
}

// TODO: replace component woth breadcrumbs

const HistoryBlock: FC<Props> = ({ link, linkedPageTitle, currentPageTitle }) => {
  return (
    <div className="history">
      <span className="history__link"><Link to={link}>{linkedPageTitle}</Link></span>
      <HistoryIcon />
      <span>{currentPageTitle}</span>
    </div>
  );
};

export default HistoryBlock;
