import Axios, { AxiosInstance, AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

type MainInstanse = AxiosInstance & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multipartPost: <T extends any>(method: string, url: string, data: any) => Promise<AxiosResponse<T>>
}

export const mainInstanse = Axios.create({
  baseURL: API_URL,
}) as MainInstanse;

mainInstanse.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem('mycomply_token')}`;
  return config;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
mainInstanse.multipartPost = <T extends any>(method: string, url: string, data: any): Promise<AxiosResponse<T>> => {
  return mainInstanse[method](url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const authInstanse = Axios.create({
  baseURL: `${API_URL}/auth/local`,
});
