/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/blue-arrow.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/blue-plus.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/Delete.svg';
import useOutsideClick from 'src/hooks/useOutsideClick';

import Input from '../Input/Input';

type NotificationsPickerProps = {
  className?: string,
  limit: number,
  values: number[],
  daysBeforeFinishDate: number[],
  onChange: (v: number[]) => void
}

type OptionsProps = Omit<NotificationsPickerProps, 'limit'> & { value: string, idx: number, }

const Options: FC<OptionsProps> = ({ daysBeforeFinishDate, values, onChange, value, idx }) => {
  const ref = useRef(null);
  const [val, setVal] = useState(value.toString());
  const changeNotification = (d: number) => onChange([...values.slice(0, idx), d, ...values.slice(idx + 1)]);
  const deleteNotification = () => onChange([...values.slice(0, idx), ...values.slice(idx + 1)]);

  return (
    <li className="notifications-picker__notification" ref={ref}>
      <div className="notifications-picker__select">
        <div className="notifications-picker__select-wrapper">
          <div
            className="notifications-picker__select-input"
          >
            <input
              className="form-input__field"
              placeholder="days"
              value={val}
              onBlur={(e) => changeNotification(parseInt(e.target.value, 10) || 3)}
              onChange={(e) => (setVal(e.target.value))}
            />
          </div>
          <p className="notifications-picker__select-placeholder">days before the Expiry date</p>
          <DeleteIcon
            onClick={deleteNotification}
            className="notifications-picker__delete-icon"
            role="presentation"
          />
        </div>
      </div>
    </li>
  );
};

const NotificationsPicker: FC<NotificationsPickerProps> = ({ className, values, daysBeforeFinishDate, onChange, limit }) => {
  const availableDays = daysBeforeFinishDate.filter((d) => !values.includes(d));
  const addNotification = () => onChange([...values, (availableDays[0] || 1)]);

  return (
    <div className={`notifications-picker ${className}`}>
      {!!limit && <p className="notifications-picker__label">Notifications</p>}

      <ul className="notifications-picker__notifications-list">
        {values.map((v, idx) => (
          <Options
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            daysBeforeFinishDate={availableDays}
            onChange={onChange}
            value={v.toString()}
            values={values}
            idx={idx}
          />
        ))}
      </ul>

      {(values.length < limit && !!availableDays.length) && (
        <div
          className="notifications-picker__add-notifications"
          role="presentation"
          onClick={addNotification}
        >
          <PlusIcon className="notifications-picker__plus-icon" />
          <p>Add notification</p>
        </div>
      )}
    </div>
  );
};

export default NotificationsPicker;
