/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

import classNames from 'classnames';

type Props = {
  changeHandler?: (v: any) => void;
  checked: boolean
}

const Toggle: FC<Props> = ({ changeHandler, checked }) => {
  const inputClassName = classNames('toggle', { 'toggle--active': checked });
  return (
    <div className={inputClassName} onClick={changeHandler} role="presentation">
      <div className="toggle__input" />
      <span className="toggle__slider" />
    </div>
  );
};

export default Toggle;
