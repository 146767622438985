import { SignFormValues, SignUpUserModel } from 'src/types/types';

export const serializeUser = (values: SignFormValues): SignUpUserModel => {
  return {
    email: values.email,
    password: values.password,
    companyType: values.category,
    companyName: values.companyName,
    phone: values.phone,
  };
};
