import { FC, useEffect, useState } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { format, differenceInDays } from 'date-fns';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Attach } from 'src/assets/icons/Attach.svg';
import Button from 'src/components/Button/Button';
import HistoryBlock from 'src/components/History/HistoryBlock';
import PolicyStatus from 'src/components/PoliciesForm/PolicyStatus';
import Spinner from 'src/components/Spinner/Spinner';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectPolicies } from 'src/store/policies/selectors';
import { deletePolicy, getNotifications, getPolicyById, sendNotification } from 'src/store/policies/thunk';
import { downloadFiles } from 'src/utils/browser';
import { coverageFormatter } from 'src/utils/formatters';

const PolicyInfoPage: FC = () => {
  const dispatch = useAppDispatch();
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { policy } = useSelector(selectPolicies);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { policyNotifications } = useSelector(selectPolicies);

  const onDelete = async () => {
    const action = await dispatch(deletePolicy({ id }));
    if (isFulfilled(action)) navigate(routeNames.POLICIES);
  };

  const handleSendNow = async () => {
    setLoading(true);
    setDone(false);
    await dispatch(sendNotification({ id: policy.policyId }));
    setLoading(false);
    setDone(true);
    dispatch(getNotifications({ id }));
  };

  const formatDate = (date: string) => {
    return format(new Date(date), 'd MMM y hh:mm');
  };

  useEffect(() => {
    dispatch(getPolicyById({ id }));
    dispatch(getNotifications({ id }));
  }, [dispatch, id]);

  return (
    <div className="policy-info">
      {!Object.keys(policy).length
        && <Spinner visible />}
      {!!Object.keys(policy).length
        && (
          <>
            <HistoryBlock link={routeNames.POLICIES} linkedPageTitle="Policies" currentPageTitle={policy.name} />
            <div className="policy-info__title">
              <h1>{policy.name}</h1>
              <div className="policy-info__title__buttons-block">
                <Button title="Delete policy" appearance="transparent" onClick={onDelete} />
                <Button title="Edit Policy" onClick={() => navigate(`${routeNames.POLICIES}/edit/${id}`)} />
              </div>
            </div>
            {policy.finishDate && <PolicyStatus date={policy.finishDate} />}
            <div className="policy-info__info">
              <div className="policy-info__info-row">
                <p>Policy number</p>
                <span>{policy.policyNumber}</span>
              </div>

              <div className="policy-info__info-row">
                <p>Contact name</p>
                <span>{policy.contactName}</span>
              </div>

              <div className="policy-info__info-row">
                <p>Email to notify</p>
                <span>{policy.emailToNotify.join(', ')}</span>
              </div>

              {policy.finishDate && (
                <div className="policy-info__info-row">
                  <p>Expiry date</p>
                  <span>{format(new Date(policy.finishDate), 'd MMM y hh:mm aaa')}</span>
                </div>
              )}

              <div className="policy-info__info-row">
                <p>Coverage amount</p>
                <span>{coverageFormatter(policy.coverageAmount)}</span>
              </div>

              <div className="policy-info__info-row">
                <p>Notifications</p>
                <div className="policy-info__notifications">
                  {policy.notifications.map((item) => (
                    <span
                      key={item}
                    >{differenceInDays(new Date(policy.finishDate), new Date(item))} days before the Expiry date
                    </span>
                  ))}
                  <span role="presentation" onClick={handleSendNow}>
                    <Button appearance="label" title="Send now" loading={loading} done={done} />
                  </span>
                </div>
              </div>

              <div className="policy-info__info-row">
                <p>Notifications log</p>
                <div className="policy-info__notifications">
                  {policyNotifications.map((item) => (
                    <span
                      title={item.published_at}
                      key={item.published_at}
                    >
                      {!!item.sender
                        && (
                          <>Reminder sent
                            by <b>{item.sender.name}</b>&nbsp;
                            on <b>{formatDate(item.published_at)}</b>&nbsp;
                            to {item.email}
                          </>
                        )}
                      {!item.sender
                        && (
                          <>Reminder sent
                            on <b>{formatDate(item.published_at)}</b>&nbsp;
                            to {item.email}
                          </>
                        )}
                    </span>
                  ))}
                </div>
              </div>

              <div className="policy-info__info-row">
                <p>Notes</p>
                <span>{policy.notes}</span>
              </div>

              <div className="policy-info__info-row">
                <p>Attachment</p>
                {Object.keys(policy.attachment).length > 0
                  ? (
                    <span
                      className="policy-info__attachment"
                      onClick={() => downloadFiles(policy.attachment)}
                      role="presentation"
                    >
                      <Attach />{policy.attachment[0].name}
                    </span>
                  )
                  : null}
              </div>

              <div className="policy-info__buttons-block policy-info__mobile-block">
                <Button title="Edit Policy" onClick={() => navigate(`${routeNames.POLICIES}/edit/${id}`)} />
                <Button title="Delete policy" appearance="transparent" onClick={onDelete} />
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default PolicyInfoPage;
