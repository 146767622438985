import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as DeleteIcon } from 'src/assets/icons/Delete.svg';

type Props = {
  text: string,
  success?: boolean,
  error?: boolean,
  onClear: () => void
}

const Snackbar: FC<Props> = ({ text, error, success, onClear }) => {
  const [hidden, setHidden] = useState(false);

  const onClick = () => {
    setHidden(true);
    onClear();
  };

  useEffect(() => {
    setTimeout(() => {
      setHidden(true);
      onClear();
    }, 3000);
  }, [success, onClear]);

  return (
    <div className={classNames({
      'fetch-status-popup': true,
      'fetch-status-popup_error': error,
      'fetch-status-popup_success': success,
      hidden,
    })}
    >
      <span>{text}</span>
      {!success && <DeleteIcon onClick={onClick} />}
    </div>
  );
};

export default Snackbar;
