import { mycomplyToken } from 'src/constants';

export const setToken = (token: string): void => {
  localStorage.setItem(mycomplyToken, token);
};

export const removeToken = (): void => {
  localStorage.removeItem(mycomplyToken);
};

export const getToken = (): string | null => {
  return localStorage.getItem('mycomply_token');
};
