import { createSlice } from '@reduxjs/toolkit';

import { sliceNames } from 'src/constants';
import { FilterType, PolicyModel, PolicyNotificationModel } from 'src/types/types';

import {
  approvedPolicies,
  createPolicy, deletePolicies, deletePolicy, getNotifications, getPolicies, getPolicyById, updatePolicy,
} from './thunk';

const policiesSlice = createSlice({
  name: sliceNames.policies,
  initialState: {
    policies: [] as PolicyModel[],
    reviewingPolicies: [] as PolicyModel[],

    policy: {} as PolicyModel,
    policyNotifications: [] as PolicyNotificationModel[],
    loading: false,

    policyPageFilterBy: '',
    policyPageCustomFilter: {} as FilterType,
    policyPageGroupedBy: '',

    reviewingPageFilterBy: '',
    reviewingPageCustomFilter: {} as FilterType,
    reviewingPageGroupedBy: '',

    policyOwnersEmail: '',

    pages: [] as number[],
    currentPage: 1,
    reviewingCurrentPage: 1,
  },
  reducers: {

    // sorting for policy page

    setFilters(state, { payload }) {
      state.policyPageFilterBy = payload;
      state.currentPage = 1;
    },

    setCustomFilter(state, { payload }) {
      state.policyPageFilterBy = '';
      state.policyPageCustomFilter = payload;
      state.currentPage = 1;
    },

    setGroupedBy(state, { payload }) {
      state.policyPageGroupedBy = payload;
      state.currentPage = 1;
    },

    // sorting for reviewing page

    setReviewingFilters(state, { payload }) {
      state.reviewingPageFilterBy = payload;
      state.reviewingCurrentPage = 1;
    },

    setReviewingCustomFilter(state, { payload }) {
      state.reviewingPageFilterBy = '';
      state.reviewingPageCustomFilter = payload;
      state.reviewingCurrentPage = 1;
    },

    setReviewingGroupedBy(state, { payload }) {
      state.reviewingPageGroupedBy = payload;
      state.reviewingCurrentPage = 1;
    },

    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },

    setReviewingCurrentPage(state, { payload }) {
      state.reviewingCurrentPage = payload;
    },
  },
  extraReducers: (builder) => {
    // get policies
    builder.addCase(getPolicies.fulfilled, (state, action) => {
      state.policy = {} as PolicyModel;
      state.policies = action.payload;
      state.reviewingPolicies = action.payload.filter((policy) => !policy.approved);
    });

    // create policy
    builder.addCase(createPolicy.fulfilled, (state, action) => {
      state.policies.push(action.payload);
    });

    builder.addCase(getPolicyById.fulfilled, (state, action) => {
      state.policy = action.payload;
    });

    builder.addCase(deletePolicy.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deletePolicy.fulfilled, (state, action) => {
      state.loading = false;
      state.policies = state.policies.filter((policy) => policy.policyId !== state.policy.policyId);
      state.policy = action.payload;
    });

    // update policy

    builder.addCase(updatePolicy.fulfilled, (state, action) => {
      state.policies = state.policies.map((policy) => (policy.policyId === action.payload.policyId
        ? action.payload : policy));
      state.policy = {} as PolicyModel;
    });

    // delete policies

    builder.addCase(deletePolicies.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deletePolicies.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.policies = state.policies
        .filter((policy, id) => !(payload[id]?.status === 'fulfilled'));
    });

    // approved policies

    builder.addCase(approvedPolicies.fulfilled, (state, { payload }) => {
      state.policies = state.policies.filter((policy) => !payload.map((obj) => obj.status === 'fulfilled'
        && obj.value.policyId)
        .includes(policy.policyId));
    });

    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.policyNotifications = action.payload;
    });
  },
});

export const {
  setFilters,
  setCustomFilter,
  setGroupedBy,
  setReviewingFilters,
  setReviewingCustomFilter,
  setReviewingGroupedBy,
  setCurrentPage,
  setReviewingCurrentPage,
} = policiesSlice.actions;

export default policiesSlice.reducer;
