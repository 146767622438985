import * as yup from 'yup';

export const validationSchema = yup.object({
  password: yup
    .string()
    .min(5, 'Password is too short - should be 5 characters minimum')
    .required('Please enter your password')
    .matches(/[a-zA-Z]/, 'Password must contain letters and numbers'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match').required('Please confirm password'),
});
