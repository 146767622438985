import { FC, useEffect } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import HistoryBlock from 'src/components/History/HistoryBlock';
import UsersForm from 'src/components/UsersForm/UsersForm';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getAllCompanies } from 'src/store/companies/thunk';
import { selectUsers } from 'src/store/users/selectors';
import { getUserById, updateUser, deleteUser } from 'src/store/users/thunk';
import { clearUser } from 'src/store/users/userSlice';
import { UpdateUserModel } from 'src/types/types';

const EditUserPage: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUsers).fetchUser;
  const { me, users } = useSelector(selectUsers);
  const { myCompany, companies } = useSelector(selectCompanies);

  const editUser = async (values: UpdateUserModel) => {
    const updatedUser = {
      email: values.email,
      role: values.role,
      fullName: values.fullName,
      username: values.username,
      phone: values.phone,
      companyId: values.companyId,
    };
    const action = await dispatch(updateUser({ user: updatedUser, id }));
    if (isFulfilled(action)) navigate(routeNames.MANAGE_USERS);
  };

  const handleDeleteUser = async () => {
    const action = await dispatch(deleteUser(id));
    if (isFulfilled(action)) navigate(routeNames.MANAGE_USERS);
  };

  useEffect(() => {
    dispatch(getUserById({ id }));
    dispatch(getAllCompanies);
    return () => {
      dispatch(clearUser());
    };
  }, [dispatch, id]);

  return (
    <div className="companies-form__container">
      {Object.keys(user).length !== 0
        && (
          <>
            <HistoryBlock link={routeNames.MANAGE_USERS} currentPageTitle="Edit user" linkedPageTitle="Manage users" />
            {myCompany && (
              <UsersForm
                me={me}
                handleSubmit={editUser}
                user={user}
                company={myCompany}
                companies={companies}
                onDeleteClick={handleDeleteUser}
                usersAmount={users.length}
              />
            )}
          </>
        )}
    </div>
  );
};

export default EditUserPage;
