import { FC } from 'react';

import { format } from 'date-fns';
import DatePicker from 'react-datepicker';

import { ReactComponent as CalendarIcon } from 'src/assets/icons/Calendar.svg';

type DateFieldProps = {
  date: Date | null,
  minDate?: Date | null,
  placeholder: string,
  onChange(d: Date): void
}

const DateField: FC<DateFieldProps> = ({ date, onChange, placeholder, minDate }) => {
  const dateInput = (
    <div className="date-field__input">
      <CalendarIcon className="date-field__calendar-icon" />
      <input
        className="date-field__field"
        placeholder={placeholder}
        disabled
        value={date ? format(date, 'dd MMM yyy') : ''}
      />
    </div>
  );

  return (
    <div className="date-field">
      <DatePicker
        selected={date}
        onChange={(d: Date) => onChange(d)}
        customInput={dateInput}
        minDate={minDate || null}
      />
    </div>
  );
};

export default DateField;
