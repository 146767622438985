/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { FC, useCallback, useEffect, useState } from 'react';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { ReactComponent as PlusIcon } from 'src/assets/icons/blue-plus.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/Delete.svg';
import { CompanyModel } from 'src/types/types';

import DateSelector from '../DateSelector/DateSelector';
import FileInput from '../FileInput/FileInput';
import CurrencyInput from '../Input/CurrencyInput';
import Input from '../Input/Input';
import NotificationsPicker from '../NotificationsPicker/NotificationsPicker';
import SelectCompany from '../SelectCompany/SelectCompany';
import Textarea from '../Textarea/Textarea';
import Toggle from '../Toggle/Toggle';

export type PolicyFormValues = {
  name: string,
  policyNumber: string,
  contactName: string,
  emailToNotify: string[],
  company: CompanyModel,
  coverageAmount: string,
  file?: File,
  finishDate: null | Date,
  notifications: Date[],
  notes: string,
  attachment: File[]
}

type PolicyFormProps = {
  values: PolicyFormValues,
  companies: CompanyModel[],
  onChange: (v: PolicyFormValues) => void,
  submit: () => void
}

const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PolicyForm: FC<PolicyFormProps> = ({ values, onChange, companies, children, submit }) => {
  const rules = {
    name: { isValid: true, validate: (v: string) => !!v.length, message: 'Policy name is required field' },
    policyNumber: { isValid: true, validate: (v: string) => !!v.length, message: 'Policy number is required field' },
    contactName: { isValid: true, validate: (v: string) => !!v.length, message: 'Contact name is required field' },
    emailToNotify: {
      isValid: true,
      isValidArray: [] as boolean[],
      validate: (v: string[]) => v.map((e) => (!!e.trim() && email.test(e))).every((v) => v),
      validateArray: (v: string[]) => v.map((e) => (!!e.trim() && email.test(e))),
      message: 'Provided email has invalid format',
    },
    finishDate: { isValid: true },
    notifications: { isValid: true },
    attachment: { isValid: true },
  };

  const [isFinishDateDisabled, setFinishDateDisabled] = useState(!values.finishDate);
  const [validationRules, setValidationRules] = useState(rules);
  const [daysBeforeExpireDate, setDaysBeforeExpireDate] = useState<number[]>([]);
  const [textCurrency, setTextCurrency] = useState(false);
  const [currencyPriceToggle, setCurrencyPriceToggle] = useState(true);

  const notificationsLimit = (() => {
    if (values.finishDate) {
      const diff = differenceInCalendarDays(new Date(values.finishDate), new Date());
      if (diff > 0 && diff < 4) return diff - 1;
      if (diff >= 4) return 3;
      return 0;
    }
    return 0;
  })();

  const daysBeforeFinishDate = (() => {
    if (values.finishDate) {
      const initialDays = [1, 3, 7, 14];
      const diff = differenceInCalendarDays(new Date(values.finishDate), new Date());
      return initialDays.filter((d) => d < diff);
    }
    return [];
  })();

  const handlePolicyNameChange = (name: string) => {
    setValidationRules({
      ...validationRules,
      name: { ...validationRules.name, isValid: validationRules.name.validate(name) },
    });
    onChange({ ...values, name });
  };

  const handlePolicyNumber = (policyNumber: string) => {
    setValidationRules({
      ...validationRules,
      policyNumber: {
        ...validationRules.policyNumber,
        isValid: validationRules.policyNumber.validate(policyNumber),
      },
    });
    onChange({ ...values, policyNumber });
  };

  const handleContactName = (contactName: string) => {
    setValidationRules({
      ...validationRules,
      contactName: {
        ...validationRules.contactName,
        isValid: validationRules.contactName.validate(contactName),
      },
    });
    onChange({ ...values, contactName });
  };

  const handleEmail = (emailToNotify: string, index: number) => {
    const emails = [...values.emailToNotify];
    emails[index] = emailToNotify;
    setValidationRules({
      ...validationRules,
      emailToNotify: {
        ...validationRules.emailToNotify,
        isValidArray: validationRules.emailToNotify.validateArray(emails),
        isValid: validationRules.emailToNotify.validate(emails),
      },
    });
    onChange({ ...values, emailToNotify: emails });
  };

  const addEmail = () => {
    const emails = [...values.emailToNotify, ''];
    setValidationRules({
      ...validationRules,
      emailToNotify: {
        ...validationRules.emailToNotify,
        isValidArray: validationRules.emailToNotify.validateArray(emails),
        isValid: validationRules.emailToNotify.validate(emails),
      },
    });
    onChange({ ...values, emailToNotify: emails });
  };

  const deleteEmail = (index: number) => {
    const emails = [...values.emailToNotify];
    emails.splice(index, 1);
    const valid = validationRules.emailToNotify.validate(emails);
    setValidationRules({
      ...validationRules,
      emailToNotify: {
        ...validationRules.emailToNotify,
        isValidArray: validationRules.emailToNotify.validateArray(emails),
        isValid: validationRules.emailToNotify.validate(emails),
      },
    });
    onChange({ ...values, emailToNotify: emails });
  };

  const handleCompanyChange = (company: CompanyModel) => onChange({ ...values, company });
  const handleFileChange = (file: File | null) => onChange({ ...values, attachment: file ? [file] : [] });
  const handleNotificationsChange = (notifications: Date[]) => onChange({ ...values, notifications });
  const handleChangeNotes = (notes: string) => onChange({ ...values, notes });

  const handleDaysBeforeExpireDateChange = (days: number[]) => {
    const { finishDate } = values;

    if (finishDate) {
      setDaysBeforeExpireDate(days);

      const dates = days.map((d) => {
        const date = new Date(finishDate);
        date.setDate(date.getDate() - d);
        return date;
      });

      handleNotificationsChange(dates);
    }
  };

  const handleFinishDateChange = (date: Date) => {
    const notifications = daysBeforeExpireDate.map((d) => {
      const newNotificationDate = new Date(date);
      newNotificationDate.setDate(newNotificationDate.getDate() - d);
      return newNotificationDate;
    }).filter((n) => differenceInCalendarDays(new Date(n), new Date()) > 0);

    onChange({ ...values, finishDate: date, notifications });
  };

  const handleCoverageAmount = (coverageAmount: string) => {
    onChange({
      ...values,
      coverageAmount,
    });
  };

  const deleteFinishDate = () => {
    setFinishDateDisabled(!isFinishDateDisabled);
    onChange({ ...values, finishDate: null, notifications: [] });
  };

  const validateForm = () => {
    const updatedRules = { ...validationRules };

    Object.keys(validationRules).forEach((rule) => {
      if (validationRules[rule].validate) {
        updatedRules[rule].isValid = validationRules[rule].validate(values[rule]);
      }
    });

    setValidationRules(validationRules);

    const anyInvalidRule = !!Object.values(updatedRules).find((rule) => !rule.isValid);
    return !anyInvalidRule;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) submit();
  };

  const notificationsToDays = useCallback(() => {
    const { finishDate } = values;

    if (finishDate) {
      const days = values.notifications.map((d) => differenceInCalendarDays(finishDate, d));
      setDaysBeforeExpireDate(days);
    }
  }, [values]);

  const togglerHandleClick = () => {
    setTextCurrency(!textCurrency);
  };

  useEffect(() => {
    notificationsToDays();
  }, [notificationsToDays]);

  useEffect(() => {
    if (Number.isNaN(Number(values.coverageAmount.replaceAll(',', '')))) {
      setTextCurrency(true);
    }
  }, [values]);

  return (
    <form className="policy-form" onSubmit={handleSubmit}>
      <div className="policy-form__wrapper">
        <Input
          label="Policy name"
          placeholder="Name"
          value={values.name}
          onChange={handlePolicyNameChange}
          isValid={validationRules.name.isValid}
          errorMessage={validationRules.name.message}
        />
        <Input
          label="Policy number"
          placeholder="Number"
          value={values.policyNumber}
          onChange={handlePolicyNumber}
          isValid={validationRules.policyNumber.isValid}
          errorMessage={validationRules.policyNumber.message}
        />
        <Input
          label="Contact name"
          placeholder="Name"
          value={values.contactName}
          onChange={handleContactName}
          isValid={validationRules.contactName.isValid}
          errorMessage={validationRules.contactName.message}
        />
        <div className="form-input policy-form__emails">
          <p className="form-input__label">Emails to notify</p>
          <ul className="policy-form__email-list">
            {values.emailToNotify.map((e, i) => (
              <div
                className="policy-form__email-item"
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                <Input
                  label=""
                  placeholder="mail@company-name.com"
                  value={e}
                  onChange={(n) => handleEmail(n, i)}
                  isValid={validationRules.emailToNotify.isValidArray[i]}
                  extraClassName="form-input__email"
                />
                {i !== 0
                  && (
                    <DeleteIcon
                      onClick={() => deleteEmail(i)}
                      className="policy-form__email-delete-icon"
                      role="presentation"
                    />
                  )}
              </div>
            ))}
          </ul>
          {!validationRules.emailToNotify.isValid && <p className="form-input__error-message">{validationRules.emailToNotify.message}</p>}

          <div
            className="policy-form__add-email"
            role="presentation"
            onClick={addEmail}
          >
            <PlusIcon className="notifications-picker__plus-icon" />
            <p>Add email</p>
          </div>
        </div>

        <SelectCompany
          companies={companies}
          initialCompany={values.company}
          onClick={(v) => handleCompanyChange(v)}
        />
        {textCurrency
          ? (
            <Input
              label="Coverage amount"
              placeholder="Value"
              value={values.coverageAmount}
              onChange={handleCoverageAmount}
              toggler={currencyPriceToggle}
              checked={currencyPriceToggle}
              toggleTitle="Switch to text value"
              togglerHandleClick={togglerHandleClick}
            />
          )
          : (
            <CurrencyInput
              label="Coverage $ amount"
              placeholder="$00.00"
              value={values.coverageAmount}
              onChange={handleCoverageAmount}
              className="form-input form-input__coverage-amount"
              toggler={!currencyPriceToggle}
              checked={!currencyPriceToggle}
              toggleTitle="Switch to text value"
              toggleHandleClick={togglerHandleClick}
            />
          )}

        <div className="policy-form__date-selector">
          <p className="policy-form__date-selector-label">
            Expiry date
          </p>

          <DateSelector
            disabled={isFinishDateDisabled}
            initialDate={values.finishDate}
            onChange={handleFinishDateChange}
          />

          <div className="policy-form__date-toggler">
            <Toggle
              checked={isFinishDateDisabled}
              changeHandler={deleteFinishDate}
            />
            <p className="policy-form__date-toggler-text">Doesn’t have an expiry date</p>
          </div>
        </div>

        <div className="form-input policy-form__file-selector">
          <p className="policy-form__file-selector-label">
            Attachment
          </p>

          <FileInput
            onChange={handleFileChange}
            value={values.attachment}
          />
        </div>

        {values.finishDate && (
          <NotificationsPicker
            className="form-input"
            values={daysBeforeExpireDate}
            daysBeforeFinishDate={daysBeforeFinishDate}
            limit={notificationsLimit}
            onChange={handleDaysBeforeExpireDateChange}
          />
        )}
      </div>

      <Textarea
        label="Notes"
        placeholder="Notes"
        value={values.notes}
        onChange={handleChangeNotes}
      />

      {children}
    </form>
  );
};

export default PolicyForm;
