/* eslint-disable complexity */
import { FC } from 'react';

import Button from '../Button/Button';

type BillingCardProps = {
  title: string,
  active?: boolean,
  selected?: boolean,
  showButtons?: boolean,
  price?: number,
  usersLimit: number | null,
  features: string,
  featuresPrefix: string,
  companiesLimit: number | null,
  policiesLimit: number | null,
  multiple: boolean,
  onClick?: () => void
}

const BillingCard: FC<BillingCardProps> = ({
  title,
  active,
  showButtons,
  price,
  features,
  featuresPrefix,
  multiple,
  onClick,
  selected,
}) => {
  const showButton = !(active && !price) && showButtons;

  const buttonLabel = (() => {
    if (active) return 'Unsubscribe';
    if (selected) return 'Selected';
    return 'Select';
  })();

  return (
    <div className={`billing-card ${active && 'billing-card_active'} ${selected && 'selected'}`}>
      <div className="billing-card__title">
        <h3>{title}</h3>
        {active && <div className="billing-card__current-plan">Current plan</div>}
      </div>

      <div className="billing-card__price-block">
        {!price && <p className="billing-card__price">$Free</p>}
        {price === 100000 && <p className="billing-card__price">Contact</p>}
        {!!price && price < 100000 && multiple && (
          <>
            <p className="billing-card__price">${price / 100}</p>
            <span className="billing-card__period">per company<br /> per month</span>
          </>
        )}
        {!!price && price < 100000 && !multiple && (
          <>
            <p className="billing-card__price">${price / 100}</p>
            <span className="billing-card__period">per<br /> month</span>
          </>
        )}
      </div>
      <div className="billing-card__features-prefix-block">
        {featuresPrefix}
      </div>

      <ul className="billing-card__limits">
        {features.split('\n').map((feature) => {
          return (
            <li className="billing-card__limit" key={feature}>
              <p>{feature}</p>
            </li>
          );
        })}
      </ul>

      {showButton && <Button title={buttonLabel} onClick={onClick} />}
    </div>
  );
};

export default BillingCard;
