import { FC } from 'react';

import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Edit } from 'src/assets/icons/Edit.svg';
import { userRoleItems } from 'src/constants';
import { routeNames } from 'src/router';
import { CompanyModel, UserModel } from 'src/types/types';

type SmallTableProps = {
  tableHeaders: string[],
  editLink?: string,
  users?: UserModel[],
  companies?: CompanyModel[],
  me: UserModel,
  company?: CompanyModel[],
}

const SmallTable: FC<SmallTableProps> = ({ tableHeaders, users, companies, me, company }) => {
  const navigate = useNavigate();
  const userRole = userRoleItems.find((r) => r.role === me.role.name);

  const isUserEditEnabled = (user: UserModel) => (
    user.userId === me.userId || userRole?.rolesUserCanManage.includes(user.role.name)
  );

  const isCompanyEditEnabled = userRole?.canUserEditCompanies;

  const findExacUserCompany = (user) => {
    return company?.find((c) => {
      return c.companyId === user.companyId;
    });
  };

  return (
    <div className="small-table">
      <table className="table">
        <thead>
          <tr>
            {tableHeaders.map((header) => <td key={header}>{header}</td>)}
            <td />
          </tr>
        </thead>

        <tbody>
          {users && users.map((user) => (
            <tr key={user.userId}>
              <td>{user.email}</td>
              <td>
                {user.role.name}
              </td>
              <td>{user.phone}</td>
              <td>
                {user.fullName?.length > 0 ? user.fullName : '---'}
              </td>
              <td>
                {findExacUserCompany(user)?.companyName}
              </td>
              <td className="">{format(new Date(user.created_at), 'd MMM y')}</td>
              <td>
                {isUserEditEnabled(user) && (
                  <Edit onClick={() => navigate(`${routeNames.MANAGE_USERS}/edit/${user.userId}`)} />
                )}
              </td>
            </tr>
          ))}

          {companies && companies.map((company) => (
            <tr key={company.companyId}>
              <td>{company.companyName}</td>
              <td>{company.companyType}</td>
              <td>{company.contactName}</td>
              <td>{company.contactEmail}</td>
              <td>{company.phone || ''}</td>
              <td>{format(new Date(company.created_at), 'd MMM y')}</td>
              <td>
                {isCompanyEditEnabled && (
                  <Edit
                    onClick={() => navigate(`${routeNames.COMPANIES}/edit/${company.companyId}`)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SmallTable;
