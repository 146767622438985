import { mainInstanse } from 'src/axios';
import { InviteUserModel, ResponseUserModel, InviteUser, UserModel, UpdateUserModel } from 'src/types/types';

const UserApi = {

  async fetchUsers(): Promise<UserModel[]> {
    const res = await mainInstanse.get<UserModel[]>('/users');
    return res.data;
  },

  async fetchUserById(id: string): Promise<UserModel> {
    const res = await mainInstanse.get<UserModel>(`/users/${id}`);
    return res.data;
  },

  async fetchMe(): Promise<UserModel> {
    const res = await mainInstanse.get<UserModel>('/users/me');
    return res.data;
  },

  async deleteUser(id: string): Promise<ResponseUserModel> {
    const res = await mainInstanse.delete<ResponseUserModel>(`/users/${id}`);
    return res.data;
  },

  async inviteUser(user: InviteUser): Promise<InviteUserModel> {
    const res = await mainInstanse.post<InviteUserModel>('/users/invite', user);
    return res.data;
  },

  async updateUser(user: UpdateUserModel, id: string): Promise<UserModel> {
    const res = await mainInstanse.put<UserModel>(`/users/${id}`, user);
    return res.data;
  },
};

export default UserApi;
