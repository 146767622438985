import { useEffect } from 'react';

import { setLoading } from 'src/store/auth/authSlice';
import { getMe } from 'src/store/auth/thunk';
import { getToken } from 'src/utils/mycomplyTokenUtils';

import { useAppDispatch } from './useDispatchType';

export const useAuthenticated = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getToken()) {
      dispatch(getMe());
    } else {
      dispatch(setLoading());
    }
  }, [dispatch]);
};
