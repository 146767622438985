export const moneyFormatter = (value: string | number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(Number(value));
};

export const coverageFormatter = (value: string | number): string => {
  if (value.toString().length === 0) {
    return '---';
  }
  let numberValue = value;
  if (typeof value === 'string') {
    numberValue = value.replace(',', '');
    if (Number.isNaN(Number(numberValue))) {
      return value;
    }
  }

  return moneyFormatter(numberValue);
};
