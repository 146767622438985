import { FC, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from 'src/assets/icons/Plus.svg';
import Button from 'src/components/Button/Button';
import Search from 'src/components/Search/Search';
import SmallMobileTable from 'src/components/SmallTable/SmallMobileTable';
import SmallTable from 'src/components/SmallTable/SmallTable';
import { userRoleItems } from 'src/constants';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { selectCompanies } from 'src/store/companies/selectors';
import { getAllCompanies } from 'src/store/companies/thunk';
import { selectUsers } from 'src/store/users/selectors';
import { getUsers } from 'src/store/users/thunk';
import { UserModel } from 'src/types/types';

const tableHeaders = ['User email', 'User type', 'Phone', 'Name', 'Company name', 'Added'];

const getSearchedUsers = (users: UserModel[], searchValue: string) => {
  return users.filter((user) => user.email.toLowerCase().includes(searchValue.toLowerCase()));
};

const ManageUsers: FC = () => {
  const dispatch = useAppDispatch();
  const { me } = useSelector(selectUsers);
  const { users } = useSelector(selectUsers);
  const { companies, myCompany } = useSelector(selectCompanies);
  const [searchValue, setSearchValue] = useState('');

  const canInviteUsers = userRoleItems.find((r) => r.role === me?.role.name)?.canInviteUsers;

  const isUsersLimitAchieved = useMemo(() => {
    return !!(myCompany && myCompany.usersLimit && myCompany.usersLimit <= users.length);
  }, [users.length, myCompany]);

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getAllCompanies());
  }, [dispatch]);

  return (
    <div className="manage-users__container">
      <h1>Manage Users</h1>
      <div className="search-block">
        <Search placeholder="Search by user email" onChange={(value: string) => setSearchValue(value)} />
        {canInviteUsers && (
          <Link to={isUsersLimitAchieved ? routeNames.BILLING : routeNames.ADD_USER}>
            <Button title="Add users">
              <PlusIcon />
            </Button>
          </Link>
        )}
      </div>
      <div className="manage-users__table">
        {me && (
          <SmallTable
            editLink={routeNames.EDIT_USER}
            tableHeaders={tableHeaders}
            users={getSearchedUsers(users, searchValue)}
            me={me}
            company={companies}
          />
        )}

        {me && (
          <SmallMobileTable
            users={getSearchedUsers(users, searchValue)}
            me={me}
          />
        )}
      </div>
    </div>
  );
};

export default ManageUsers;
