import { FC } from 'react';

import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import 'react-phone-number-input/style.css';
import Button from 'src/components/Button/Button';
import { SignFormValues } from 'src/types/types';

import Phone from '../Phone/Phone';
import { signUpValidationSchema } from './validationSchema';

const initialValues: SignFormValues = {
  category: 'company',
  companyName: '',
  email: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
};

type SignFormProps = {
  onSubmitForm: (v: SignFormValues) => void,
  loading: boolean
}

const SignUpForm: FC<SignFormProps> = ({ onSubmitForm, loading }) => {
  const submit = (values: SignFormValues) => {
    onSubmitForm(values);
  };

  const updatePhone = (setFieldValue, setFieldTouched, validateField, value: string) => {
    setFieldValue('phone', value, true);
    setTimeout(() => {
      setFieldTouched('phone', true);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signUpValidationSchema}
      validateOnChange={false}
      onSubmit={submit}
    >
      {({ setFieldValue, setFieldTouched, validateField, errors, isValid }) => (
        <Form className="form">
          <div className="form__item-block input-radio__block">
            <h5>How will you use my comply?</h5>
            <div>
              <Field
                className="input-radio"
                id="choose1"
                type="radio"
                name="category"
                value="agency"
              />
              <label htmlFor="choose1" className="radio-label">
                <span className="radio-button" />Manage compliance for multiple companies
              </label>
            </div>

            <div>
              <Field
                className="input-radio"
                id="choose2"
                type="radio"
                name="category"
                value="company"
              />
              <label htmlFor="choose2" className="radio-label">
                <span className="radio-button" />Manage compliance for 1 company
              </label>
            </div>
            <span className="form__error">
              <ErrorMessage name="category" />
            </span>
          </div>

          <div className="form__item-block">
            <h5>What’s your company name?</h5>
            <Field
              className={classNames({ 'input-text': true, 'input-text_error': errors.companyName })}
              name="companyName"
              placeholder="Company Name"
              autoComplete="organization"
            />
            <span className="form__error">
              <ErrorMessage name="companyName" />
            </span>
          </div>

          <div className="form__item-block">
            <h5>What’s your email?</h5>
            <Field
              className={classNames({ 'input-text': true, 'input-text_error': errors.email })}
              name="email"
              placeholder="mail@company-name.com"
              autoComplete="email"
            />
            <span className="form__error">
              <ErrorMessage name="email" />
            </span>
          </div>

          <div className="form__item-block">
            <h5>What’s your number?</h5>
            <Phone
              className={classNames({ 'input-text': true, 'input-text_error': errors.phone })}
              onChange={(value) => { updatePhone(setFieldValue, setFieldTouched, validateField, value); }}
            />

            <span className="form__error">
              <ErrorMessage name="phone" />
            </span>
          </div>

          <div className="form__item-block">
            <h5>What’s your password?</h5>
            <Field
              type="password"
              className={classNames({ 'input-text': true, 'input-text_error': errors.password })}
              name="password"
              placeholder="*********"
              autoComplete="new-password"
            />
            <span className="form__error">
              <ErrorMessage name="password" />
            </span>
          </div>

          <div className="form__item-block">
            <h5>Confirm password</h5>
            <Field
              type="password"
              className={classNames({ 'input-text': true, 'input-text_error': errors.passwordConfirmation })}
              name="passwordConfirmation"
              placeholder="*********"
              autoComplete="new-password"
            />
            <span className="form__error">
              <ErrorMessage name="passwordConfirmation" />
            </span>
          </div>
          <Button disabled={!isValid || loading} title="Get started" type="submit" loading={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
