import { FC } from 'react';

interface SpinnerValues {
  visible?: boolean
  style?: Record<string, string>
}

const Spinner: FC<SpinnerValues> = ({ visible, style }) => {
  if (visible) {
    return (
      <div className="spinner" style={style}>
        <div className="spinner_part spinner__1" />
        <div className="spinner_part spinner__2" />
        <div className="spinner_part spinner__3" />
        <div className="spinner_part spinner__4" />
        <div className="spinner_part spinner__5" />
        <div className="spinner_part spinner__6" />
        <div className="spinner_part spinner__7" />
        <div className="spinner_part spinner__8" />
      </div>
    );
  } return null;
};

export default Spinner;
