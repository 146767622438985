import { FC, useState } from 'react';

import { Link } from 'react-router-dom';

import ForgotPasswordForm from 'src/components/ForgotPasswordForm/ForgotPasswordForm';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { forgotPassword } from 'src/store/auth/thunk';
import { ForgotPasswordFormValues } from 'src/types/types';

const ForgotPassword: FC = () => {
  const [disableButton, setDisableButton] = useState(false);
  const [complete, setComplete] = useState(false);

  const dispatch = useAppDispatch();

  const onSubmitForm = async (values: ForgotPasswordFormValues) => {
    setDisableButton(true);
    await dispatch(forgotPassword(values));
    setComplete(true);
  };

  return (
    <div className="container">
      <h1 className="mb-40 login-title">Forgot password</h1>
      {!complete && (
        <>
          <ForgotPasswordForm onSubmitForm={onSubmitForm} disableButton={disableButton} />

          <div className="form__subtitle">
            &nbsp;Already have an account?&nbsp;
            <span className="form__label--blue">
              <Link to={routeNames.LOGIN}>Log in</Link>
            </span>
          </div>
        </>
      )}

      {complete && (
        <div className="text-center">
          An email has been sent<br />
          Don&apos;t forget to check your spam folder.
          <br /><br />
          <span className="form__label--blue">
            <Link to={routeNames.LOGIN}>Go back to login</Link>
          </span>
        </div>
      )}

    </div>
  );
};

export default ForgotPassword;
