/* eslint-disable complexity */
export const onChangeTimeHandler = (hours: string, minutes: string): string => {
  if (+minutes > 59) {
    return `${hours}59`;
  }
  if (+hours > 12) {
    return `12${minutes}`;
  }
  if (+hours <= 12) {
    return `${hours}${minutes}`;
  }
  return `${hours}${minutes}`;
};

export const onBlurTimeInput = (time: string): string => {
  const checkedTime = time.split(':');
  if (!checkedTime[0].length) return '';
  if (checkedTime[0].length === 1) return `${checkedTime[0]}0:00`;
  if (!checkedTime[1].length) return `${checkedTime[0]}:00`;
  if (checkedTime[1].length === 1) return `${checkedTime[0]}:0${checkedTime[1]}`;

  return time;
};

// eslint-disable-next-line complexity
export const toFormatTime = (time: string, format: string): string => {
  const hours = time ? time.split(':')[0] : '00';
  const minutes = time ? time.split(':')[1] : '00';
  let formatedTime = `${+hours}:${minutes}`;
  if (format === 'pm') {
    formatedTime = `${+hours + 12}:${minutes}`;
  }
  if (format === 'pm' && +hours === 12) {
    formatedTime = `12:${minutes}`;
  }
  if (format === 'am' && +hours === 12) {
    formatedTime = `00:${minutes}`;
  }
  return formatedTime;
};

export const checkIsDateInRange = (dateFrom: null | Date, dateTo: null | Date, date: Date): boolean => {
  let isDateInRange = true;

  if (dateFrom && !dateTo) isDateInRange = dateFrom.getTime() <= date.getTime();
  if (!dateFrom && dateTo) isDateInRange = dateTo.getTime() >= date.getTime();
  if (dateFrom && dateTo) isDateInRange = dateFrom.getTime() <= date.getTime() && dateTo.getTime() >= date.getTime();

  return isDateInRange;
};
