import { authInstanse, mainInstanse } from 'src/axios';
import { OkResponse, ResponseUserModel, SignUpByInvite, SignUpUserModel, UserModel } from 'src/types/types';

export const AuthApi = {
  async login(email: string, password: string): Promise<ResponseUserModel> {
    const res = await authInstanse.post<ResponseUserModel>('', { identifier: email, password });
    return res.data;
  },

  async forgotPassword(email: string): Promise<OkResponse> {
    const res = await authInstanse.post<OkResponse>('/forgot-password', { email });
    return res.data;
  },

  async resetPassword(password: string, passwordConfirmation: string, code: string): Promise<ResponseUserModel> {
    const res = await authInstanse.post<ResponseUserModel>('/reset-password', { password, passwordConfirmation, code });
    return res.data;
  },

  async signup(user: SignUpUserModel): Promise<ResponseUserModel> {
    const res = await authInstanse.post<ResponseUserModel>('/register', user);
    return res.data;
  },

  async signUpByInvite(user: SignUpByInvite): Promise<ResponseUserModel> {
    const res = await authInstanse.post<ResponseUserModel>('/registerByInvite', user);
    return res.data;
  },

  async getMe(): Promise<UserModel> {
    const res = await mainInstanse.get<UserModel>('/users/me');
    return res.data;
  },
};
