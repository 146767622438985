import { FC, useState, useEffect } from 'react';

import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import { ReactComponent as Icon } from 'src/assets/icons/Chevron.svg';
import Button from 'src/components/Button/Button';
import { userRoleItems } from 'src/constants';
import { CompanyModel, UserModel } from 'src/types/types';

import Phone from '../Phone/Phone';
import { addUserValidationSchema, editUserValidationSchema } from './validationSchema';

type FormValues = {
  email: string,
  role: string,
  username?: string,
  phone?: string,
  companyId: string,
  fullName: string
}

type UserFormProps = {
  user?: UserModel,
  company: CompanyModel,
  me: UserModel | null,
  companies?: CompanyModel[],
  usersAmount?: number,
  isUsersLimitAchieved?: boolean,
  // TODO add type here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: (v: any) => void
  onDeleteClick?: () => void
}

const UserForm: FC<UserFormProps> = ({
  user,
  handleSubmit,
  company,
  me,
  companies,
  onDeleteClick,
  usersAmount = 2,
  isUsersLimitAchieved,
}) => {
  // TODO move role names to enum
  const defaultRole = 'viewer';
  const myRole = userRoleItems.find((r) => r.role === me?.role.name);
  const [selectedRole, setSelectedRole] = useState(user ? user.role.name : defaultRole);

  const initialValues: FormValues = {
    email: user ? user.email : '',
    role: selectedRole,
    companyId: '',
    fullName: user ? user.fullName : '',
    username: user ? user.username : '',
    phone: user ? user.phone : '',
  };

  const addInitialCompanyId = () => {
    if (user) initialValues.companyId = user.companyId;
    if (!user) {
      if (companies) initialValues.companyId = companies[0].companyId;
    }
    return '';
  };

  const roles = userRoleItems.filter((role) => (
    role.companyTypes.includes(company.companyType) && myRole?.rolesUserCanManage.includes(role.role)
  ));

  const ableToDelete = (() => {
    if (user) {
      return user.userId !== me?.userId && myRole?.rolesUserCanManage.includes(user.role.name);
    }
    return false;
  })();

  const findDefaultCompany = () => {
    const company = companies?.find((c) => c.companyId === user?.companyId);
    return {
      companyName: company?.companyName,
      companyId: company?.companyId,
    };
  };

  const updatePhone = (setFieldValue, setFieldTouched, validateField, value: string) => {
    setFieldValue('phone', value, true);
    setTimeout(() => {
      setFieldTouched('phone', true);
    });
  };

  useEffect(() => {
    addInitialCompanyId();
  }, []);

  console.log(ableToDelete);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={user ? editUserValidationSchema : addUserValidationSchema}
      validateOnChange
      validateOnBlur={false}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue, setFieldTouched, validateField, errors, isValid }) => {
        return (
          <Form className="form users-form">
            <h1>{user ? 'Edit user' : 'Add user'}</h1>
            <div className="form__two-blocks-container">
              <div className="form__left-block">
                <div className="form__item-block">
                  <h5>User email</h5>
                  <Field
                    placeholder="mail@company-name.com"
                    className={classNames({ 'input-text': true, 'input-text_error': errors.email })}
                    name="email"
                  />
                  <span className="form__error">
                    <ErrorMessage name="email" />
                  </span>
                </div>

                <div className="form__item-block">
                  <h5>Company Name</h5>
                  <div className="form__select">
                    <Field
                      placeholder="Company Name"
                      className={classNames({ 'input-text_error': errors.companyId })}
                      as="select"
                      name="companyId"
                    >
                      <option defaultValue={findDefaultCompany().companyId} selected>
                        {findDefaultCompany().companyName}
                      </option>
                      {companies && companies.map((company) => {
                        return (
                          <option
                            key={company.companyId}
                            value={company.companyId}
                            className="form__select-option"
                          >
                            {company.companyName}
                          </option>
                        );
                      })}
                    </Field>
                    <Icon className="form__select-arrow" />
                  </div>
                  <span className="form__error">
                    <ErrorMessage name="companyId" />
                  </span>
                </div>

                <div className="form__item-block user-role">
                  <h5>User role</h5>

                  {roles.map((item) => (

                    <label
                      key={item.role}
                      htmlFor={item.role}
                      className={`radio-label radio-role ${selectedRole === item.role && 'selected'}`}
                    >
                      <div className="radio-role__block">
                        <h5>
                          {item.role.charAt(0).toUpperCase() + item.role.slice(1)}
                        </h5>
                        <ul>
                          {item.possibilities.map((item) => <li key={item}>• {item}</li>)}
                        </ul>
                      </div>
                      {(!(me?.userId === user?.userId) && usersAmount > 1)
                        && (
                          <Field
                            className="input-radio"
                            id={item.role}
                            type="radio"
                            name="role"
                            value={item.role}
                            onClick={() => setSelectedRole(item.role)}
                          />
                        )}
                    </label>
                  ))}

                </div>
              </div>
              {!!user && (
                <div className="form__right-block">
                  <div className="form__item-block">
                    <h5>Name</h5>
                    <Field
                      placeholder="John Smith"
                      className="input-text"
                      name="fullName"
                      defaultValue={initialValues.fullName}
                    />
                    <span className="form__error">
                      <ErrorMessage name="name" />
                    </span>
                  </div>
                  <div className="form__item-block">
                    <Phone
                      className={classNames({ 'input-text': true, 'input-text_error': errors.phone })}
                      onChange={(value) => { updatePhone(setFieldValue, setFieldTouched, validateField, value); }}
                    />

                    <span className="form__error">
                      <ErrorMessage name="phone" />
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex">
              {(!isUsersLimitAchieved && !user) && <Button disabled={!isValid} title="Save" type="submit" />}
              {user && <Button disabled={!isValid} title="Save" type="submit" />}
              <Button title="Delete" appearance="transparent" onClick={onDeleteClick} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
