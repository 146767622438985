import { createSlice } from '@reduxjs/toolkit';

import { sliceNames } from 'src/constants';
import { UserModel } from 'src/types/types';

import { getMe, getUserById, getUsers, updateUser } from './thunk';

const userSlice = createSlice({
  name: sliceNames.users,
  initialState: {
    users: [] as UserModel[],
    fetchUser: {} as UserModel,
    me: null as (UserModel | null),
  },
  reducers: {
    clearAllUsers(state) {
      state.users = [];
      state.me = null;
    },
    clearUser(state) {
      state.fetchUser = {} as UserModel;
    },
  },
  extraReducers: (builder) => {
    // get users
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    // fetch user
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.fetchUser = action.payload;
    });

    // fetch me
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.me = action.payload;
    });

    // update user

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.users = state.users.map((user) => {
        if (user.userId === action.payload.userId) {
          return action.payload;
        }
        return user;
      });
    });
  },
});

export const { clearAllUsers, clearUser } = userSlice.actions;
export default userSlice.reducer;
