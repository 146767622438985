import { createSlice } from '@reduxjs/toolkit';

import { sliceNames } from 'src/constants';
import { UserModel } from 'src/types/types';
import { removeToken, setToken } from 'src/utils/mycomplyTokenUtils';

import { getMe, loginUser, registerUser, registerUserByInvite } from './thunk';

const authSlice = createSlice({
  name: sliceNames.users,
  initialState: {
    user: {} as UserModel,
    isAuth: false,
    isLoading: true,
  },
  reducers: {
    setLoading(state) {
      state.isLoading = false;
    },
    logout(state) {
      state.isAuth = false;
      state.user = {} as UserModel;
      removeToken();
    },
  },
  extraReducers: (builder) => {
    // login user
    builder.addCase(loginUser.fulfilled, (state, action) => {
      setToken(action.payload.jwt);
      state.user = action.payload.user;
      state.isAuth = true;
    });

    // registration
    builder.addCase(registerUser.fulfilled, (state, action) => {
      setToken(action.payload.jwt);
      state.user = action.payload.user;
      state.isAuth = true;
    });

    // registration by invite
    builder.addCase(registerUserByInvite.fulfilled, (state, action) => {
      setToken(action.payload.jwt);
      state.user = action.payload.user;
      state.isAuth = true;
    });

    // me
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
      state.isLoading = false;
    });

    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getMe.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setLoading, logout } = authSlice.actions;
export default authSlice.reducer;
