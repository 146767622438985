import { FC, MouseEvent, useState } from 'react';

import { differenceInCalendarDays } from 'date-fns/esm';

import { useAppDispatch } from 'src/hooks/useDispatchType';
import { sendNotification } from 'src/store/policies/thunk';
import { PolicyModel } from 'src/types/types';

import Button from '../Button/Button';

type NotificationsTipsProps = {
  policy: PolicyModel,
  notifications: Date[] | string[],
  finishDate: Date | string
}

const NotificationsTips: FC<NotificationsTipsProps> = ({ policy, notifications, finishDate }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleSendNow = async (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    setDone(false);
    await dispatch(sendNotification({ id: policy.policyId }));
    setLoading(false);
    setDone(true);
  };

  // const formatDate = (date: string) => {
  //   return format(new Date(date), 'd MMM y hh:mm');
  // };

  //   useEffect(() => {
  //     getNotifications({ id }));
  // }, [dispatch, id]);

  return (
    <div className="notifications-tips">
      <h4 className="notifications-tips__title">Notifications</h4>
      <ul className="notifications-tips__notifications">
        {notifications.map((n) => {
          const diff = differenceInCalendarDays(new Date(finishDate), new Date(n));
          return (
            <li className="notifications-tips__notification" key={n}>
              {diff} days before the Expiry date
            </li>
          );
        })}
        <li className="notifications-tips__notification notifications-tips__resend" role="presentation" onClick={handleSendNow}>
          <Button appearance="label" title="Send now" loading={loading} done={done} />
        </li>
        {/* {policyNotifications.map((item) => (
          <span
            title={item.published_at}
            key={item.published_at}
          >{formatDate(item.published_at)} to {item.email}
          </span>
        ))} */}
      </ul>
    </div>
  );
};

export default NotificationsTips;
