import { FC, useRef, useState } from 'react';

import classNames from 'classnames';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';

import Button from 'src/components/Button/Button';
import { ResetPasswordFormValues } from 'src/types/types';

import { validationSchema } from './validationSchema';

const initialValues: ResetPasswordFormValues = {
  password: '',
  passwordConfirmation: '',
};

type ResetPasswordProps = {
  disableButton?: boolean,
  onSubmitForm: (values: ResetPasswordFormValues) => void;
}

const ResetPasswordForm: FC<ResetPasswordProps> = ({ onSubmitForm, disableButton }) => {
  const ref = useRef<FormikProps<ResetPasswordFormValues>>(null);

  const [loading, setLoading] = useState(false);

  const submit = (values: ResetPasswordFormValues) => {
    setLoading(true);
    onSubmitForm(values);
  };

  return (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur={false}
      onSubmit={submit}
    >
      {({ errors }) => (
        <Form className="form">
          <div className="form__input-box">
            <div className="form__item-block">
              <h5>What’s your password?</h5>
              <Field
                type="password"
                className={classNames({ 'input-text': true, 'input-text_error': errors.password })}
                name="password"
                placeholder="*********"
                autoComplete="new-password"
              />
              <span className="form__error">
                <ErrorMessage name="password" />
              </span>
            </div>

            <div className="form__item-block">
              <h5>Confirm password</h5>
              <Field
                type="password"
                className={classNames({ 'input-text': true, 'input-text_error': errors.passwordConfirmation })}
                name="passwordConfirmation"
                placeholder="*********"
                autoComplete="new-password"
              />
              <span className="form__error">
                <ErrorMessage name="passwordConfirmation" />
              </span>
            </div>
          </div>

          <Button
            title="Setup new password"
            type="submit"
            disabled={disableButton}
            className={disableButton ? 'disabled' : ''}
            loading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
