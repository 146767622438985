import { FC, useEffect, useState } from 'react';

import { isFulfilled } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'src/components/Button/Button';
import CompanyForm from 'src/components/CompanyForm/CompanyForm';
import HistoryBlock from 'src/components/History/HistoryBlock';
import { useAppDispatch } from 'src/hooks/useDispatchType';
import { routeNames } from 'src/router';
import { clearCompany } from 'src/store/companies/companiesSlice';
import { selectCompanies } from 'src/store/companies/selectors';
import { getCompanyById, updateCompany, deleteCompany, onboardCompany } from 'src/store/companies/thunk';
import { selectUsers } from 'src/store/users/selectors';
import { CompanyFormModel } from 'src/types/types';

const EditCompanyForm: FC = () => {
  const [onboard, setOnboard] = useState(false);
  const [onboardProgress, setOnboardProgress] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id = '' } = useParams<{ id: string }>();
  const { me } = useSelector(selectUsers);
  const { company } = useSelector(selectCompanies);

  const onSubmit = async (company: CompanyFormModel) => {
    if (onboard) {
      await dispatch(onboardCompany({ id }));
      setOnboard(false);
    }
    const action = await dispatch(updateCompany({ company, id }));
    setOnboardProgress(false);
    if (isFulfilled(action)) navigate(routeNames.COMPANIES);
  };

  const handleDeleteCompany = async () => {
    const action = await dispatch(deleteCompany({ id }));
    if (isFulfilled(action)) navigate(routeNames.COMPANIES);
  };

  const handleOnboardCompany = async () => {
    setOnboardProgress(true);
    setOnboard(true);
  };

  useEffect(() => {
    dispatch(getCompanyById({ id }));

    return () => {
      dispatch(clearCompany());
    };
  }, [dispatch, id]);

  return (
    <div className="companies-form__container">
      {Object.keys(company).length !== 0 && (
        <>
          <HistoryBlock link={routeNames.COMPANIES} currentPageTitle="Edit company" linkedPageTitle="Companies" />
          <CompanyForm
            company={company}
            onSubmit={onSubmit}
            title="Edit company"
            buttonTitle="Save"
          >
            {company.companyId !== me?.companyId
              && <Button title="Delete" appearance="transparent" onClick={handleDeleteCompany} />}
            {company.companyId !== me?.companyId
              && (
                <Button
                  title="Save and send onboard email"
                  onClick={handleOnboardCompany}
                  type="submit"
                  loading={onboardProgress}
                />
              )}
          </CompanyForm>
        </>
      )}
    </div>
  );
};

export default EditCompanyForm;
